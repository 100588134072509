import dev from './dev.config';
import { default as local } from './alpha.config';
import stg from './staging.config';
import prod from './prod.config';

let config = prod;

if (/dev/.test(window.location.hostname)) config = dev;
if (/stg/.test(window.location.hostname)) config = stg;
if (/local/.test(window.location.hostname)) config = local;

export default config;
