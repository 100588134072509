export const toolEvent = (
    data = {
        analyticsAppName: '',
        eventName: '',
        name: '',
        sessionSettings: '',
    },
) => {
    window.appEventData = window.appEventData || [];
    window.appEventData.push({
        event: data.eventName,
        process: {
            processStepLabel: data.analyticsAppName,
            processStepValue: data.name,
            settingUsed: '',
        },
        tool: {
            SessionSettings: data.sessionSettings,
            toolId: data.analyticsAppName,
        },
    });
};
