import { FC } from 'react';

/* material */
import { Button, IconButton, makeStyles, Modal } from '@material-ui/core';
import CloseIcon from '@material-ui/icons/Close';
import CheckIcon from '@material-ui/icons/Check';

import { pxToRem } from 'theme/utils';
import { FormattedMessage } from 'react-intl';

const useStyles = makeStyles((theme) => ({
    root: {
        color: theme.colors.navy,
        backgroundColor: theme.colors.white,
        border: theme.nextStepModal.border,
        borderRadius: '8px',
        boxShadow: theme.nextStepModal.boxShadow,
        display: 'flex',
        flexDirection: 'column',
        left: '50%',
        position: 'absolute',
        transform: 'translate(-50%, -50%)',
        top: '20%',
        width: '90%',
        [theme.breakpoints.up('sm')]: {
            top: '50%',
            width: '80%',
            maxHeight: '80%',
            maxWidth: theme.breakpoints.values.lg,
            overflow: 'hidden',
        },
    },
    checkIcon: {
        color: theme.subscribeModal.color,
        fontSize: '50px',
        paddingRight: '20px',
    },
    closeBtn: {
        padding: 0,
        position: 'absolute',
        right: 25,
        top: 20,
        zIndex: 1,

        [theme.breakpoints.up('sm')]: {
            top: 18,
            right: 25,
        },
    },
    closeButton: {
        width: '100%',
        [theme.breakpoints.up('sm')]: {
            padding: '16px 1px',
            width: '180px',
        },
    },
    closeIcon: {
        color: theme.nextStepModal.closeIcon.color,
        fontSize: pxToRem(22),

        [theme.breakpoints.up('sm')]: {
            fontSize: pxToRem(40),
        },
    },
    contentWrapper: {
        display: 'flex',
        flexDirection: 'row',
        height: '100%',
        overflowY: 'scroll',
    },
    title: {
        fontSize: '22px',
        fontWeight: 600,
        lineHeight: '33px',
        [theme.breakpoints.up('sm')]: {
            fontSize: '30px',
        },
        '& > img': {
            marginRight: '18px',
        },
    },
    titleWrapper: {
        alignItems: 'center',
        display: 'flex',
        paddingBottom: '20px',
        [theme.breakpoints.up('sm')]: {
            paddingBottom: '40px',
        },
    },
    menuWrapper: {
        padding: '30px',
        [theme.breakpoints.up('sm')]: {
            padding: '40px',
        },
    },
}));

interface CloseModalProps {
    dataAnalyticsPlacement?: string;
    dataAnalyticsLabel?: string;
    dataAnalyticsId?: string;
    onClose: () => void;
    open: boolean;
}

const CloseModal: FC<CloseModalProps> = ({
    dataAnalyticsId,
    dataAnalyticsLabel,
    dataAnalyticsPlacement,
    onClose,
    open = false,
}) => {
    const classes = useStyles();

    return (
        <Modal open={open}>
            <div className={classes.root}>
                <IconButton className={classes.closeBtn} onClick={onClose}>
                    <CloseIcon className={classes.closeIcon} />
                </IconButton>
                <div className={classes.menuWrapper}>
                    <div className={classes.titleWrapper}>
                        <CheckIcon className={classes.checkIcon} />
                        <div className={classes.title}>
                            <FormattedMessage id="subscribe.successful" />
                        </div>
                    </div>
                    <Button
                        data-analytics-placement={dataAnalyticsPlacement}
                        data-analytics-label={dataAnalyticsLabel}
                        data-analytics-id={dataAnalyticsId}
                        className={classes.closeButton}
                        onClick={onClose}
                        variant="contained"
                    >
                        <FormattedMessage id="subscribe.close" />
                    </Button>
                </div>
            </div>
        </Modal>
    );
};

export default CloseModal;
