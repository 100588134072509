import { Dispatch, Action, Middleware } from 'redux';
import { resetCreateWebsiteInfo } from 'store/actionCreators/createWebsiteActionCreator';
import { AppState } from 'store/store/configureStore';

const LOCATION_CHANGE = '@@router/LOCATION_CHANGE';

export const trackPageView: Middleware = ({ getState }) => (
    next: Dispatch,
) => async (action: Action) => {
    next(action);
    if (action.type === LOCATION_CHANGE) {
        const { payload } = action as any;

        if (payload.location.pathname.indexOf('/advisor/create') === -1) {
            const state: AppState = getState();
            if (state.createWebsite.isFlowActive) {
                next(resetCreateWebsiteInfo());
            }
        }
    }
};
export default trackPageView;
