// let processStepLabel: string;
// let processStepValue: string;

export const multiStepEvent = (
    data = {
        analyticsAppName: '',
        eventName: '',
        name: '',
        sessionSettings: '',
    },
) => {
    // processStepLabel = data.analyticsAppName;
    // processStepValue = data.name;
    window.appEventData = window.appEventData || [];
    window.appEventData.push({
        event: data.eventName,
        process: {
            processStepLabel: data.analyticsAppName,
            processStepValue: data.name,
            settingUsed: '',
        },
        selfservice: {
            ActivityName: data.analyticsAppName,
            SessionSettings: data.sessionSettings,
        },
    });
};

export const multiStepErrorMessageEvent = (
    processStepLabel: string,
    processStepValue: string,
    errorMessageArray: string[] = [],
) => {
    window.appEventData = window.appEventData || [];
    window.appEventData.push({
        event: 'Error Message Presented',
        formFieldError: errorMessageArray
            .map((item) => (item.includes(',') ? `"${item}"` : item))
            .join('|'),
        process: {
            processStepLabel: processStepLabel,
            processStepValue: processStepValue,
        },
    });
};

export const multiStepFormErrorEvent = (
    processStepLabel: string,
    processStepValue: string,
    errorMessageArray: string,
) => {
    window.appEventData = window.appEventData || [];
    window.appEventData.push({
        event: 'Error Message Presented',
        formFieldError: errorMessageArray,
        process: {
            processStepLabel: processStepLabel,
            processStepValue: processStepValue,
        },
    });
};
