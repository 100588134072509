import * as actions from '../actionTypes/storiesActionTypes';
import { Story } from 'interfaces/story';

export interface StoriesState {
    stories: Story[];
}

const initialState: StoriesState = {
    stories: [],
};

export default function StoriesReducer(
    state: StoriesState = initialState,
    action: actions.StoriesAction,
): StoriesState {
    switch (action.type) {
        case actions.GET_STORIES_SUCCESS:
            return {
                stories: action.stories,
            };
        default:
            return state;
    }
}
