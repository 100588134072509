export const dynamicCustomlinkEvent = (
    id: string,
    label: string,
    placement: string,
) => {
    if (id) {
        const cleanID = id.toString().replace(/(<([^>]+)>)/g, '');
        let inputTag = document.createElement('input');
        inputTag.setAttribute('data-analytics-id', cleanID);
        inputTag.setAttribute('data-analytics-label', label);
        inputTag.setAttribute('data-analytics-placement', placement);
        inputTag.setAttribute('type', 'hidden');
        document.body.appendChild(inputTag);
        inputTag.click();
        inputTag.remove();
    }
};
