import MuiPagination from '@material-ui/lab/Pagination';
import { withStyles } from '@material-ui/core/styles';

const Pagination = withStyles((theme) => ({
    root: {
        display: 'flex',
        justifyContent: 'center',
    },
    ul: {
        marginTop: 40,

        '& button': {
            backgroundColor: 'transparent',
            borderRadius: 5,
            color: theme.colors.navyTint1,
            fontSize: 18,
            margin: 0,

            [theme.breakpoints.up('sm')]: {
                margin: '0 8px',
            },

            '&:hover': {
                background: 'transparent',
                color: theme.colors.purpleDark,
            },

            '&.Mui-selected': {
                backgroundColor: 'transparent',
                color: theme.colors.purpleDark,
                fontWeight: 600,

                '&:hover': {
                    background: 'transparent',
                },
            },
        },

        '& .MuiPaginationItem-ellipsis': {
            margin: 0,
            padding: '0 4px',

            [theme.breakpoints.up('sm')]: {
                padding: '0 15px',
            },
        },

        '& > li:first-child > button, & > li:last-child > button': {
            border: `solid 1px ${theme.colors.purpleDark}`,
            height: 'auto',
            padding: '0 8px',
            margin: 0,
            width: 'auto',

            '&.Mui-disabled': {
                borderColor: theme.colors.navyTint1,
            },

            '&:hover': {
                backgroundColor: theme.colors.purpleDark,
                // borderRadius: 5,
                color: theme.colors.white,
            },
        },

        '& svg': {
            fontSize: 45,
        },
    },
}))(MuiPagination);

export default Pagination;
