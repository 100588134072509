import { makeStyles } from '@material-ui/core';

const styles = makeStyles((theme) => ({
    root: {
        backgroundColor: theme.palette.primary.dark,
        marginTop: 8,
    },
    content: {
        color: theme.footer.color,
        fontFamily: theme.footer.fontFamily,
        fontSize: theme.footer.fontSize,
        fontWeight: 400,
        lineHeight: '150%',
        padding: `${theme.spacing(3)}px 0 ${theme.spacing(6)}px`,

        [theme.breakpoints.up('sm')]: {
            display: 'flex',
            justifyContent: 'space-between',
        },

        [theme.breakpoints.up('md')]: {
            padding: `${theme.spacing(5)}px 0 ${theme.spacing(10)}px`,
        },
    },
    advisors: {
        fontSize: 14,
        lineHeight: '150%',
        [theme.breakpoints.up('sm')]: {
            fontSize: 22,
        },
    },
    privacyLink: {
        color: theme.footer.color,
        display: 'block',
        marginBottom: '4px',
    },
    bodyWrapper: {
        maxWidth: '100%',
        width: '100%',

        [theme.breakpoints.up('sm')]: {
            maxWidth: 500,
            marginLeft: '10%',
        },
    },
    dividerWrapper: {
        margin: '16px 0',
    },
    evidonNotice: {
        display: 'block',
        marginBottom: '16px',
        '& a': {
            color: `${theme.footer.color} !important`,
            textDecoration: 'underline !important',
        },
    },
    contactUstLink: {
        color: theme.footer.color,
        marginBottom: '8px',
    },
}));

export default styles;
