import { FC, ReactElement } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import clsx from 'clsx';

/* material */
import Grid from '@material-ui/core/Grid';

/* components */
import { Divider, PageWrapper } from 'components';
import FooterDisclosure from './FooterDisclosure';

import { Link } from 'react-router-dom';

/* styles */
import styles from './styles';

/* constants */
import { GENERIC } from 'constants/plansponsors';

interface FooterProps {
    contactInfoElement: ReactElement | null;
    isAdvisorSite?: boolean;
    storyDisclaimerElement: ReactElement | null;
    planSponsorCompany?: string;
    slug: String;
}

const Footer: FC<FooterProps> = ({
    contactInfoElement,
    isAdvisorSite = false,
    planSponsorCompany,
    storyDisclaimerElement,
    slug,
}) => {
    const classes = styles();
    const intl = useIntl();
    const contactUsSubject = intl.formatMessage({ id: 'contactUs.subject' });
    const contactUsBody = intl.formatMessage(
        { id: 'contactUs.body' },
        { company: planSponsorCompany === GENERIC ? '' : planSponsorCompany },
    );
    const emailString = `mailto:technical_support@retirementplaninsights.com?&subject=${contactUsSubject}&body=${contactUsBody}`;

    return (
        <footer data-qa="footer" className={classes.root}>
            <PageWrapper>
                <Grid container spacing={1} justifyContent="center">
                    <Grid item xs={12} md={10}>
                        <div className={classes.content}>
                            {contactInfoElement}
                            <div className={classes.bodyWrapper}>
                                {storyDisclaimerElement}
                                <div className={classes.dividerWrapper}>
                                    <Divider />
                                </div>
                                <FooterDisclosure
                                    isAdvisorSite={isAdvisorSite}
                                />
                                <Link
                                    to={`/${slug}/privacy`}
                                    target="_blank"
                                    className={classes.privacyLink}
                                    data-analytics-label="trackLink:anchorClick"
                                    data-analytics-id="Privacy Policy"
                                    data-analytics-placement="footer:bottom-right"
                                >
                                    Privacy Policy
                                </Link>
                                <FormattedMessage id="contactUs.text" />{' '}
                                <a
                                    className={classes.contactUstLink}
                                    href={emailString}
                                    data-analytics-label="trackLink:anchorClick"
                                    data-analytics-id="Contact Us"
                                    data-analytics-placement="footer:bottom-right"
                                >
                                    <FormattedMessage id="contactUs.contactUs" />
                                </a>
                                <span
                                    className={clsx(
                                        classes.evidonNotice,
                                        'evidon-notice-link',
                                    )}
                                ></span>
                                <p>
                                    <FormattedMessage id="footer.respectiveCompanyDisclaimer" />
                                </p>
                                <p>
                                    <FormattedMessage id="footer.copyright" />
                                </p>
                            </div>
                        </div>
                    </Grid>
                </Grid>
            </PageWrapper>
        </footer>
    );
};

export default Footer;
