const stg = {
    envName: 'stg',
    issuer:
        'https://identity-advisor.stg.capitalgroup.com/oauth2/aus1evoyz33AkQkwZ0h8',
    redirect_uri: '/advisor/implicit/callback',
    client_id: '0oa1ezg9se3jwh3zk0h8',
    scopes: ['openid', 'profile', 'email'],
};

export default stg;
