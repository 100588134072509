import { PlanSponsorConfig } from 'store/reducers/planSponsorReducer';
import * as actions from '../actionTypes/planSponsorActionTypes';

export function getPlanSponsor(slug: string): actions.GetPlanSponsorAction {
    return {
        type: actions.GET_PLAN_SPONSOR,
        slug,
    };
}

export function getPlanSponsorSuccess(
    config: PlanSponsorConfig,
): actions.GetPlanSponsorSuccessAction {
    return {
        type: actions.GET_PLAN_SPONSOR_SUCCESS,
        config,
    };
}

export function getPlanSponsorFailure(
    error: Error | string,
): actions.GetPlanSponsorFailureAction {
    return {
        type: actions.GET_PLAN_SPONSOR_FAILURE,
        error,
    };
}
