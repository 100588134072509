import { GENERIC } from 'constants/plansponsors';
import Url from 'url-parse';

export const getSlugUrl = () => {
    const windowLocation = window.location;
    const url = new Url(windowLocation.href);
    const urlPathArray = url.pathname.split('/');
    let planSponsorSlug: string = GENERIC;
    if (urlPathArray.length >= 2 && urlPathArray[1].length > 0) {
        planSponsorSlug = urlPathArray[1];
    }
    if (planSponsorSlug === 'unsubscribe') {
        return GENERIC;
    }
    return planSponsorSlug.toLowerCase();
};

export const isUrlExternal = (url: string): boolean => {
    const urlRgx = /https?:\/\/(?:[\w\d-])/;
    return urlRgx.test(url);
};

export const getTitleFromPath = (path: string) => {
    if (path.lastIndexOf('/') > -1) {
        return path.substring(path.lastIndexOf('/') + 1);
    } else {
        return path;
    }
};

export const objectToUrlSearchParams = (obj: {
    [key: string]: string | number;
}) => {
    return Object.keys(obj)
        .map((key) => {
            return `${key}=${encodeURIComponent(obj[key])}`;
        })
        .join('&');
};
