import React, { FC } from 'react';
import clsx from 'clsx';

/* material */
import {
    Select as MaterialSelect,
    MenuItem,
    FormControl,
    InputLabel,
} from '@material-ui/core';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import DoneIcon from '@material-ui/icons/Done';
import { Theme, withStyles, WithStyles } from '@material-ui/core/styles';

/* utils */
import { pxToRem } from 'theme/utils';

const styles = (theme: Theme) => ({
    root: {
        marginBottom: 24,

        '& .MuiInputLabel-root': {
            color: theme.select.color,
            fontFamily: theme.select.fontFamily,
            fontSize: theme.select.label.fontSize,
            fontWeight: 500,
            marginBottom: 8,
            position: 'relative',
            transform: 'none',
        },

        '& .MuiFormLabel-root.Mui-focused': {
            color: theme.select.label.focused.color,
        },

        '& .MuiSelect-icon': {
            color: theme.select.icon.color,
        },
    },
    input: {
        backgroundColor: theme.colors.white,
        color: theme.select.color,
        fontFamily: theme.select.fontFamily,
        fontSize: theme.select.fontSize,

        '& fieldset': {
            border: theme.select.input.border,
        },

        '& .MuiSelect-outlined': {
            color: theme.select.color,
            padding: '15px 48px 15px 16px',
            whiteSpace: 'normal',

            '& .MuiSvgIcon-root': {
                display: 'none',
            },
        },

        '&.MuiOutlinedInput-root.Mui-focused fieldset': {
            border: theme.select.input.focused.border,
        },
    },
    menu: {
        borderRadius: 4,
        boxShadow: theme.tenureCalculator.menu.boxShadow,
        marginTop: 8,
    },
    list: {
        '& .MuiMenuItem-root': {
            fontFamily: theme.tenureCalculator.list.fontFamily,
            fontSize: pxToRem(18),
            paddingRight: 48,
            whiteSpace: 'normal',
            maxWidth: '90vw',

            '&.Mui-selected': {
                fontWeight: 600,
            },

            '& .MuiSvgIcon-root': {
                position: 'absolute',
                right: 18,
            },
        },
    },
    error: {
        '& > p': {
            color: theme.colors.red,
            fontSize: 14,
            lineHeight: '120%',
            marginTop: 3,
            padding: 0,
        },
        '& fieldset': {
            borderColor: theme.colors.red,
        },
    },
});

export type MenuItems = {
    text: string;
    value: unknown;
};

interface SelectProps extends WithStyles<typeof styles> {
    analyticId?: string;
    analyticLabel?: string;
    analyticPlacement?: string;
    children?: React.ReactNode;
    className?: string;
    'data-qa': string;
    error?: boolean;
    helperText?: string;
    id: string;
    items: MenuItems[];
    label: string;
    name: string;
    onChange: (
        event: React.ChangeEvent<{
            name?: string | undefined;
            value: unknown;
        }>,
        child?: React.ReactNode,
    ) => void;
    value?: unknown;
}

const Select: FC<SelectProps> = (props: SelectProps) => {
    const {
        analyticId,
        analyticLabel,
        analyticPlacement,
        classes,
        className = '',
        error = false,
        helperText = '',
        id,
        items,
        label,
        name,
        onChange,
        value,
        ...other
    } = props;

    return (
        <FormControl
            {...other}
            className={clsx(classes.root, className, error && classes.error)}
        >
            <InputLabel shrink={false} htmlFor={id}>
                {label}
            </InputLabel>
            <MaterialSelect
                variant="outlined"
                labelId="demo-simple-select-label"
                id={id}
                name={name}
                value={value}
                onChange={onChange}
                className={classes.input}
                MenuProps={{
                    anchorOrigin: { vertical: 'bottom', horizontal: 'center' },
                    transformOrigin: { vertical: 'top', horizontal: 'center' },
                    getContentAnchorEl: null,
                    classes: { paper: classes.menu, list: classes.list },
                }}
                IconComponent={ExpandMoreIcon}
            >
                {items.map((item, idx) => {
                    return (
                        <MenuItem
                            data-analytics-label={analyticLabel}
                            data-analytics-id={
                                analyticId &&
                                `${analyticId}${item.text.toLowerCase()}`
                            }
                            data-analytics-placement={analyticPlacement}
                            key={idx}
                            value={item.value as string}
                        >
                            {item.text}
                            {item.value === value ? <DoneIcon /> : ''}
                        </MenuItem>
                    );
                })}
            </MaterialSelect>
            {error && helperText && (
                <p className={classes.error}>{helperText}</p>
            )}
        </FormControl>
    );
};

export default withStyles(styles, { withTheme: true })(Select);
