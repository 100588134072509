import { FC } from 'react';
import { FormattedMessage } from 'react-intl';

/* material */
import { makeStyles } from '@material-ui/core';

/* utils */
import { pxToRem } from 'theme/utils';

const useStyles = makeStyles((theme) => ({
    downloadError: {
        border: `2px solid ${theme.colors.purple}`,
        borderRadius: '4px',
        backgroundColor: theme.colors.white,
        boxShadow: '0 0 8px 4px rgba(143,121,255,0.4)',
        color: theme.colors.valencia,
        fontSize: pxToRem(16),
        lineHeight: pxToRem(27),
        padding: `${pxToRem(5)} ${pxToRem(30)}`,
    },
}));

interface BannerInlineErrorProps {
    messageId: string;
}

const BannerInlineError: FC<BannerInlineErrorProps> = ({ messageId }) => {
    const classes = useStyles();
    return (
        <div className={classes.downloadError}>
            <FormattedMessage id={messageId} />
        </div>
    );
};

export default BannerInlineError;
