import { FC } from 'react';
import { FontWeightProperty } from 'csstype';
import { useHistory } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { FormattedMessage, useIntl } from 'react-intl';

/* material */
import { makeStyles } from '@material-ui/core/styles';
import { Button, Grid } from '@material-ui/core';

/* interfaces */
import { pxToRem } from 'theme/utils';
import { AppState } from 'store/store/configureStore';
import PageWrapper from 'components/PageWrapper';

/* utils */
import useGetPath from '@capgroup-cxt/aem-react-utils/lib/hooks/useGetPath';

const useStyles = makeStyles((theme) => ({
    container: {
        backgroundColor: theme.colors.white,
        display: 'flex',
        flexDirection: 'column',
        fontFamily: "'Inter', sans-serif",

        [theme.breakpoints.up('sm')]: {
            flexDirection: 'row',
            height: 225,
        },
    },
    leftContent: {
        backgroundPosition: 'top',
        backgroundSize: 'cover',
        height: 200,
        width: '100%',
        [theme.breakpoints.up('sm')]: {
            backgroundPosition: 'top center',
            height: '100%',
            maxWidth: '40%',
        },
    },
    rightContent: {
        color: theme.colors.navy,
        margin: `0 auto ${theme.spacing(1.875)}px`,
        padding: '0 13px',
        position: 'relative',
        textAlign: 'center',
        [theme.breakpoints.up('sm')]: {
            marginBottom: 0,
            minWidth: 520,
            padding: '0 20px',
        },
    },
    title: {
        fontFamily: theme.quizHero.title.fontFamily,
        fontSize: pxToRem(22),
        fontWeight: theme.quizHero.title.fontWeight as FontWeightProperty,
        lineHeight: pxToRem(33),
        marginBottom: 10,
        paddingTop: 20,
        [theme.breakpoints.up('sm')]: {
            textAlign: 'center',
            marginBottom: 15,
            paddingTop: 20,
        },
    },
    description: {
        fontFamily: theme.quizHero.description.fontFamily,
        fontSize: pxToRem(18),
        lineHeight: '27px',
        maxWidth: 500,
        marginBottom: theme.spacing(1.25),
        [theme.breakpoints.up('sm')]: {
            marginBottom: 25,
            textAlign: 'center',
        },
    },
    button: {
        display: 'inline-block',
        textTransform: 'unset',
        width: 176,
    },
    iconBundle: {
        alignItems: 'center',
        display: 'flex',
        flexWrap: 'wrap',
    },
    iconWrapper: {
        display: 'flex',
        justifyContent: 'center',
        marginTop: 20,

        [theme.breakpoints.up('sm')]: {
            marginTop: 0,
            position: 'absolute',
            right: 20,
            top: 20,
        },
    },
    backgroundWrapper: {
        backgroundColor: theme.quizHero.backgroundColor,
        padding: '60px 0',
    },
}));

const QuizHero: FC = () => {
    const classes = useStyles();
    const history = useHistory();
    const intl = useIntl();
    const planSponsorSlug = useSelector(
        (state: AppState) => state.planSponsorSlug.id,
    );

    const backgroundImage = useGetPath(
        '/content/dam/leapfrog/images/heros/benchmark-promo.jpg',
    );

    return (
        <div className={classes.backgroundWrapper}>
            <PageWrapper>
                <Grid container justifyContent="center">
                    <Grid item xs={12} md={10}>
                        <div className={classes.container}>
                            <div
                                className={classes.leftContent}
                                style={{
                                    backgroundImage: `url(${backgroundImage})`,
                                }}
                            />
                            <div className={classes.rightContent}>
                                <div className={classes.title}>
                                    <FormattedMessage id="quiz.hero.title" />
                                </div>
                                <div className={classes.description}>
                                    <FormattedMessage id="quiz.hero.desc" />
                                </div>
                                <Button
                                    data-analytics-label="trackLink:buttonClick"
                                    data-analytics-id={intl.formatMessage({
                                        id: 'quiz.hero.buttonLabel',
                                    })}
                                    data-analytics-placement="homepage : bottom-center"
                                    className={classes.button}
                                    fullWidth
                                    variant="contained"
                                    onClick={() => {
                                        history.push(
                                            `/${planSponsorSlug}/quiz/plan-health-benchmarking-assessment`,
                                        );
                                    }}
                                >
                                    <FormattedMessage id="quiz.hero.buttonLabel" />
                                </Button>
                            </div>
                        </div>
                    </Grid>
                </Grid>
            </PageWrapper>
        </div>
    );
};

export default QuizHero;
