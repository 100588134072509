import { FC } from 'react';
import { useOktaAuth } from '@okta/okta-react';
import { useHistory } from 'react-router';

/* material */
import { Button, makeStyles } from '@material-ui/core';
import { ADVISOR_LOGIN_PATH, ADVISOR_LOGOUT_PATH } from 'routes/AdvisorRoutes';

/* util */
import { deleteCookie } from 'utils/cookie';

const useStyles = makeStyles((theme) => ({
    button: {
        backgroundColor: theme.colors.white,
        marginBottom: 40,
        marginTop: 40,
        padding: '10px 32px',
        width: 150,

        '&:hover': {
            backgroundColor: theme.colors.white,
        },

        [theme.breakpoints.up('sm')]: {
            marginBottom: 0,
            marginTop: 0,
            width: 120,
        },

        [theme.breakpoints.up('md')]: {
            width: 150,
        },
    },
}));

interface LoginButtonProps {
    onClick?: () => void;
    redirect?: string;
}

const LoginButton: FC<LoginButtonProps> = ({ onClick, redirect }) => {
    const history = useHistory();
    const classes = useStyles();
    const { authState, oktaAuth } = useOktaAuth();

    if (!authState) return null;

    const handleClick = async () => {
        onClick && onClick();

        if (authState.isAuthenticated) {
            deleteCookie('AEID');
            await oktaAuth.signOut({
                postLogoutRedirectUri: `${window.location.origin}${ADVISOR_LOGOUT_PATH}`,
            });
            return;
        }

        history.push(ADVISOR_LOGIN_PATH, { loginRedirect: redirect });
    };

    const isLoggedIn = authState?.isAuthenticated;

    return (
        <Button
            data-analytics-placement="Button:top-right"
            data-analytics-label="trackLink:buttonClick"
            data-analytics-id={`Header login button-${
                isLoggedIn ? 'Log Out' : 'Log in'
            } `}
            id={isLoggedIn ? 'logout' : ''}
            className={classes.button}
            onClick={handleClick}
            variant="outlined"
        >
            {isLoggedIn ? 'Log Out' : 'Log in'}
        </Button>
    );
};

export default LoginButton;
