import * as actions from '../actionTypes/planSponsorActionTypes';
import { roundToNumberOfDigits } from 'utils/math';
import {
    CG_DEMO,
    CG_INSTITUTIONAL,
    PUBLIC,
    SITE_BRAND_CG,
    SITE_BRAND_RPI,
    SITE_FOOTER_CUSTOM,
    SITE_FOOTER_DEFAULT,
    SITE_FOOTER_DETAILED,
} from 'constants/plansponsors';

export type PlanSponsorState = {
    config: PlanSponsorConfig | undefined;
    isFetched: boolean;
};

export type PlanSponsorConfig = {
    benchmark: Benchmark;
    businessDevelopmentManagers?: BusinessDevelopmentManagers[];
    financialAdvisors?: FinancialAdvisor[];
    id: number;
    industries?: Industry[];
    isCGInstitutional: boolean;
    brandType: PlanSponsorBrandType;
    name: string;
    nationals: National[];
    officialName?: string;
    partyId: number;
    partyTaxId?: string;
    sites: Site[];
    type?: PlanSponsorConfigType;
};

export type PlanSponsorBrandType = typeof SITE_BRAND_CG | typeof SITE_BRAND_RPI;

export type PlanSponsorConfigType =
    | typeof PUBLIC
    | typeof CG_DEMO
    | typeof CG_INSTITUTIONAL;

export type BusinessDevelopmentManagers = BusinessDevelopmentInfo & {
    businessDevelopmentSpecialists: BusinessDevelopmentInfo[];
};

export type BusinessDevelopmentInfo = {
    id: number;
    email: string;
    firstName: string;
    lastName: string;
    title: string;
    phoneNumber: string;
    other: string;
    fullName: string;
    fullNameWithDesignation: string;
};

export type Benchmark = {
    planSponsorId: number;
    numberOfEmployees: number;
    tenure: number;
};

export type FinancialAdvisor = {
    id: number;
    designation: string;
    email: string;
    firstName: string;
    lastName: string;
    firmName: string;
    firmWebUrl: string;
    fullName: string;
    fullNameWithDesignation: string;
    title: string;
    phoneNumber: string;
    other: string;
};

export type Industry = {
    id: number;
    naicsCode2: string;
    name: string;
    benchmark: IndustryBenchmark;
};

export type IndustryBenchmark = {
    industryId: number;
    deferralRate?: number;
    deferralRateUpdateTimestamp?: string;
    averageSalary?: number;
    averageSalaryUpdateTimestamp?: string;
    participationRate?: number;
    participationRateUpdateTimestamp?: string;
    matchRate?: number;
    matchRateUpdateTimestamp?: string;
    tenure?: number;
    autoEnrollmentRate?: number;
    autoEnrollmentRateUpdateTimestamp?: string;
} & { [key: string]: number | string };

export type National = {
    id: number;
    countryId: number;
    countryName: string;
    benchmark: NationalBenchmark;
};

export type NationalBenchmark = {
    nationalId: number;
    deferralRate: number;
    deferralRateUpdateTimestamp: string;
    averageSalary: number;
    averageSalaryUpdateTimestamp: string;
    participationRate: number;
    participationRateUpdateTimestamp: string;
    matchRate: number;
    matchRateUpdateTimestamp: string;
    tenure: number;
    autoEnrollmentRate: number;
    autoEnrollmentRateUpdateTimestamp: string;
    targetDateReturnDiff: number;
    qdiaBalanceFund: number;
    qdiaManagedAccount: number;
    qdiaStableValueFund: number;
    qdiaTargetDateFund: number;
    qdiaOther: number;
    qdiaOtherUpdateTimestamp: string;
    planSponsorCouncilOfAmericaAnnualSurveyAnniversary: string;
} & { [key: string]: number | string };

export type Site = {
    brandType: PlanSponsorBrandType;
    customFooter?: string;
    displayPlanSponsorNameInPreparedForByStatement: boolean;
    displaySiteOwnerFirmNameInPreparedForByStatement: boolean;
    displaySiteOwnerNameInPreparedForByStatement: boolean;
    footerType:
        | typeof SITE_FOOTER_CUSTOM
        | typeof SITE_FOOTER_DEFAULT
        | typeof SITE_FOOTER_DETAILED;
    id: number;
    mainLogoImageAemPath?: string;
    urlSlug: string;
};

const initialState: PlanSponsorState = {
    config: undefined,
    isFetched: false,
};

export const BENCHMARK_KEYS = [
    'autoEnrollmentRate',
    'deferralRate',
    'matchRate',
    'participationRate',
];

export const QDIA_KEYS = [
    'qdiaBalanceFund',
    'qdiaManagedAccount',
    'qdiaStableValueFund',
    'qdiaTargetDateFund',
    'qdiaOther',
];

export const formatBenchmarkNumbers = (
    benchmarkSettings: IndustryBenchmark | NationalBenchmark,
) => {
    const benchmark = { ...benchmarkSettings };
    BENCHMARK_KEYS.forEach((key: string) => {
        const benchmarkValue = benchmark[key] as number;
        benchmarkValue && (benchmark[key] = Math.floor(benchmarkValue * 100));
    });
    QDIA_KEYS.forEach((key: string) => {
        const benchmarkValue = benchmark[key] as number;
        benchmarkValue &&
            (benchmark[key] = roundToNumberOfDigits(benchmarkValue * 100, 1));
    });
    return benchmark;
};

export default function planSponsorReducer(
    state: PlanSponsorState = initialState,
    action: actions.PlanSponsorAction,
): PlanSponsorState {
    switch (action.type) {
        case actions.GET_PLAN_SPONSOR_SUCCESS:
            const industry = action.config.industries?.[0];
            const industries = ((industry && [
                {
                    ...industry,
                    benchmark: formatBenchmarkNumbers(industry.benchmark),
                },
            ]) ||
                []) as Industry[];
            const national = action.config.nationals[0];
            const nationals = ((national && [
                {
                    ...national,
                    benchmark: formatBenchmarkNumbers(national.benchmark),
                },
            ]) ||
                []) as National[];

            return {
                ...state,
                config: {
                    id: action.config.id,
                    partyId: action.config.partyId,
                    name: action.config.name,
                    officialName: action.config.officialName,
                    partyTaxId: action.config.partyTaxId,
                    benchmark: action.config.benchmark,
                    financialAdvisors: action.config.financialAdvisors,
                    businessDevelopmentManagers:
                        action.config.businessDevelopmentManagers,
                    industries,
                    nationals,
                    sites: action.config.sites,
                    type: action.config.type,
                    isCGInstitutional: action.config.type === CG_INSTITUTIONAL,
                    brandType:
                        action.config.sites[0]?.brandType || SITE_BRAND_RPI,
                },
                isFetched: true,
            };
        case actions.GET_PLAN_SPONSOR_FAILURE:
            return {
                config: undefined,
                isFetched: true,
            };
        default:
            return state;
    }
}
