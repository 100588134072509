import { isTrackAnalytics } from 'analytics/utils';
import { RPI_ADVISOR_PORTAL } from './constants';
import {
    aemBaseURL,
    aemRPIAnalyticsPath,
    aemNodeExtension,
} from 'constants/aem';
import { getSlugUrl } from 'utils/url';
import { GENERIC, HOME } from 'constants/site';

export const analyticsPageEvent = (nodePath: string, overrides = {}) => {
    if (!isTrackAnalytics()) {
        return;
    }
    try {
        const { Capgroup, Analytics } = window;
        if (!Capgroup || !Analytics) return;

        Capgroup.ContextHub.ContextHubDataSet.nextGenSendingData = false;
        Capgroup.ContextHub.ContextHubDataSet.legacySendingData = false;
        Analytics.singlePageApp.recordStateByURI(nodePath, overrides);

        const pagestore = window.Capgroup.ContextHub.stores.page;
        pagestore.setItem(
            'info/nodePath',
            nodePath.replace(aemNodeExtension, ''),
        );
    } catch (e) {
        console.log('Analytics Error: ', e);
    }
};

export const analyticsAdvisorPageViewEvent = (analyticNodePath: string) => {
    const pagePathArr = analyticNodePath.split('/');
    let pagePath = pagePathArr.slice(pagePathArr.length - 2).join(' > ');
    if (pagePathArr.length === 2) {
        pagePath = `advisor > ${pagePathArr[1]}`;
    }

    analyticsPageEvent(
        `${aemBaseURL}${aemRPIAnalyticsPath}${analyticNodePath}.analytics-json.js`,
        {
            pageStore: {
                info: {
                    name: pagePath,
                },
                category: {
                    audience: RPI_ADVISOR_PORTAL,
                },
            },
        },
    );
};

export const analyticsAuthoredStoryEvent = (
    data = {
        storyAuthoredPath: '',
    },
) => {
    const planSponsor: string = getSlugUrl() || 'generic';
    const jsonFileName = `${aemBaseURL}${data.storyAuthoredPath}.analytics-json.js`;
    const overrides = {
        pageStore: {
            category: {
                audience: planSponsor,
            },
        },
    };
    analyticsPageEvent(jsonFileName, overrides);
};

export const analyticsHomepageViewEvent = () => {
    const planSponsor: string = getSlugUrl() || GENERIC;
    const jsonFileName = `${aemBaseURL}${aemRPIAnalyticsPath}home.analytics-json.js`;
    let overrides = {
        pageStore: {
            info: {
                name: `${planSponsor} > ${HOME}`,
            },
            category: {
                audience: planSponsor,
            },
        },
    };
    analyticsPageEvent(jsonFileName, overrides);
};

export const analyticsPageViewEvent = (
    data = {
        path: '',
    },
) => {
    if (!isTrackAnalytics()) {
        return;
    }
    try {
        const { Capgroup, Analytics } = window;
        if (!Capgroup || !Analytics) return;

        let AEMpath = '';
        const currentPath =
            data.path.length > 1 ? data.path.replace(/\/$/, '') : data.path;
        const pathSlashCount = (currentPath.match(/\//g) || []).length;
        const getLastDirectoryPath = currentPath.match(/[^/]+(?=\/$|$)/);
        let routeName = getLastDirectoryPath && getLastDirectoryPath[0];
        const planSponsor: string = getSlugUrl() || 'generic';
        if (pathSlashCount === 1) {
            AEMpath = 'home';
        } else {
            if (pathSlashCount > 1) {
                switch (routeName) {
                    case 'privacy':
                        AEMpath = 'home/privacy';
                        break;
                    case '404':
                        AEMpath = 'home/404';
                        break;
                    case 'form':
                        AEMpath = 'home/ccpa-form';
                        break;
                    case 'home':
                        AEMpath = 'home/advisor/home';
                        break;
                    case 'faqs':
                        AEMpath = 'home/advisor/faqs';
                        break;
                    case 'manage':
                        AEMpath = 'home/advisor/manage';
                        break;
                    case 'learn':
                        AEMpath = 'home/advisor/learn';
                        break;
                    default:
                    // don't track
                }
            }
        }
        // can't/won't resolve route path to AEM node...
        if (!AEMpath) {
            //console.warn(`Analytics - No path return - ${routeName}`);
            return;
        }

        const jsonFileName = `${aemBaseURL}${aemRPIAnalyticsPath}${AEMpath}.analytics-json.js`;
        let overrides = {};
        if (AEMpath === 'home') {
            overrides = {
                pageStore: {
                    info: {
                        name: `${planSponsor} &gt; home`,
                    },
                    category: {
                        audience: planSponsor,
                    },
                },
            };
        } else {
            overrides = {
                pageStore: {
                    info: {
                        name: AEMpath.split('/').join(' &gt; '),
                    },
                    category: {
                        audience: planSponsor,
                    },
                },
            };
        }

        Capgroup.ContextHub.ContextHubDataSet.nextGenSendingData = false;
        Capgroup.ContextHub.ContextHubDataSet.legacySendingData = false;
        Analytics.singlePageApp.recordStateByURI(jsonFileName, overrides);

        const pagestore = window.Capgroup.ContextHub.stores.page;
        const nodePathValue = `${aemRPIAnalyticsPath}${AEMpath}`;
        pagestore.setItem('info/nodePath', nodePathValue);
    } catch (e) {
        console.log('Analytics Error: ', e);
    }
};
