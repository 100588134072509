import loadable from '@loadable/component';

/* component */
import Loader from 'components/Loader/Loader';

export const asyncLoadComponent = (componentPath: string) =>
    loadable(() => import(`../components/${componentPath}`), {
        fallback: <Loader />,
    });

export const asyncLoadContainer = (componentPath: string) =>
    loadable(() => import(`../containers/${componentPath}`), {
        fallback: <Loader />,
    });
