/* constants */
import {
    ADVISOR_HOME_PATH,
    ADVISOR_FAQS_PATH,
    ADVISOR_LEARN_PATH,
    ADVISOR_MANAGE_PATH,
} from 'routes/AdvisorRoutes';

import { ADVISOR_CREATE_ADVISOR_INFO_PATH } from 'routes/AdvisorCreateRoutes';

type Link = {
    path: string;
    id: string;
    title: string;
};

export const navLinks: Link[] = [
    { path: ADVISOR_HOME_PATH, id: 'advisor.nav.home', title: 'Home' },
    {
        path: ADVISOR_CREATE_ADVISOR_INFO_PATH,
        id: 'advisor.nav.createWebsite',
        title: 'Create',
    },
    { path: ADVISOR_MANAGE_PATH, id: 'advisor.nav.manage', title: 'Manage' },
    { path: ADVISOR_LEARN_PATH, id: 'advisor.nav.learn', title: 'Learn' },
    { path: ADVISOR_FAQS_PATH, id: 'advisor.nav.faqs', title: 'Faqs' },
];
