import { FC } from 'react';
import { makeStyles } from '@material-ui/core';
import parse from 'html-react-parser';

const useStyles = makeStyles((theme) => ({
    root: {
        ...theme.typography.body1,
        '& a': {
            color: theme.colors.pacificBlue,
            textDecoration: 'none',
        },
        '& a:hover': {
            color: theme.colors.cobalt,
        },
    },
}));

interface CustomPlanSponsorContactProps {
    footer: string | undefined;
}

const CustomPlanSponsorContact: FC<CustomPlanSponsorContactProps> = ({
    footer,
}) => {
    const classes = useStyles();

    return footer ? (
        <div data-qa="custom-info" className={classes.root}>
            {parse(footer)}
        </div>
    ) : null;
};

export default CustomPlanSponsorContact;
