import { combineReducers } from 'redux';
import { connectRouter } from 'connected-react-router';
import planSponsorReducer from './planSponsorReducer';
import planSponsorSlugReducer from './planSponsorSlugReducer';
import isLoadingReducer from './isLoadingReducer';
import errorReducer from './errorReducer';
import storiesReducer from './storiesReducer';
import quizzesReducer from './quizesReducer';
import industryReducer from './industryReducer';
import createWebsiteReducer from './createWebsiteReducer';
import { History } from 'history';

const rootReducer = (history: History) =>
    combineReducers({
        router: connectRouter(history),
        planSponsor: planSponsorReducer,
        planSponsorSlug: planSponsorSlugReducer,
        isLoading: isLoadingReducer,
        error: errorReducer,
        stories: storiesReducer,
        quizzes: quizzesReducer,
        industry: industryReducer,
        createWebsite: createWebsiteReducer,
    });

export default rootReducer;
