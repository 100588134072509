import { Redirect, Route, useRouteMatch, Switch } from 'react-router-dom';

/* utils */
import { asyncLoadContainer } from 'utils/asyncLoad';

export const ADVISOR_CREATE_ADVISOR_INFO_PATH = '/advisor/create/advisorInfo';
export const ADVISOR_CREATE_PLAN_SPONSOR_PATH =
    '/advisor/create/plansponsorInfo';
export const ADVISOR_CREATE_REVIEW_PATH = '/advisor/create/review';
export const ADVISOR_CREATE_SUCCESS_PATH = '/advisor/create/success';

const AdvisorInfo = asyncLoadContainer('Advisor/Create/AdvisorInfo');
const PlanSponsorInfo = asyncLoadContainer(
    'Advisor/Create/PlanSponsorInfo/PlanSponsorInfo',
);
const Review = asyncLoadContainer('Advisor/Create/Review');
const Success = asyncLoadContainer('Advisor/Create/Success');
const NotFound = asyncLoadContainer('404');

const AdvisorCreateRoutes = () => {
    const { path } = useRouteMatch();

    return (
        <Switch>
            <Route exact path={`${path}`}>
                <Redirect to={`${path}/advisorInfo`} />
            </Route>
            <Route path={`${path}/advisorInfo`} component={AdvisorInfo} />
            <Route
                path={`${path}/plansponsorInfo`}
                component={PlanSponsorInfo}
            />
            <Route path={`${path}/review`} component={Review} />
            <Route path={`${path}/success`} component={Success} />
            <Route component={NotFound} />
        </Switch>
    );
};

export default AdvisorCreateRoutes;
