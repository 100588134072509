import { FC } from 'react';

/* material */
import { makeStyles } from '@material-ui/core/styles';
import StarIcon from '@material-ui/icons/Star';
import BarChartIcon from '@material-ui/icons/BarChart';

const iconMap: { [key: string]: JSX.Element } = {
    new: <StarIcon />,
    resource: <BarChartIcon />,
    featured: <StarIcon />,
};

type Badges = {
    textPaddingTop: string;
    styles: {
        [key: string]: {
            backgroundColor: string;
            color: string;
        };
    };
};

interface BadgeStyleProps {
    backgroundColor: string | undefined;
    color: string | undefined;
    status: string;
}

const useStyles = makeStyles((theme) => ({
    root: {
        alignItems: 'center',
        backgroundColor: (props: BadgeStyleProps) => {
            const { backgroundColor, status } = props;
            return (
                backgroundColor ||
                (theme.badges as Badges).styles[status].backgroundColor
            );
        },
        borderRadius: '4px',
        color: (props: BadgeStyleProps) => {
            const { color, status } = props;
            return color || (theme.badges as Badges).styles[status].color;
        },
        display: 'inline-flex',
        fontSize: '18px',
        fontWeight: 600,
        marginBottom: 16,
        padding: '2px 8px',
        textTransform: 'capitalize',
    },
    icon: {
        alignItems: 'center',
        display: 'flex',
        height: 28,
        marginRight: 4,
    },
    label: {
        height: 28,
        lineHeight: '28px',
        paddingTop: theme.badges.textPaddingTop,
    },
}));

interface BadgeProps {
    backgroundColor?: string;
    color?: string;
    status: string;
    icon?: JSX.Element;
}

const Badge: FC<BadgeProps> = ({ backgroundColor, status, color, icon }) => {
    const classes = useStyles({ backgroundColor, color, status });
    const mappedIcon = iconMap[status] || null;
    const badgeIcon = icon ? icon : mappedIcon;
    return (
        <div className={classes.root}>
            {badgeIcon ? <div className={classes.icon}>{badgeIcon}</div> : null}
            <div className={classes.label}>{status}</div>
        </div>
    );
};

export default Badge;
