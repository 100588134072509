import { FC } from 'react';
import clsx from 'clsx';

/* material */
import MaterialInput, { TextFieldProps } from '@material-ui/core/TextField';
import { Theme, withStyles, WithStyles } from '@material-ui/core/styles';
import { ClassNameMap } from '@material-ui/core/styles/withStyles';

/* utils */
import { pxToRem } from 'theme/utils';

const styles = (theme: Theme) => ({
    root: {
        backgroundColor: 'transparent',
        marginBottom: 24,

        '& .MuiInput-input': {
            borderRadius: 4,
            color: theme.colors.navy,
            fontFamily: theme.textField.fontFamily,
            fontSize: theme.textField.fontSize,
            padding: '14px 16px',
        },

        '& label + .MuiInput-formControl': {
            marginTop: 0,
        },

        '& .MuiInputAdornment-positionEnd': {
            margin: '0 8px 0 0',
        },

        '& .MuiInputAdornment-positionStart': {
            margin: '0 -4px 0 8px',
        },
    },
    helperText: {
        bottom: -28,
        fontFamily: "'Inter', sans-serif",
        fontSize: pxToRem(14),
        lineHeight: '120%',

        '&.Mui-error': {
            color: theme.colors.red,
        },
    },
    disabled: {
        opacity: 0.4,

        '&:hover': {
            boxShadow: 'none',
        },
    },
    standardInputRoot: {
        backgroundColor: theme.colors.white,
        border: `solid 1px ${theme.colors.navy}`,
        borderRadius: 4,

        transition:
            'box-shadow 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms,border 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms',

        '&:hover': {
            boxShadow: theme.textField.input.boxShadow,
        },
    },
    standardInputError: {
        border: `solid 1px ${theme.colors.red}`,

        '& .MuiInput-input': {
            color: theme.colors.red,
        },
    },
    standardInputFocused: {
        border: theme.textField.input.focus.border,
        boxShadow: theme.textField.input.boxShadow,

        '&.Mui-error': {
            border: `solid 1px ${theme.colors.red}`,
        },
    },
    standardInputLabelRoot: {
        color: theme.colors.navy,
        fontFamily: theme.textField.fontFamily,
        fontSize: theme.textField.label.fontSize,
        fontWeight: 500,
        marginBottom: 8,
        transform: 'none',
        position: 'relative' as 'relative',
    },
    standardInputLabelError: {
        '&.Mui-error': {
            color: theme.colors.red,
        },
    },
    standardInputLabelFocused: {
        '&.Mui-focused': {
            color: theme.textField.label.focused.color,
        },

        '&.Mui-error': {
            color: theme.colors.red,
        },
    },
});

type Variants = {
    [key: string]: { [key: string]: string | undefined };
};

const getInputLabelVariantStyles = (
    variant: string,
    classes: Partial<ClassNameMap>,
) => {
    const variants: Variants = {
        standard: {
            root: classes.standardInputLabelRoot,
            error: classes.standardInputLabelError,
            focused: classes.standardInputLabelFocused,
        },
    };

    return variants[variant] || variants.standard;
};

const getInputVariantStyles = (
    variant: string,
    classes: Partial<ClassNameMap>,
) => {
    const variants: Variants = {
        standard: {
            root: classes.standardInputRoot,
            error: classes.standardInputError,
            focused: classes.standardInputFocused,
        },
    };

    return variants[variant] || variants.standard;
};

type InputProps = TextFieldProps & {
    'data-qa'?: string;
    onChange?: (event: React.ChangeEvent<HTMLInputElement>) => void;
} & WithStyles<typeof styles>;

const Input: FC<InputProps> = (props: InputProps) => {
    const {
        classes,
        className,
        error = false,
        disabled = false,
        helperText = '',
        InputProps = {},
        InputLabelProps = {},
        variant = 'standard',
        ...others
    } = props;

    return (
        <MaterialInput
            {...others}
            error={error}
            disabled={disabled}
            helperText={error ? helperText : ''}
            InputLabelProps={{
                ...InputLabelProps,
                shrink: true,
                classes: getInputLabelVariantStyles(variant, classes),
            }}
            InputProps={{
                ...InputProps,
                disableUnderline: true,
                classes: getInputVariantStyles(variant, classes),
            }}
            FormHelperTextProps={{
                classes: { root: classes.helperText },
            }}
            className={clsx(
                classes.root,
                className,
                disabled && classes.disabled,
            )}
        />
    );
};

export default withStyles(styles, { withTheme: true })(Input);
