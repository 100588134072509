import Badge from './Badge';

export const getBadgeTags = (badgeTags: string[], planSponsorSlug: string) => {
    return badgeTags.reduce((previous: string[], current: string) => {
        const tagSegments = current.split('/');
        /* 
           non plansponsor specific badge
           leapfrog:badges/new
        */
        if (tagSegments.length === 2) {
            previous.push(tagSegments.pop() as string);
        }

        /*
            plansponsor specific badge
            leapfrog:badges/planpremier-tpa/new
        */
        if (tagSegments.length === 3) {
            const [, planSponsor, badge] = tagSegments;
            if (planSponsor === planSponsorSlug) {
                previous.push(badge);
            }
        }
        return previous;
    }, []);
};

export const renderBadge = (
    badgeTags: string[] = [],
    planSponsorExcludedTags: string[],
    planSponsorSlug: string,
) => {
    const badges = getBadgeTags(badgeTags, planSponsorSlug);
    const badgesToExclude = getBadgeTags(
        planSponsorExcludedTags,
        planSponsorSlug,
    );
    const excludePlanSponsorBadges = planSponsorExcludedTags.some(
        (tag) => tag.indexOf(planSponsorSlug) > -1,
    );

    if (excludePlanSponsorBadges) {
        badgesToExclude.forEach((excludeBadge: string) => {
            const foundIndex = badges.findIndex(
                (badge) => badge === excludeBadge,
            );
            if (foundIndex > -1) {
                badges.splice(foundIndex, 1);
            }
        });
    }

    if (!badges.length) return null;

    return badges.map((badge, idx) => <Badge key={idx} status={badge} />);
};
