import { FC, useState } from 'react';
import { FormattedMessage } from 'react-intl';

import clsx from 'clsx';

/* material */
import { makeStyles } from '@material-ui/core';
import List from '@material-ui/core/List';
import Divider from '@material-ui/core/Divider';

/* components */
import AdvisorInfo from './AdvisorInfo';
import MenuLink, { Link } from '../MenuLink';
import NavigationBar from 'components/NavigationBar';
import PlanSponsorLabel from './PlanSponsorLabel';

/* interfaces */
import { PlanSponsorConfig } from 'store/reducers/planSponsorReducer';
import { Story } from 'interfaces/story';
import { Quiz } from 'interfaces/quiz';

/* utils */
import { getTitleFromPath } from 'utils/url';
import { pxToRem } from 'theme/utils';
import { isBenchmarkQuizExcluded } from 'containers/Quiz/utils';
import { renderBadge } from 'components/Badge/utils';

const useStyles = makeStyles((theme) => ({
    linkList: {
        flexShrink: 0,
        overflow: 'hidden',
    },
    contactWrapper: {
        padding: 50,
    },
    list: {
        marginLeft: 40,
    },
    linkBottomBorder: {
        borderBottom: theme.topMenu.border,
    },
    link: {
        display: 'block',
        textDecoration: 'none',
    },
    divider: {
        backgroundColor: theme.topMenu.divider.backgroundColor,
    },
    navLabel: {
        color: theme.topMenu.label.color,
        fontFamily: theme.topMenu.label.fontFamily,
        fontSize: pxToRem(18),
        margin: '40px 0px 0px 55px',
    },
    badgeWrapper: {
        margin: '28px 16px -24px',
    },
}));

interface MenuProps {
    isCgInstitutional: boolean | undefined;
    planSponsorSlug: string;
    planSponsorConfig?: PlanSponsorConfig;
    quizzes: Quiz[];
    stories: Story[];
}

const Menu: FC<MenuProps> = ({
    isCgInstitutional = false,
    planSponsorSlug,
    planSponsorConfig,
    quizzes,
    stories,
}) => {
    const classes = useStyles();
    const [isMenuOpen, setIsMenuOpen] = useState<boolean>(false);

    const toggleDrawer = () => {
        setIsMenuOpen(!isMenuOpen);
    };

    const handleMenuLinkClick = (_link: Link) => {
        toggleDrawer();
    };

    const showBenchmarkQuiz = !isBenchmarkQuizExcluded(
        quizzes,
        planSponsorSlug,
    );

    const renderStoryMenuItems = () => {
        return (
            <div
                className={classes.linkList}
                role="menu"
                aria-label="main navigation"
            >
                <h2 className={classes.navLabel}>
                    <FormattedMessage id="topNav.articlesLabel" />
                </h2>
                {stories && (
                    <List className={classes.list}>
                        {stories.map((story: Story, index: number) => {
                            if (!story.id) return null;
                            const {
                                title,
                                badges = [],
                                plansponsorExcludedBadges = [],
                            } = story.properties;
                            const lastItem = stories.length - 1 === index;
                            const urlTitle = getTitleFromPath(story.path);
                            return (
                                <div key={index}>
                                    {badges.length > 0 && (
                                        <div className={classes.badgeWrapper}>
                                            {renderBadge(
                                                badges,
                                                plansponsorExcludedBadges,
                                                planSponsorSlug,
                                            )}
                                        </div>
                                    )}
                                    <MenuLink
                                        analyticId={urlTitle}
                                        analyticLabel="trackLink:anchorClick"
                                        analyticPlacement="navigation:top-right"
                                        className={clsx(
                                            classes.link,
                                            !lastItem &&
                                                classes.linkBottomBorder,
                                        )}
                                        handleClick={handleMenuLinkClick}
                                        link={{
                                            path: `/${planSponsorSlug}/story/${urlTitle}`,
                                            title,
                                        }}
                                    />
                                </div>
                            );
                        })}
                    </List>
                )}
            </div>
        );
    };
    const renderBenchmarkMenuItem = () => {
        return showBenchmarkQuiz ? (
            <>
                <Divider className={classes.divider} />
                <h2 className={classes.navLabel}>
                    <FormattedMessage id="topNav.resourcesLabel" />
                </h2>
                <List className={classes.list}>
                    <MenuLink
                        analyticId="quiz.benchmarkingAssessment"
                        analyticLabel="trackLink:anchorClick"
                        analyticPlacement="navigation:top-right"
                        className={classes.link}
                        handleClick={handleMenuLinkClick}
                        link={{
                            path: `/${planSponsorSlug}/quiz/plan-health-benchmarking-assessment`,
                            id: 'quiz.benchmarkingAssessment',
                        }}
                    />
                </List>
            </>
        ) : null;
    };
    const renderAdvisorInfo = () => {
        return planSponsorConfig ? (
            <>
                <Divider className={classes.divider} />
                <div className={classes.contactWrapper}>
                    <AdvisorInfo planSponsorConfig={planSponsorConfig} />
                </div>
            </>
        ) : null;
    };

    return (
        <NavigationBar
            handleToggleDrawer={toggleDrawer}
            isNavMenuOpen={isMenuOpen}
            isCGInstitutional={isCgInstitutional}
            isMenuVisible={!!planSponsorConfig}
            title={
                <PlanSponsorLabel
                    planSponsorSlug={planSponsorSlug}
                    planSponsorConfig={planSponsorConfig}
                />
            }
            homePath={`/${planSponsorSlug}`}
        >
            <>
                {renderStoryMenuItems()}
                {renderBenchmarkMenuItem()}
                {renderAdvisorInfo()}
            </>
        </NavigationBar>
    );
};

export default Menu;
