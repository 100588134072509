import { Quiz } from 'interfaces/quiz';
import { Quiz as QuizConfig } from 'containers/Quiz/interfaces';

export const GET_QUIZ_SUCCESS = 'quizActionTypes/QUIZ_SUCCESS';

export interface GetQuizSuccessAction {
    type: typeof GET_QUIZ_SUCCESS;
    quizzes: Quiz[];
}

export const GET_QUIZ_FAILURE = 'quizActionTypes/QUIZ_FAILURE';
export interface GetQuizFailureAction {
    type: typeof GET_QUIZ_FAILURE;
    error: Error | string;
}

export const GET_QUIZ_REQUEST = 'quizActionTypes/GET_QUIZ_REQUEST';
export interface GetQuizRequestAction {
    type: typeof GET_QUIZ_REQUEST;
    industry: string;
    planSponsor: string;
}

export const START_QUIZ = 'START_QUIZ';
export interface StartQuizAction {
    type: typeof START_QUIZ;
    quiz: QuizConfig;
}

export const QUESTION_ANSWERED = 'QUESTION_ANSWERED';

export interface AnswerQuestionAction {
    type: typeof QUESTION_ANSWERED;
    questionNumber: number;
    answer: any;
}

export type QuizAction =
    | GetQuizSuccessAction
    | GetQuizRequestAction
    | GetQuizFailureAction
    | StartQuizAction
    | AnswerQuestionAction;
