import { FC } from 'react';

/* material */
import { Typography } from '@material-ui/core';
import { makeStyles } from '@material-ui/core';

/* types */
import { BusinessDevelopmentManagers } from 'store/reducers/planSponsorReducer';

/* components */
import { LinkButton } from 'components/Buttons';

/* utils */
import { phoneFormatRegex } from 'utils/regex/phoneNumbers';

const useStyles = makeStyles((theme) => ({
    root: {
        fontSize: 14,
        lineHeight: '150%',
        [theme.breakpoints.up('sm')]: {
            fontSize: 22,
        },
    },
    advisor: {
        lineHeight: '33px',
        marginBottom: 20,
        '& > h4': {
            fontFamily: "'AvenirNextLTCom-Bold', arial, sans-serif",
            fontWeight: 'normal',
        },
    },
}));

interface InstitutionalAdvisorInfoProps {
    businessDevelopmentManagers: BusinessDevelopmentManagers[] | undefined;
}

const InstitutionalAdvisorInfo: FC<InstitutionalAdvisorInfoProps> = ({
    businessDevelopmentManagers,
}) => {
    const classes = useStyles();

    if (!businessDevelopmentManagers || !businessDevelopmentManagers.length)
        return null;

    const {
        fullName: BDMFullName,
        other: BDMOther,
        phoneNumber: BDMPhoneNumber,
        email: BDMEmail,
        title: BDMTitle,
        businessDevelopmentSpecialists,
    } = businessDevelopmentManagers?.[0] || {};

    const {
        fullName: BDSFullName,
        other: BDSOther,
        phoneNumber: BDSPhoneNumber,
        email: BDSEmail,
        title: BDSTitle,
    } = businessDevelopmentSpecialists?.[0] || {};

    const mailToFunc = (email: string) => {
        window.open(
            `mailto:${email}?subject=Retirement Plan Insights Inquiry&body=`,
        );
    };
    const BDMExists = businessDevelopmentManagers.length > 0;
    const BDSExists = businessDevelopmentSpecialists.length > 0;

    return (
        <div data-qa="institution-info" className={classes.root}>
            {BDMExists && (
                <div className={classes.advisor}>
                    <Typography variant="h4">{BDMFullName}</Typography>
                    <div>{BDMOther}</div>
                    <div>{BDMTitle}</div>
                    {BDMPhoneNumber && (
                        <div>{phoneFormatRegex(BDMPhoneNumber)}</div>
                    )}
                    <LinkButton
                        onClick={() => {
                            mailToFunc(BDMEmail);
                        }}
                        component="button"
                    >
                        {BDMEmail}
                    </LinkButton>
                </div>
            )}
            {BDSExists && (
                <div className={classes.advisor}>
                    <>
                        <Typography variant="h4">{BDSFullName}</Typography>
                        <div>{BDSOther}</div>
                        <div>{BDSTitle}</div>
                        {BDSPhoneNumber && (
                            <div>{phoneFormatRegex(BDSPhoneNumber)}</div>
                        )}
                        <LinkButton
                            onClick={() => {
                                mailToFunc(BDSEmail);
                            }}
                            component="button"
                        >
                            {BDSEmail}
                        </LinkButton>
                    </>
                </div>
            )}
        </div>
    );
};

export default InstitutionalAdvisorInfo;
