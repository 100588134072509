import { Route, Switch, Redirect, useHistory } from 'react-router-dom';
import { asyncLoadContainer } from 'utils/asyncLoad';
import { Security, LoginCallback, SecureRoute } from '@okta/okta-react';
import { OktaAuth, toRelativeUrl } from '@okta/okta-auth-js';

/* config */
import { configDeterminator } from 'components';
import { oktaConfig } from 'components/Okta';

/* routes */
import { AdvisorMenuRoutes } from 'routes';

export const ADVISOR_CONTACT_US_PATH = '/advisor/contactus';
export const ADVISOR_CREATE_PATH = '/advisor/create';
export const ADVISOR_FAQS_PATH = '/advisor/faqs';
export const ADVISOR_HOME_PATH = '/advisor/home';
export const ADVISOR_LEARN_PATH = '/advisor/learn';
export const ADVISOR_LOGIN_PATH = '/advisor/login';
export const ADVISOR_LOGOUT_PATH = '/advisor/logout';
export const ADVISOR_MANAGE_PATH = '/advisor/manage';
export const ADVISOR_PATH = '/advisor';
export const LEARN_PATH = '/learn';

const Advisor = asyncLoadContainer('Advisor/Home');
const ContactUs = asyncLoadContainer('Advisor/Create/ContactUs');
const Create = asyncLoadContainer('Advisor/Create');
const Faqs = asyncLoadContainer('Advisor/Faqs');
const Learn = asyncLoadContainer('Advisor/Learn');
const LearnResource = asyncLoadContainer('Advisor/LearnResource');
const LogIn = asyncLoadContainer('Advisor/LogIn');
const LogOut = asyncLoadContainer('Advisor/LogOut');
const Manage = asyncLoadContainer('Advisor/Manage');
const NotFound = asyncLoadContainer('404');

const AdvisorRoutes = () => {
    const history = useHistory();
    const oktaAuth = new OktaAuth(oktaConfig);

    const customAuthHandler = () => {
        history.push(ADVISOR_LOGIN_PATH);
    };

    const restoreOriginalUri = async (
        _oktaAuth: OktaAuth,
        originalUri: string,
    ) => {
        history.replace(
            toRelativeUrl(
                originalUri || ADVISOR_HOME_PATH,
                window.location.origin,
            ),
        );
    };

    return (
        <Security
            oktaAuth={oktaAuth}
            onAuthRequired={customAuthHandler}
            restoreOriginalUri={restoreOriginalUri}
        >
            <AdvisorMenuRoutes />
            <Switch>
                <Route exact path={LEARN_PATH} component={LearnResource} />
                <Route exact path={ADVISOR_PATH}>
                    <Redirect to={ADVISOR_HOME_PATH} />
                </Route>
                <Route path={ADVISOR_HOME_PATH} component={Advisor} />
                <Route path={ADVISOR_LEARN_PATH} component={Learn} />
                <Route path={ADVISOR_FAQS_PATH} component={Faqs} />
                <SecureRoute path={ADVISOR_CREATE_PATH} component={Create} />
                <SecureRoute path={ADVISOR_MANAGE_PATH} component={Manage} />
                <Route path={ADVISOR_LOGIN_PATH} component={LogIn} />
                <Route path={ADVISOR_LOGOUT_PATH} component={LogOut} />
                <Route path={ADVISOR_CONTACT_US_PATH} component={ContactUs} />
                <Route
                    path={configDeterminator.redirect_uri}
                    component={LoginCallback}
                />
                <Route component={NotFound} />
            </Switch>
        </Security>
    );
};

export default AdvisorRoutes;
