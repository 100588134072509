import { Industry } from 'store/reducers/planSponsorReducer';
import * as actions from '../actionTypes/industryActionTypes';

export function setTempIndustry(
    tempIndustry: Industry,
): actions.SetTempIndustryAction {
    return {
        type: actions.SET_TEMP_INDUSTRY,
        tempIndustry,
    };
}

export const getIndustries = (
    includeBenchmark: boolean,
): actions.GetIndustriesAction => {
    return {
        type: actions.GET_INDUSTRIES_REQUEST,
        includeBenchmark,
    };
};

export const getIndustriesSuccess = (
    content: Industry[],
): actions.GetIndustriesSuccessAction => {
    return {
        type: actions.GET_INDUSTRIES_SUCCESS,
        content,
    };
};

export const getIndustriesFailure = (
    error: Error,
): actions.GetIndustriesFailureAction => {
    return {
        type: actions.GET_INDUSTRIES_FAILURE,
        error,
    };
};
