import { FC } from 'react';
import { FormattedMessage } from 'react-intl';

/* material */
import { makeStyles } from '@material-ui/core';

const useStyles = makeStyles((theme) => ({
    root: {
        backgroundColor: theme.colors.white,
        fontSize: 14,
        padding: '0 0 14px 24px',

        [theme.breakpoints.up('sm')]: {
            fontSize: 18,
            padding: '0px 125px 0px 24px',
            marginRight: '80px',
            marginLeft: '-180px',
        },

        [theme.breakpoints.up('md')]: {
            fontSize: 22,
        },

        [theme.breakpoints.up('lg')]: {
            fontSize: 22,
            marginLeft: '0px',
        },
    },
}));

const InstitutionalLabel: FC = () => {
    const classes = useStyles();

    return (
        <div data-qa="institution-label" className={classes.root}>
            <FormattedMessage id="header.preparedByInstitutional" />
        </div>
    );
};

export default InstitutionalLabel;
