const prod = {
    envName: 'prod',
    issuer:
        'https://identity-advisor.capitalgroup.com/oauth2/ausnwsui7qatYOJk7357',
    redirect_uri: '/advisor/implicit/callback',
    client_id: '0oanwsviwpRIr0A0s357',
    scopes: ['openid', 'profile', 'email'],
};

export default prod;
