import { FC } from 'react';
import { useQuery } from 'react-query';
import { useQueryTags } from '@capgroup-cxt/aem-react-utils';

/* types */
import { Hero } from 'interfaces/hero';

/* constants */
import { AEMMODELS, contentFragmentUrl, TAG_NAMESPACES } from 'constants/aem';

/* components */
import TwoColumnImageHero from 'components/Heros/TwoColumnImageHero';
import PageWrapper from 'components/PageWrapper';

/* material */
import { makeStyles } from '@material-ui/core';
import { useSelector } from 'react-redux';
import { AppState } from 'store/store/configureStore';

const useStyles = makeStyles((theme) => ({
    root: {
        display: 'flex',
        padding: 0,

        [theme.breakpoints.up('sm')]: {
            padding: 0,
        },
    },
}));

interface HeroResponse {
    articles: Hero[];
}

interface HomepageHeroProps {
    planSponsorIndustry: string;
}

const HomepageHero: FC<HomepageHeroProps> = ({ planSponsorIndustry }) => {
    const classes = useStyles();
    const name = useSelector(
        (state: AppState) => state.planSponsor?.config?.industries?.[0]?.name,
    );
    const naicsCode = useSelector(
        (state: AppState) =>
            state.planSponsor?.config?.industries?.[0]?.naicsCode2,
    );
    const tags = useQueryTags({
        [TAG_NAMESPACES.industries]: [planSponsorIndustry],
    });
    const url = `${contentFragmentUrl}?type=${AEMMODELS.hero}&${tags}`;
    const { isLoading, error, data } = useQuery<HeroResponse>(
        `hero-${tags}`,
        async () => {
            try {
                const res = await fetch(url);
                if (!res.ok) throw Error(`Unable to fetch following ${url}`);
                return res.json();
            } catch (e) {
                console.error(e);
            }
        },
    );
    const industryImageName =
        (name &&
            naicsCode &&
            (name.match(
                /[A-Z]{2,}(?=[A-Z][a-z]+[0-9]*|\b)|[A-Z]?[a-z]+[0-9]*|[A-Z]|[0-9]+/g,
            ) as string[])
                .map((name: string) => name.toLowerCase())
                .join('-') +
                '--' +
                naicsCode +
                '.png') ||
        'generic.png';
    if (isLoading) {
        return <div>Loading...</div>;
    }

    if (!data || error) {
        console.log({ error, data });
        return null;
    }

    const { alt, desc, title } = data.articles[0].properties;
    return (
        <PageWrapper className={classes.root}>
            <TwoColumnImageHero
                alt={alt}
                desc={desc}
                image={
                    '/content/dam/leapfrog/images/heros/industries/' +
                    industryImageName
                }
                title={title}
            />
        </PageWrapper>
    );
};

export default HomepageHero;
