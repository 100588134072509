import { FC } from 'react';

/* material */
import {
    IconButton,
    makeStyles,
    Modal as MuiModal,
    ModalProps as MuiModalProps,
    Typography,
} from '@material-ui/core';
import CloseIcon from '@material-ui/icons/Close';

import { pxToRem } from 'theme/utils';

const useStyles = makeStyles((theme) => ({
    root: {
        backgroundColor: theme.colors.white,
        display: 'flex',
        flexDirection: 'column',
        height: '100%',
        width: '100%',
        [theme.breakpoints.up('sm')]: {
            border: theme.nextStepModal.border,
            borderRadius: '8px',
            boxShadow: theme.nextStepModal.boxShadow,
            left: '50%',
            maxHeight: '80%',
            maxWidth: theme.breakpoints.values.lg,
            overflow: 'hidden',
            position: 'absolute',
            transform: 'translate(-50%, -50%)',
            top: '50%',
        },
    },
    title: {
        backgroundColor: theme.colors.navyTint2,
        padding: `20px 24px`,
        [theme.breakpoints.up('sm')]: {
            padding: `20px 30px`,
        },
    },
    closeBtn: {
        padding: 0,
        position: 'absolute',
        top: 22,
        right: 25,
        zIndex: 1,

        [theme.breakpoints.up('sm')]: {
            top: 18,
            right: 25,
        },
    },
    closeIcon: {
        color: theme.nextStepModal.closeIcon.color,
        fontSize: pxToRem(22),

        [theme.breakpoints.up('sm')]: {
            fontSize: pxToRem(40),
        },
    },
    contentWrapper: {
        height: '100%',
        overflowY: 'scroll',
    },
    contentWrapperNoScroll: {
        height: '100%',
    },
}));

interface PageModalProps extends MuiModalProps {
    onClose: () => void;
    title?: string;
    preventScroll?: boolean;
}

const PageModal: FC<PageModalProps> = ({
    children,
    title,
    onClose,
    preventScroll = false,
    ...props
}) => {
    const classes = useStyles();
    return (
        <MuiModal {...props}>
            <div className={classes.root}>
                <Typography variant="h4" className={classes.title}>
                    {title}
                </Typography>
                <IconButton className={classes.closeBtn} onClick={onClose}>
                    <CloseIcon className={classes.closeIcon} />
                </IconButton>
                <div
                    className={
                        preventScroll
                            ? classes.contentWrapperNoScroll
                            : classes.contentWrapper
                    }
                >
                    {children}
                </div>
            </div>
        </MuiModal>
    );
};

export default PageModal;
