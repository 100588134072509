export const loginCompleted = (
    login = {
        processStepName: '',
        processValueName: '',
        siteLoginStatus: '',
        loginMethod: '',
    },
) => {
    window.appEventData = window.appEventData || [];
    window.appEventData.push({
        event: 'Login Complete',
        process: {
            processStepLabel: login.processStepName,
            processStepValue: login.processValueName,
        },
        userStatus: {
            loginMethod: login.loginMethod,
            siteLoginStatus: login.siteLoginStatus,
        },
    });
};
