import { getSlugUrl } from 'utils/url';
import { cgPlanSponsors } from './cgPlanSponsors';
const planSponsor: string = getSlugUrl();

const getImagePath = (path: string) => {
    if (cgPlanSponsors.indexOf(planSponsor) > -1) {
        const pathNoExt = path.substring(0, path.lastIndexOf('.'));
        const ext = path.substring(path.lastIndexOf('.'), path.length);
        return `${pathNoExt}-cg${ext}`;
    } else {
        return path;
    }
};

export default getImagePath;
