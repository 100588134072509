import { Quiz } from 'interfaces/quiz';
import { Quiz as QuizConfig } from 'containers/Quiz/interfaces';
import * as actions from '../actionTypes/quizActionTypes';

export const getQuizSuccess = (
    quizzes: Quiz[],
): actions.GetQuizSuccessAction => {
    return {
        type: actions.GET_QUIZ_SUCCESS,
        quizzes,
    };
};

export const getQuizRequest = (
    industry: string,
    planSponsor: string,
): actions.GetQuizRequestAction => {
    return {
        type: actions.GET_QUIZ_REQUEST,
        industry,
        planSponsor,
    };
};

export const getQuizFailure = (error: Error): actions.GetQuizFailureAction => {
    return {
        type: actions.GET_QUIZ_FAILURE,
        error,
    };
};

export const startQuiz = (quiz: QuizConfig): actions.StartQuizAction => {
    return {
        type: actions.START_QUIZ,
        quiz,
    };
};

export const answerQuestion = (
    questionNumber: number,
    answer: string,
): actions.AnswerQuestionAction => {
    return {
        type: actions.QUESTION_ANSWERED,
        questionNumber,
        answer,
    };
};
