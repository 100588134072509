import { FC } from 'react';

/* material */
import { makeStyles } from '@material-ui/core';

const useStyles = makeStyles(() => ({
    root: {
        border: 'none',
        marginTop: -1,
    },
}));

interface IframeProps {
    height?: string;
    src: string;
    title: string;
    width?: string;
}

const Iframe: FC<IframeProps> = ({
    height = '100%',
    src,
    title,
    width = '100%',
}) => {
    const classes = useStyles();
    return (
        <iframe
            className={classes.root}
            title={title}
            height={height}
            width={width}
            src={src}
        />
    );
};

export default Iframe;
