import * as actions from '../actionTypes/createWebsiteActionTypes';

export type CreateWebsiteState = {
    customUrl: string;
    displayName: string;
    industry: string;
    industryNaicsCode2: string;
    isCreateAnother: boolean;
    isFlowActive: boolean;
    isFlowComplete: boolean;
    isEmailSent: boolean;
    isWebsiteCreated: boolean;
    officialName: string;
    planSponsorId: number;
    relationshipToPlanSponsor: string;
    step: number;
};

const initialState: CreateWebsiteState = {
    customUrl: '',
    displayName: '',
    industry: '',
    industryNaicsCode2: '',
    isCreateAnother: false,
    isFlowActive: false,
    isFlowComplete: false,
    isEmailSent: false,
    isWebsiteCreated: false,
    officialName: '',
    planSponsorId: -1,
    relationshipToPlanSponsor: '',
    step: 0,
};

export default function createWebsiteReducer(
    state: CreateWebsiteState = initialState,
    action: actions.CreateWebsiteAction,
): CreateWebsiteState {
    switch (action.type) {
        case actions.RESET_CREATE_WEBSITE_INFO:
            return {
                ...initialState,
            };
        case actions.RESET_CREATE_ANOTHER_FLOW: {
            return {
                ...state,
                isCreateAnother: false,
            };
        }
        case actions.RESET_PLAN_SPONSOR_INFO:
            return {
                ...state,
                customUrl: initialState.customUrl,
                displayName: initialState.displayName,
                industry: initialState.industry,
                industryNaicsCode2: initialState.industryNaicsCode2,
                isFlowComplete: initialState.isFlowComplete,
                isEmailSent: initialState.isEmailSent,
                isWebsiteCreated: initialState.isWebsiteCreated,
                officialName: initialState.officialName,
                planSponsorId: initialState.planSponsorId,
                relationshipToPlanSponsor:
                    initialState.relationshipToPlanSponsor,
            };
        case actions.RESET_SELECTED_PLAN_SPONSOR_INFO:
            return {
                ...state,
                customUrl: initialState.customUrl,
                displayName: initialState.displayName,
                officialName: initialState.officialName,
                industry: initialState.industry,
                industryNaicsCode2: initialState.industryNaicsCode2,
                planSponsorId: initialState.planSponsorId,
                relationshipToPlanSponsor:
                    initialState.relationshipToPlanSponsor,
            };
        case actions.SET_ACTIVE_STEP:
            return {
                ...state,
                step: action.step,
            };
        case actions.SET_CREATE_ANOTHER_FLOW:
            return {
                ...state,
                isCreateAnother: true,
            };
        case actions.SET_SELECTED_PLAN_SPONSOR:
            return {
                ...state,
                displayName: action.name,
                officialName: action.officialName,
                industry: action.industry || initialState.industry,
                planSponsorId: action.id,
            };
        case actions.SET_URL_SLUG:
            return {
                ...state,
                customUrl: action.customUrl,
            };
        case actions.SET_PLAN_SPONSOR_INFO: {
            return {
                ...state,
                customUrl: action.customUrl,
                displayName: action.displayName,
                industry: action.industry,
                industryNaicsCode2: action.industryNaicsCode2,
                relationshipToPlanSponsor: action.relationshipToPlanSponsor,
            };
        }
        case actions.SET_WEBSITE_CREATION_ERROR: {
            return {
                ...state,
                isEmailSent: false,
                isWebsiteCreated: false,
            };
        }
        case actions.SET_WEBSITE_CREATION_SUCCESS: {
            return {
                ...state,
                isEmailSent: action.createWebsiteResponse.confirmationEmailSent,
                isWebsiteCreated: true,
            };
        }
        case actions.SET_ACTIVE_FLOW_COMPLETE: {
            return {
                ...state,
                isFlowComplete: true,
            };
        }
        case actions.SET_ACTIVE_FLOW_START: {
            return {
                ...state,
                isFlowActive: true,
            };
        }
        default:
            return state;
    }
}
