import { FC, useState } from 'react';

/* material */
import {
    Button,
    Drawer,
    Divider,
    List,
    makeStyles,
    useTheme,
    useMediaQuery,
} from '@material-ui/core';
import AddAlertOutlinedIcon from '@material-ui/icons/AddAlertOutlined';
import MenuIcon from '@material-ui/icons/Menu';
import CloseIcon from '@material-ui/icons/Close';

/* components */
import { PageWrapper, SubscribeModal } from 'components';
import { MenuLink } from 'components';
import InstitutionalLabel from 'components/Menus/RpiMenu/InstitutionalLabel';
import NavPlaceHolder, { MENU_HEIGHT } from './NavPlaceHolder';
import { FormattedMessage } from 'react-intl';
import { CloseModal } from 'components';
import { useLocation } from 'react-router';
import { useFlags } from 'launchdarkly-react-client-sdk';

const useStyles = makeStyles((theme) => ({
    navBar: {
        height: MENU_HEIGHT,
        position: 'fixed',
        top: 0,
        backgroundColor: theme.colors.white,
        width: '100%',
        zIndex: 999,
    },
    navBarWrapper: {
        alignItems: 'center',
        display: 'flex',
        justifyContent: 'space-between',
        height: '100%',
    },
    navMenu: {
        flexShrink: 0,
        overflow: 'hidden',
    },
    menuIcon: {
        color: theme.topMenu.menuIcon.color,
    },
    navIconWrapper: {
        marginRight: 0,
        minWidth: 'unset',

        [theme.breakpoints.up('md')]: {
            marginRight: 30,
        },
    },
    paper: {
        background: theme.topMenu.backgroundColor,
        width: '100%',

        [theme.breakpoints.up('sm')]: {
            width: '50%',
        },
    },
    navTitle: {
        fontSize: 14,
        fontWeight: 600,
        lineHeight: '120%',
        [theme.breakpoints.up('sm')]: {
            fontSize: '16px',
            paddingRight: '200px',
        },
        [theme.breakpoints.up('md')]: {
            fontSize: '18px',
        },
    },
    logo: {
        color: '#4938A1',
        fontWeight: 600,
        fontSize: '14px',
    },
    linkItem: {
        alignItems: 'flex-start',
        display: 'flex',
        flexDirection: 'column',
        textDecoration: 'none',

        '& > div > span': {
            ...theme.typography.h3,
            color: theme.topMenu.color,
            padding: '18px 16px 18px 16px',
        },
    },
    addAlertOutlinedIcon: {
        fontSize: '28px',
        marginRight: '10px',
        position: 'relative',
        top: theme.addAlertIcon.top,
    },
    closeIcon: {
        color: theme.topMenu.closeIcon.color,
        position: 'absolute',
        right: 16,
        top: 21,
    },
    list: {
        marginLeft: 40,
        padding: 0,
    },
    divider: {
        backgroundColor: theme.topMenu.divider.backgroundColor,
    },
    hamburgerMenuWrapper: {
        position: 'relative',
    },
    subscribeButton: {
        backgroundColor: theme.subscribeButton.mobileColor,
        borderColor: theme.subscribeButton.mobileColor,
        bottom: 30,
        boxSizing: 'border-box',
        color: theme.colors.white,
        fontSize: '18px',
        fontWeight: 500,
        left: '50%',
        letterSpacing: '2.9px',
        padding: theme.subscribeButton.mobilePadding,
        position: 'fixed',
        textAlign: 'center',
        textTransform: 'uppercase',
        transform: 'translateX(-50%)',
        '&:active': {
            backgroundColor: theme.subscribeButton.mobileColor,
            borderColor: theme.subscribeButton.mobileColor,
            boxShadow: theme.subscribeButton.boxShadow,
            color: theme.colors.white,
        },
        '&:hover': {
            backgroundColor: theme.subscribeButton.mobileColor,
            borderColor: theme.subscribeButton.mobileColor,
            color: theme.colors.white,
        },
        '@media (hover: none)': {
            '&.MuiButton-outlined:hover': {
                backgroundColor: theme.subscribeButton.mobileColor,
                borderColor: theme.subscribeButton.mobileColor,
                color: theme.colors.white,
            },
            '&.MuiButton-outlined:active': {
                boxShadow: theme.subscribeButton.boxShadow,
            },
        },

        [theme.breakpoints.up('sm')]: {
            backgroundColor: theme.colors.white,
            borderColor: theme.subscribeButton.color,
            bottom: 'unset',
            color: theme.subscribeButton.color,
            fontWeight: 600,
            left: theme.subscribeButton.left,
            letterSpacing: '0px',
            marginRight: theme.subscribeButton.marginRight,
            padding: theme.subscribeButton.padding,
            position: 'absolute',
            textAlign: 'center',
            textTransform: 'capitalize',
            top: theme.subscribeButton.top,
            '&:active': {
                backgroundColor: theme.colors.white,
                borderColor: theme.subscribeButton.hoverColor,
                boxShadow: theme.subscribeButton.boxShadow,
                color: theme.subscribeButton.hoverColor,
            },
            '&:hover': {
                backgroundColor: theme.colors.white,
                borderColor: theme.subscribeButton.hoverColor,
                color: theme.subscribeButton.hoverColor,
            },
            '@media (hover: none)': {
                '&.MuiButton-outlined:hover': {
                    backgroundColor: theme.colors.white,
                    borderColor: theme.subscribeButton.color,
                    color: theme.subscribeButton.color,
                },
                '&.MuiButton-outlined:active': {
                    boxShadow: theme.subscribeButton.boxShadow,
                },
            },
        },
    },
    unsubscribeTitle: {
        color: theme.colors.purpleAxis,
        fontSize: '16px',
        fontWeight: 600,
        [theme.breakpoints.up('sm')]: {
            fontSize: '18px',
        },
    },
}));

interface NavigationBarProps {
    handleToggleDrawer: () => void;
    homePath: string;
    isAdvisorPortal?: boolean;
    isCGInstitutional?: boolean;
    isMenuVisible?: boolean;
    isNavMenuOpen: boolean;
    title?: JSX.Element | string | null;
}

const NavigationBar: FC<NavigationBarProps> = ({
    children,
    handleToggleDrawer,
    homePath,
    isAdvisorPortal = false,
    isCGInstitutional = false,
    isMenuVisible = true,
    isNavMenuOpen = false,
    title,
}) => {
    const { enablesSubscribeButton = false } = useFlags();
    const classes = useStyles();
    const location = useLocation();
    const theme = useTheme();
    const matches = useMediaQuery(theme.breakpoints.up('sm'));
    const size = isCGInstitutional && !matches ? 'large' : 'regular';
    const [isModalOpen, setIsModalOpen] = useState<boolean>(false);
    const [isSuccessOpen, setIsSuccessOpen] = useState<boolean>(false);
    const handleClick = () => {
        setIsModalOpen(true);
    };
    const handleClose = () => {
        setIsModalOpen(false);
        setIsSuccessOpen(true);
    };
    const handleCloseIcon = () => {
        setIsModalOpen(false);
        setIsSuccessOpen(false);
    };
    const handleSuccessClose = () => {
        setIsSuccessOpen(false);
    };
    const isAdvisorPage = location.pathname.startsWith('/advisor');
    const path = location.pathname.substring(
        location.pathname.lastIndexOf('/') + 1,
    );
    const isPrivacyPage = path === 'privacy';
    const isUnsubscribe = path === 'unsubscribe';

    return (
        <>
            <NavPlaceHolder size={size} />
            <div data-qa="navigation-bar" className={classes.navBar}>
                <PageWrapper className={classes.navBarWrapper}>
                    {!isUnsubscribe && !isAdvisorPage ? (
                        <div>
                            <div className={classes.logo}>
                                <FormattedMessage id="unsubscribe.title" />
                            </div>
                            <div
                                data-qa="navigation-title"
                                className={classes.navTitle}
                            >
                                {title}
                            </div>
                        </div>
                    ) : (
                        <div className={classes.unsubscribeTitle}>
                            <FormattedMessage id="unsubscribe.title" />
                        </div>
                    )}
                    {matches && isCGInstitutional && <InstitutionalLabel />}
                    <div className={classes.hamburgerMenuWrapper}>
                        {enablesSubscribeButton &&
                            isMenuVisible &&
                            !isAdvisorPage &&
                            !isPrivacyPage &&
                            !isUnsubscribe && (
                                <>
                                    <Button
                                        data-analytics-placement="Button:top-right"
                                        data-analytics-label="trackLink:start"
                                        data-analytics-id="Header subscribe button"
                                        className={classes.subscribeButton}
                                        data-qa="subscribe-button"
                                        variant="outlined"
                                        onClick={() => {
                                            handleClick();
                                        }}
                                    >
                                        {matches ? null : (
                                            <AddAlertOutlinedIcon
                                                fontSize={'large'}
                                                className={
                                                    classes.addAlertOutlinedIcon
                                                }
                                            />
                                        )}
                                        <FormattedMessage id="subscribe.title" />
                                    </Button>

                                    <SubscribeModal
                                        open={isModalOpen}
                                        onCloseIcon={handleCloseIcon}
                                        onClose={handleClose}
                                    />

                                    <CloseModal
                                        dataAnalyticsPlacement="Button:top-middle"
                                        dataAnalyticsLabel="trackLink:buttonClick"
                                        dataAnalyticsId="SubscribedSuccessfully_Close"
                                        open={isSuccessOpen}
                                        onClose={handleSuccessClose}
                                    />
                                </>
                            )}
                        {isMenuVisible && !isUnsubscribe && (
                            <Button
                                data-qa="hamburger-menu"
                                onClick={handleToggleDrawer}
                                data-dig-nav="primary navigation"
                                aria-label="open navigation button"
                                aria-expanded={isNavMenuOpen}
                                data-analytics-label="trackLink:anchorClick"
                                data-analytics-id="Main Nav"
                                data-analytics-placement="Navigation:top-right"
                                className={classes.navIconWrapper}
                            >
                                <MenuIcon
                                    fontSize={'large'}
                                    className={classes.menuIcon}
                                />
                            </Button>
                        )}
                    </div>
                    <Drawer
                        anchor="right"
                        open={isNavMenuOpen}
                        onClose={handleToggleDrawer}
                        classes={{ paper: classes.paper }}
                    >
                        <nav
                            data-qa="drawer-menu"
                            aria-label="main navigation"
                            className={classes.navMenu}
                        >
                            <List component="div" className={classes.list}>
                                <MenuLink
                                    analyticId={
                                        isAdvisorPortal
                                            ? 'RPIFAPortal: home'
                                            : 'menu.home'
                                    }
                                    analyticLabel="trackLink:anchorClick"
                                    analyticPlacement="navigation:top-right"
                                    className={classes.linkItem}
                                    handleClick={handleToggleDrawer}
                                    link={{
                                        path: homePath,
                                        id: 'menu.home',
                                    }}
                                />
                                <CloseIcon
                                    fontSize={'large'}
                                    className={classes.closeIcon}
                                    onClick={handleToggleDrawer}
                                    data-analytics-label="trackLink:anchorClick"
                                    data-analytics-id="Main Nav Close"
                                    data-analytics-placement="Navigation:top-right"
                                    role="menuitem"
                                />
                            </List>
                            <Divider
                                role="presentation"
                                className={classes.divider}
                            />
                            {children}
                        </nav>
                    </Drawer>
                </PageWrapper>
                {!matches && isCGInstitutional && <InstitutionalLabel />}
            </div>
        </>
    );
};

export default NavigationBar;
