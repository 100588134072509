import { FC } from 'react';
import { makeStyles } from '@material-ui/core/styles';

export type Politeness = 'off' | 'polite' | 'assertive';

interface AnnounceProps {
    message?: string;
    politeness?: Politeness;
}

const useStyles = makeStyles(() => ({
    visuallyhidden: {
        clip: 'rect(1px, 1px, 1px, 1px)',
        height: 1,
        opacity: 0.1,
        overflow: 'hidden',
        position: 'absolute',
        width: 1,
    },
}));

const Announce: FC<AnnounceProps> = ({
    children,
    message,
    politeness = 'off',
}) => {
    const classes = useStyles();
    return (
        <div aria-live={politeness} className={classes.visuallyhidden}>
            {message ? message : children}
        </div>
    );
};

export default Announce;
