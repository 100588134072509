import { FC } from 'react';

import Box, { BoxProps } from '@material-ui/core/Box';

const PageWrapper: FC<BoxProps> = ({ children, ...others }) => {
    return (
        <Box
            mx="auto"
            width="100%"
            maxWidth={1440}
            px={{ xs: 1.5, md: 4 }}
            {...others}
        >
            {children}
        </Box>
    );
};

export default PageWrapper;
