import { Industry } from 'store/reducers/planSponsorReducer';

export const SET_TEMP_INDUSTRY = 'industryActionTypes/SET_TEMP_INDUSTRY';
export interface SetTempIndustryAction {
    type: typeof SET_TEMP_INDUSTRY;
    tempIndustry: Industry;
}

export const GET_INDUSTRIES_REQUEST =
    'industryActionTypes/GET_INDUSTRIES_REQUEST';
export interface GetIndustriesAction {
    type: typeof GET_INDUSTRIES_REQUEST;
    includeBenchmark: boolean;
}

export const GET_INDUSTRIES_SUCCESS =
    'industryActionTypes/GET_INDUSTRIES_SUCCESS';
export interface GetIndustriesSuccessAction {
    type: typeof GET_INDUSTRIES_SUCCESS;
    content: Industry[];
}

export const GET_INDUSTRIES_FAILURE =
    'industryActionTypes/GET_INDUSTRIES_FAILURE';
export interface GetIndustriesFailureAction {
    type: typeof GET_INDUSTRIES_FAILURE;
    error: Error;
}

export type IndustryAction =
    | SetTempIndustryAction
    | GetIndustriesAction
    | GetIndustriesSuccessAction
    | GetIndustriesFailureAction;
