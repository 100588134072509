const defaults = {
    AEM_BASE_URL: 'https://www.dev.capitalgroup.com',
    APPIAN_DPRM_URL:
        'https://cgafsdev.appiancloud.com/suite/webapi/dprmRequest',
    APPIAN_API_KEY: 'test',
    DATADOG_CLIENT_TOKEN: 'pub93e2ca56fb3522d983aef267c8b2e980',
    GOOGLE_RECAPTCHA_V2_KEY_SITE: '6LeipP4cAAAAAKONF3IA5ffGnd9iwgJpn3zmR5K2',
    LAUNCH_DARKLY_CLIENT_SIDE_ID: '6160f101fbeeb72527cb4675',
    SPRING_PROFILES_ACTIVE: 'dev',
};

export const getGlobalConfig = () => {
    return (window.envConfig && window.envConfig()) || defaults;
};
