import { FC, useState } from 'react';
import Tooltip, { TooltipProps } from '@material-ui/core/Tooltip';
import withStyles from '@material-ui/core/styles/withStyles';
import { Theme } from '@material-ui/core/styles/createTheme';
import { ClickAwayListener, makeStyles, Zoom } from '@material-ui/core';
import InfoOutlinedIcon from '@material-ui/icons/InfoOutlined';
import { pxToRem } from 'theme/utils';
import { useHtmlParser } from '@capgroup-cxt/aem-react-utils/lib/hooks/htmlParser/useHtmlParser';
import renderMAp from 'utils/AemRenderMap';
import { dynamicCustomlinkEvent } from 'utils/dynamicCustomlinkEvent';
import { useIntl } from 'react-intl';

const HtmlTooltip = withStyles((theme: Theme) => ({
    tooltip: {
        backgroundColor: theme.colors.white,
        border: theme.nextStepModal.border,
        boxShadow: theme.nextStepModal.boxShadow,
        color: theme.tooltip.color,
        fontFamily: theme.typography.body2.fontFamily,
        fontSize: theme.typography.body2.fontSize,
        padding: '24px',
        maxWidth: 378,
    },
}))(Tooltip);

interface ExtendedToolTipProps extends TooltipProps {
    variation?: string;
    'data-qa-id'?: string;
    isIcon?: boolean;
}

const useStyles = makeStyles((theme) => ({
    button: {
        cursor: 'pointer',
        display: 'inline-block',
        textDecoration: 'underline',
        border: 'none',
        backgroundColor: 'transparent',
        font: 'unset',
        padding: 0,
        color: 'inherit',
    },
    icon: {
        color: theme.tooltip.iconColor,
        fontSize: pxToRem(27),
        verticalAlign: 'text-bottom',
    },
}));

const CustomizedTooltips: FC<ExtendedToolTipProps> = (props) => {
    const intl = useIntl();
    const [isFireAnalytics, setIsFireAnalytics] = useState<boolean>(true);
    const { children, title, isIcon = true } = props;
    const [open, setOpen] = useState(false);
    const classes = useStyles(props);
    const parsedTitle = useHtmlParser(`<p>${title}</p>`, renderMAp);

    const handleTooltipClose = () => {
        setOpen(false);
    };

    const handleTooltipOpen = () => {
        if (isFireAnalytics) {
            dynamicCustomlinkEvent(
                title as string,
                'trackLink:hover',
                'Tool Tip:middle:middle',
            );
            setIsFireAnalytics(false);
        }

        if (open) {
            setOpen(false);
            return;
        }

        setOpen(true);
    };

    return (
        <ClickAwayListener onClickAway={handleTooltipClose}>
            <HtmlTooltip
                title={parsedTitle as string}
                onClose={handleTooltipClose}
                open={open}
                disableFocusListener
                disableHoverListener
                disableTouchListener
                TransitionComponent={Zoom}
                aria-label={intl.formatMessage({
                    id: 'toolTip.helpText',
                })}
            >
                <button className={classes.button} onClick={handleTooltipOpen}>
                    {children}
                    {isIcon && (
                        <InfoOutlinedIcon
                            className={classes.icon}
                            color="secondary"
                        />
                    )}
                </button>
            </HtmlTooltip>
        </ClickAwayListener>
    );
};

export default CustomizedTooltips;
