import config from './configDeterminator';

const widgetConfig = {
    issuer: config.issuer,
    redirectUri: window.location.origin + config.redirect_uri,
    clientId: config.client_id,
    scopes: config.scopes,
    useInteractionCodeFlow: false,
    features: {
        scrollOnError: false,
    },
};

export default widgetConfig;
