import Cookies from 'js-cookie';
import {
    aemBaseURL,
    aemRPIAnalyticsPath,
    aemNodeExtension,
} from 'constants/aem';

export const isTrackAnalytics = () => {
    let track = true;
    try {
        track = JSON.parse(Cookies.get('_evidon_consent_cookie') as string)
            .categories['1']['analytics provider'];
    } catch (e) {
        // do nothing
    }
    return track;
};

export const getPageNodePathFromContextHub = (): string | undefined => {
    const { Capgroup } = window;
    if (!Capgroup) return undefined;
    const pagestore = Capgroup.ContextHub.stores.page;
    return pagestore.getItem('info/nodePath');
};

export const getPageNodeFromContextHub = () => {
    const nodePath = getPageNodePathFromContextHub();
    if (nodePath) {
        return nodePath
            .replace(`${aemBaseURL}${aemRPIAnalyticsPath}`, '')
            .replace(aemNodeExtension, '');
    }

    return undefined;
};

export const getPageNodeFullPath = (node: string) => {
    return `${aemBaseURL}${aemRPIAnalyticsPath}${node}${aemNodeExtension}`;
};
