import axios from 'axios';
import { v4 as uuidv4 } from 'uuid';

/* interface */
import { Quiz } from 'interfaces/quiz';

/* utils */
import { getHeaders } from 'store/services/utils';

/* constants */
import { contentFragmentUrl, AEMMODELS } from 'constants/aem';
import { PlanSponsorBrandType } from 'store/reducers/planSponsorReducer';

interface QuizResponse {
    articles: Quiz[];
}

export async function fetchQuiz(industry: string, planSponsor: string) {
    const tags = `industry=leapfrog:industries/${industry}&plan_sponsor=leapfrog:plansponsors/${planSponsor}`;

    return await axios.get<QuizResponse>(
        `${contentFragmentUrl}?type=${AEMMODELS.quiz}&${tags}`,
    );
}

export function sendQuizEmail(
    slug: string,
    recaptchaResponse: string,
    displayPlanSponsorNameInPreparedForByStatement: boolean,
    displaySiteOwnerFirmInPreparedForByStatement: boolean,
    displaySiteOwnerNameInPreparedForByStatement: boolean,
    naicsCode2: string,
    countryId: number,
    participationRate: string,
    deferralRate: string,
    autoFeature: string,
    matchingRate: string,
    qdiaSelection: string,
    siteBrand: PlanSponsorBrandType,
) {
    return fetch(
        `/lf/data/api/v1/message/send-retirement-plan-health-quiz-results-email`,
        {
            method: 'POST',
            headers: getHeaders({
                'g-recaptcha-response': recaptchaResponse,
            }),
            body: JSON.stringify({
                id: uuidv4(),
                urlSlug: slug,
                displayPlanSponsorNameInPreparedForByStatement,
                displaySiteOwnerFirmInPreparedForByStatement,
                displaySiteOwnerNameInPreparedForByStatement,
                industryNaicsCode2: naicsCode2,
                countryId,
                participationRate,
                deferralRate,
                autoFeature,
                matchingRate,
                qdiaSelection,
                siteBrand,
            }),
        },
    );
}

export function downloadQuiz(
    slug: string,
    recaptchaResponse: string,
    displayPlanSponsorNameInPreparedForByStatement: boolean,
    displaySiteOwnerFirmInPreparedForByStatement: boolean,
    displaySiteOwnerNameInPreparedForByStatement: boolean,
    naicsCode2: string,
    countryId: number,
    participationRate: string,
    deferralRate: string,
    autoFeature: string,
    matchingRate: string,
    qdiaSelection: string,
    siteBrand: PlanSponsorBrandType,
) {
    return fetch(
        `/lf/data/api/v1/file/download-retirement-plan-health-quiz-results`,
        {
            method: 'POST',
            headers: getHeaders({
                'g-recaptcha-response': recaptchaResponse,
            }),
            body: JSON.stringify({
                id: uuidv4(),
                urlSlug: slug,
                displayPlanSponsorNameInPreparedForByStatement,
                displaySiteOwnerFirmInPreparedForByStatement,
                displaySiteOwnerNameInPreparedForByStatement,
                industryNaicsCode2: naicsCode2,
                countryId,
                participationRate,
                deferralRate,
                autoFeature,
                matchingRate,
                qdiaSelection,
                siteBrand,
            }),
        },
    );
}
