import { domToReact } from 'html-react-parser';
import {
    RenderElementProps,
    RenderElement,
} from '@capgroup-cxt/aem-react-utils/lib/hooks/htmlParser/interfaces';
import ToolTip from 'components/ToolTip';
import parse from 'html-react-parser';

const renderToolTip: RenderElement = (
    props: RenderElementProps,
): JSX.Element | null => {
    const { domNode, options } = props;
    const { attribs, children } = domNode;
    let description: string | JSX.Element | JSX.Element[] = '';

    if (!attribs) return null;
    const { text, variation } = attribs;
    if (!text) return null;
    description = parse(text, options);

    return (
        <ToolTip variation={variation} data-qa="tool-tip" title={description}>
            <>{domToReact(children, options)}</>
        </ToolTip>
    );
};

export default renderToolTip;
