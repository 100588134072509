import MuiSwitch from '@material-ui/core/Switch';
import { withStyles, Theme } from '@material-ui/core/styles';

const Switch = withStyles((theme: Theme) => ({
    root: {
        width: 46,
        height: 20,
        padding: 0,
        margin: 0,
    },
    switchBase: {
        padding: 1,
        '&$checked': {
            transform: 'translateX(26px)',
            color: theme.colors.white,
            '& + $track': {
                backgroundColor: theme.switch.backgroundColorChecked,
                opacity: 1,
                border: 'none',
            },
        },
    },
    thumb: {
        width: 18,
        height: 18,
    },
    track: {
        borderRadius: 20 / 2,
        border: `1px solid ${theme.colors.darkGray}`,
        backgroundColor: theme.switch.backgroundColor,
        opacity: 1,
        transition: theme.transitions.create(['background-color', 'border']),
    },
    checked: {},
}))(MuiSwitch);

export default Switch;
