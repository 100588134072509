import MuiIconButton from '@material-ui/core/IconButton';
import { withStyles } from '@material-ui/core/styles';

const IconButton = withStyles((theme) => ({
    root: {
        background: theme.palette.primary.contrastText,
        color: theme.iconButton.color,
        padding: '8px',
        '&:hover, &:focus': {
            background: theme.palette.secondary.dark,
            color: theme.palette.secondary.contrastText,
        },
        '&.Mui-disabled': {
            backgroundColor: `${theme.palette.primary.contrastText}`,
            color: theme.iconButton.color,
        },
    },
}))(MuiIconButton);

export default IconButton;
