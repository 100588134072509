import { FC } from 'react';
import { useHtmlParser } from '@capgroup-cxt/aem-react-utils/lib/hooks/htmlParser/useHtmlParser';
import { useHistory } from 'react-router-dom';
import useGetPath from '@capgroup-cxt/aem-react-utils/lib/hooks/useGetPath';
import { useSelector } from 'react-redux';

/* material */
import { makeStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import IconButton from 'components/Buttons/IconButton';
import ArrowForwardIcon from '@material-ui/icons/ArrowForward';

/* interfaces */
import { Story } from 'interfaces/story';

// import { pxToRem } from 'theme/utils';
import { AppState } from 'store/store/configureStore';

/* utils */
import renderMap from './renderMap';
import { getTitleFromPath } from 'utils/url';
import getImagePath from 'utils/theme/getImagePath';
import { renderBadge } from 'components/Badge/utils';

/* components */
import Header from 'components/Typography/Header';

const useStyles = makeStyles((theme) => ({
    makeADifferenceElement: {
        justifyContent: 'space-between',
        [theme.breakpoints.up('sm')]: {
            alignItems: 'center',
            display: 'flex',
            flexDirection: 'row-reverse',
        },
    },
    rightContent: {
        maxWidth: '100%',
        marginBottom: theme.spacing(2),
        [theme.breakpoints.up('sm')]: {
            flex: '1 0 auto',
            maxWidth: '537px',
        },
        '& img': {
            width: '100%',
        },
    },
    description: {
        marginBottom: theme.spacing(1),
        [theme.breakpoints.up('sm')]: {
            marginBottom: theme.spacing(1.5),
        },

        '& > div': {
            color: theme.makeADifference.color,
        },
    },
    iconButton: {
        backgroundColor: theme.makeADifference.buttonBackgroundColor,
        color: theme.makeADifference.buttonColor,
        '&:hover': {
            backgroundColor: theme.makeADifference.buttonBackgroundHoverColor,
        },
    },
    header: {
        color: theme.makeADifference.color,
    },
}));

interface MakeADifferenceElementProps {
    story: Story;
    slug: string;
}

const MakeADifferenceElement: FC<MakeADifferenceElementProps> = ({
    story,
    slug,
}) => {
    const classes = useStyles();
    const history = useHistory();
    const {
        title,
        shortDescription,
        imagePath,
        imageAlt,
        badges = [],
        plansponsorExcludedBadges = [],
    } = story.properties;
    const image = useGetPath(imagePath);
    const planSponsorSlug = useSelector(
        (state: AppState) => state.planSponsorSlug.id,
    );
    const urlTitle = getTitleFromPath(story.path);
    const parsedDescription = useHtmlParser(shortDescription, renderMap);

    return (
        <div className={classes.makeADifferenceElement}>
            <div className={classes.rightContent}>
                <img src={getImagePath(image)} alt={imageAlt} />
            </div>
            <div>
                {renderBadge(badges, plansponsorExcludedBadges, slug)}
                <Header title={title} type="card" className={classes.header} />
                <Typography
                    className={classes.description}
                    component="div"
                    variant="subtitle2"
                >
                    {parsedDescription}
                </Typography>
                <div>
                    <IconButton
                        className={classes.iconButton}
                        data-analytics-label="trackLink:buttonClick"
                        data-analytics-id={urlTitle}
                        data-analytics-placement="Home Page:bottom-center"
                        onClick={() =>
                            history.push(
                                `/${planSponsorSlug}/story/${urlTitle}`,
                            )
                        }
                        aria-label="learn more"
                    >
                        <ArrowForwardIcon />
                    </IconButton>
                </div>
            </div>
        </div>
    );
};

export default MakeADifferenceElement;
