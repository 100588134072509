import { put, call, SagaReturnType, takeLatest } from 'redux-saga/effects';

/* services */
import { getIndustries } from 'store/services/industryServices';

/* types */
import * as actionTypes from '../actionTypes/industryActionTypes';
import { Industry } from 'store/reducers/planSponsorReducer';

import {
    getIndustriesFailure,
    getIndustriesSuccess,
} from 'store/actionCreators/industryActionCreator';

function* getListOfIndustries({
    includeBenchmark,
}: actionTypes.GetIndustriesAction) {
    try {
        const res: SagaReturnType<typeof getIndustries> = yield call(
            getIndustries,
            includeBenchmark,
        );

        if (!res.ok)
            throw new Error('Error while fetching retirment calculations');

        const json: { content: Industry[] } = yield call([res, 'json']);
        yield put(getIndustriesSuccess(json.content));
    } catch (error) {
        console.error(error);
        yield put(getIndustriesFailure(error as Error));
    }
}

export default function* watchGetIndustriesData() {
    yield takeLatest(actionTypes.GET_INDUSTRIES_REQUEST, getListOfIndustries);
}
