import axios from 'axios';
import { v4 as uuidv4 } from 'uuid';
import { CG_INSTITUTIONAL, CG_DEMO, PUBLIC } from 'constants/plansponsors';
import {
    Industry,
    National,
    PlanSponsorConfig,
    FinancialAdvisor,
    Site,
} from 'store/reducers/planSponsorReducer';
import { getHeaders } from './utils';

//interfaces might not be required because in planSponsorSaga
// yield call(fetchPlanSponsor, slug);
// looses the types, we will leave it anyways in case gets resolved

type PlanSponsorBySlugResponse = {
    planSponsors: PlanSponsorConfig[];
};

export async function fetchPlanSponsorBySlug(slug: string) {
    return await axios.get<PlanSponsorBySlugResponse>(
        `/lf/data/api/v1/plan-sponsors/url-slug/${slug}`,
    );
}

export type PlanSponsorShortDescription = {
    financialAdvisors?: FinancialAdvisor[];
    id: number;
    industries?: Industry[];
    name: string;
    nationals: National[];
    officialName: string;
    partyId: number;
    partyTaxId: number;
    sites?: Site[];
    type: typeof CG_DEMO | typeof CG_INSTITUTIONAL | typeof PUBLIC;
};

export type PlanSponsorShortDescriptionResponse = {
    content: PlanSponsorShortDescription[];
};
export async function fetchPlanSponsorsShortDescription(planSponsor: string) {
    const res = await fetch(
        `/lf/data/api/v1/plan-sponsors?name=${encodeURIComponent(
            planSponsor,
        )}&page=0&size=10&sort=name,ASC`,
    );
    if (!res.ok)
        throw Error(
            `Unable to fetch plan sponsor short description for ${planSponsor}`,
        );
    return (await res.json()) as PlanSponsorShortDescriptionResponse;
}

export type UnsubscribeResponse = {
    sent: boolean;
};
export async function unsubscribe(email: string, recaptchaResponse: string) {
    const url = `/lf/data/api/v1/message/send-plan-sponsor-auto-updates-unsubscription-request-email`;
    const res = await fetch(url, {
        method: 'POST',
        headers: getHeaders({ 'g-recaptcha-response': recaptchaResponse }),
        body: JSON.stringify({ email, id: uuidv4() }),
    });
    if (!res.ok) throw Error(`Unable to unsubscribe ${email}.`);
    return (await res.json()) as UnsubscribeResponse;
}

export type SubscribeResponse = {
    requestEmailSent: boolean;
    confirmationEmailSent?: boolean;
};
export async function subscribe(
    urlSlug: string,
    firstName: string,
    lastName: string,
    jobTitle: string,
    email: string,
    recaptchaResponse: string,
    companyName?: string,
) {
    const url = `/lf/data/api/v1/message/send-plan-sponsor-auto-updates-subscription-request-emails`;
    const res = await fetch(url, {
        method: 'POST',
        headers: getHeaders({ 'g-recaptcha-response': recaptchaResponse }),
        body: JSON.stringify({
            id: uuidv4(),
            urlSlug,
            firstName,
            lastName,
            jobTitle,
            email,
            ...(companyName && { companyName: companyName }),
        }),
    });
    if (!res.ok) throw Error(`Unable to subscribe ${email}.`);
    return (await res.json()) as SubscribeResponse;
}
