import { FC } from 'react';
import { FormattedMessage } from 'react-intl';
import { makeStyles } from '@material-ui/core/styles';
import useGetPath from '@capgroup-cxt/aem-react-utils/lib/hooks/useGetPath';

/* constant */
import { GENERIC } from 'constants/plansponsors';

/* interfaces */
import { Site, PlanSponsorConfig } from 'store/reducers/planSponsorReducer';

const useStyles = makeStyles((theme) => ({
    sponsorName: {
        color: theme.palette.secondary.dark,
        fontSize: 14,
        fontWeight: 'bold',

        [theme.breakpoints.up('sm')]: {
            fontSize: 16,
        },
        [theme.breakpoints.up('md')]: {
            fontSize: 18,
        },
    },
    logo: {
        verticalAlign: 'middle',
    },
}));

interface PlanSponsorLabelProps {
    planSponsorSlug: string;
    planSponsorConfig: PlanSponsorConfig | undefined;
}

const PlanSponsorLabel: FC<PlanSponsorLabelProps> = ({
    planSponsorSlug,
    planSponsorConfig,
}) => {
    const classes = useStyles();
    const logoPath = useGetPath(
        planSponsorConfig?.sites[0].mainLogoImageAemPath as string,
    );

    if (planSponsorSlug === GENERIC || !planSponsorConfig) return null;

    const {
        name: planSponsorCompany,
        financialAdvisors,
        sites,
    } = planSponsorConfig;
    const {
        displaySiteOwnerFirmNameInPreparedForByStatement,
        displaySiteOwnerNameInPreparedForByStatement,
        mainLogoImageAemPath: planSponsorLogo,
    } = sites[0] as Site;
    const { firmName: financialAdvisorFirm, fullNameWithDesignation } =
        financialAdvisors?.[0] || {};
    const formatter = (chunk: string) => {
        return <span className={classes.sponsorName}>{chunk}</span>;
    };

    if (planSponsorLogo) {
        return (
            <img
                alt={planSponsorCompany}
                title={planSponsorCompany}
                src={logoPath}
                className={classes.logo}
            />
        );
    }

    return (
        <>
            <FormattedMessage
                id={`${
                    displaySiteOwnerNameInPreparedForByStatement ||
                    displaySiteOwnerFirmNameInPreparedForByStatement
                        ? 'header.preparedForBy'
                        : 'header.preparedFor'
                }`}
                values={{
                    span: formatter,
                    planSponsorCompany,
                }}
            />
            {displaySiteOwnerNameInPreparedForByStatement &&
                fullNameWithDesignation}
            {displaySiteOwnerNameInPreparedForByStatement &&
                displaySiteOwnerFirmNameInPreparedForByStatement &&
                ` | `}
            {displaySiteOwnerFirmNameInPreparedForByStatement &&
                financialAdvisorFirm}
        </>
    );
};

export default PlanSponsorLabel;
