import { useEffect, useState } from 'react';

export const useImportScript = (url: string) => {
    const [isLoaded, setIsLoaded] = useState(false);

    useEffect(() => {
        const scriptElem = document.createElement('script');
        scriptElem.src = url;
        scriptElem.async = true;
        document.body.appendChild(scriptElem);
        scriptElem.onload = () => {
            setIsLoaded(true);
        };

        return () => {
            document.body.removeChild(scriptElem);
        };
    }, [url]);

    return isLoaded;
};
