import { FC } from 'react';
import { useQuery } from 'react-query';
import { makeStyles } from '@material-ui/core/styles';
import { useHtmlParser } from '@capgroup-cxt/aem-react-utils/lib/hooks/htmlParser/useHtmlParser';

/* constants */
import { aemBaseURL } from 'constants/aem';

/* render map */
import { renderFooterDisclosureMap } from './renderMap';

type DisclosureResponse = {
    content: string;
};

interface DisclosureProps {
    content: string;
}

const useStyles = makeStyles((theme) => ({
    footer: {
        '&> p': {
            fontSize: theme.footer.fontSize,
        },
    },
}));

const Disclosure: FC<DisclosureProps> = ({ content }) => {
    const classes = useStyles();
    return (
        <div data-qa="footer-disclosure" className={classes.footer}>
            {useHtmlParser(content, renderFooterDisclosureMap)}
        </div>
    );
};

interface FooterDisclosureProps {
    isAdvisorSite: boolean;
}

const FooterDisclosure: FC<FooterDisclosureProps> = ({ isAdvisorSite }) => {
    const disclosureFileName = isAdvisorSite
        ? 'advisor-disclosure'
        : 'disclosure';
    const { error, data } = useQuery<DisclosureResponse>(
        `disclosure`,
        async () => {
            try {
                const res = await fetch(
                    `${aemBaseURL}/content/dam/leapfrog/content/disclosures/${disclosureFileName}/jcr:content/data/master.json`,
                );

                return res.json();
            } catch (e) {
                console.error(e);
                return null;
            }
        },
    );

    if (error) {
        console.error(error);
        return null;
    }

    if (!data) {
        return null;
    }

    return <Disclosure content={data.content} />;
};

export default FooterDisclosure;
