import { FC, useEffect, useRef } from 'react';
import OktaSignIn, { EventCallback } from '@okta/okta-signin-widget';
import '@okta/okta-signin-widget/dist/css/okta-sign-in.min.css';
import './signInWidget.css';
import widgetConfig from './config/widgetConfig';
import { Tokens } from '@okta/okta-auth-js';
import { loginStarted } from 'analytics';

interface SignInWidgetProps {
    onSuccess: (tokens: Tokens) => void;
    onError: (err: any) => void;
    onLoginError: EventCallback;
}

const SignInWidget: FC<SignInWidgetProps> = ({
    onSuccess,
    onError,
    onLoginError,
}) => {
    const widgetRef = useRef<HTMLDivElement>(null);
    useEffect(() => {
        if (!widgetRef.current) {
            return;
        }

        const widget = new OktaSignIn(widgetConfig);
        widget.on('afterError', onLoginError);
        widget
            .showSignInToGetTokens({
                el: '#widget',
            })
            .then(onSuccess)
            .catch(onError);

        const handleClick = (_event: MouseEvent) => {
            loginStarted({
                processStepName: 'RPI Advisor Portal Login',
                processValueName: 'Step 1: RPI Advisor Portal Login - Start ',
                siteLoginStatus: '',
                loginMethod: 'RPI Advisor Portal Login',
            });
        };

        const attachEventListeners = () => {
            const currentWidgetRef = widgetRef.current;
            const inputFields = currentWidgetRef?.querySelectorAll('input');
            inputFields?.forEach((input: any) => {
                input.addEventListener('click', handleClick);
            });
        };

        const observer = new MutationObserver(() => {
            if (widgetRef.current?.querySelector('input')) {
                attachEventListeners();
                observer.disconnect();
            }
        });

        observer.observe(widgetRef.current, { childList: true, subtree: true });

        const cleanup = () => {
            const currentWidgetRef = widgetRef.current;
            const inputFields = currentWidgetRef?.querySelectorAll('input');
            inputFields?.forEach((input: any) => {
                input.removeEventListener('click', handleClick);
            });
            widget.remove();
        };

        return () => {
            observer.disconnect();
            cleanup();
        };
    }, [onSuccess, onError, onLoginError]);

    return <div ref={widgetRef} id="widget" />;
};

export default SignInWidget;
