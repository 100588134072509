import { PlanSponsorConfig } from 'store/reducers/planSponsorReducer';

export const GET_PLAN_SPONSOR = 'planSponsorActionTypes/GET_PLAN_SPONSOR';
export interface GetPlanSponsorAction {
    type: typeof GET_PLAN_SPONSOR;
    slug: string;
}

export const GET_PLAN_SPONSOR_SUCCESS =
    'planSponsorActionTypes/PLAN_SPONSOR_SUCCESS';
export interface GetPlanSponsorSuccessAction {
    type: typeof GET_PLAN_SPONSOR_SUCCESS;
    config: PlanSponsorConfig;
}

export const GET_PLAN_SPONSOR_FAILURE =
    'planSponsorActionTypes/GET_PLAN_SPONSOR_FAILURE';
export interface GetPlanSponsorFailureAction {
    type: typeof GET_PLAN_SPONSOR_FAILURE;
    error: Error | string;
}

export type PlanSponsorAction =
    | GetPlanSponsorSuccessAction
    | GetPlanSponsorFailureAction
    | GetPlanSponsorAction;
