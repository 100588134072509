import { FC } from 'react';
import { Modal, CircularProgress } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { useIntl } from 'react-intl';

const useStyles = makeStyles({
    root: {
        left: '50%',
        position: 'absolute',
        top: '50%',
        transform: 'translate(-50%, -50%)',
        outline: 'none',
    },
});

interface LoaderProps {
    isFullPage?: boolean;
}

const Loader: FC<LoaderProps> = ({ isFullPage = true }) => {
    const classes = useStyles();
    const intl = useIntl();
    const loader = (
        <div
            className={classes.root}
            role="presentation"
            // eslint-disable-next-line jsx-a11y/no-noninteractive-tabindex
            tabIndex={0}
            aria-label={intl.formatMessage({ id: 'loading' })}
        >
            <CircularProgress thickness={2} size={75} />
        </div>
    );
    return isFullPage ? <Modal open>{loader}</Modal> : loader;
};

export default Loader;
