import config from './configDeterminator';

const oktaConfig = {
    issuer: config.issuer,
    clientId: config.client_id,
    redirectUri: window.location.origin + config.redirect_uri,
    scopes: config.scopes,
    pkce: true,
};

export default oktaConfig;
