import { FC, useEffect, useState, Fragment } from 'react';
import { useDispatch } from 'react-redux';
import ScrollToTop from 'components/ScrollToTop';

/* material */
// import { Link as MaterialLink } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';

/* components */
import Loader from '../Loader/Loader';
import { aemBaseURL } from 'constants/aem';
import { pxToRem } from 'theme/utils';
import ParsedAEMContent from './ParsedContent';

type Props = {
    AEMFragmentPath: string;
};

const useStyles = makeStyles((theme) => ({
    root: {
        maxWidth: '846px',
        margin: '0 auto',
        padding: '0 20px',
        '& td': {
            border: `solid 1px ${theme.colors.teal}`,
            padding: '10px',
            verticalAlign: 'top',
        },
        '& th': {
            border: `solid 1px ${theme.colors.teal}`,
            padding: '10px',
            verticalAlign: 'top',
        },
        '& table': {
            border: `solid 1px ${theme.colors.teal}`,
            textAlign: 'left',
        },
    },
    heading: {
        fontFamily: "'Playfair Display', serif",
        fontSize: pxToRem(56),
        lineHeight: '86.4px',
        marginTop: '25px',
        marginBottom: 40,
        color: theme.colors.deepNavy,
        [theme.breakpoints.up('sm')]: {
            fontSize: pxToRem(72),
        },
    },
    content: {
        ...theme.typography.body2,
        color: theme.colors.deepNavy,
    },
}));

const AemContent: FC<Props> = ({ AEMFragmentPath }) => {
    const classes = useStyles();
    const dispatch = useDispatch();
    const [isLoading, setIsLoading] = useState<boolean>(true);
    const [aemTitle, setAemTitle] = useState<string>();
    const [aemContent, setAemContent] = useState<string>('');

    useEffect(() => {
        const fetchAEMContent = async () => {
            try {
                const response = await fetch(
                    `${aemBaseURL}${AEMFragmentPath}/jcr:content/data/master.json`,

                    {
                        method: 'GET',
                    },
                );

                if (!response.ok)
                    throw new Error('Error fetching Privacy Policy');
                const json = await response.json();

                if (json && json.content && json.title) {
                    setAemContent(json.content);
                    setAemTitle(json.title);
                    setIsLoading(false);
                }
            } catch (error) {
                setIsLoading(false);
            }
        };

        fetchAEMContent();
    }, [AEMFragmentPath, dispatch]);

    const renderAemContent = () => {
        if (isLoading) {
            return <Loader />;
        } else {
            return (
                <Fragment>
                    <ScrollToTop />
                    <Typography component="h1" className={classes.heading}>
                        {aemTitle}
                    </Typography>
                    {aemContent && (
                        <div className={classes.content}>
                            <ParsedAEMContent
                                content={aemContent}
                            ></ParsedAEMContent>
                        </div>
                    )}
                </Fragment>
            );
        }
    };

    return (
        <div className={classes.root} data-qa="AEM-Content">
            {renderAemContent()}
        </div>
    );
};

export default AemContent;
