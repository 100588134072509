import { FC } from 'react';

/* material */
import { makeStyles } from '@material-ui/core';

const useStyles = makeStyles((theme) => ({
    root: {
        border: (props: DividerProps) =>
            `solid 1px ${props.color || theme.divider.borderColor}`,
        width: '100%',
        padding: 0,
        margin: (props: DividerProps) =>
            `${props.mt || 0}px 0px ${props.mb || 0}px`,
    },
}));

interface DividerProps {
    color?: string;
    mt?: number;
    mb?: number;
}

const Divider: FC<DividerProps> = (props) => {
    const classes = useStyles(props);
    return <hr className={classes.root} aria-hidden={true} />;
};

export default Divider;
