import { Route, Switch } from 'react-router-dom';
import { asyncLoadContainer } from 'utils/asyncLoad';

export const NOT_FOUND_PATH = '/404';

const HomePage = asyncLoadContainer('Homepage/HomePage');
const Story = asyncLoadContainer('Story');
const Privacy = asyncLoadContainer('Privacy');
const NotFound = asyncLoadContainer('404');
const Quiz = asyncLoadContainer('Quiz');
const QuizSummary = asyncLoadContainer('QuizSummary');
const SiteMap = asyncLoadContainer('SiteMap');
const Unsubscribe = asyncLoadContainer('Unsubscribe');

const PlanSponsorRoutes = () => {
    return (
        <>
            <Switch>
                <Route exact path="/" component={HomePage} />
                <Route exact path="/unsubscribe" component={Unsubscribe} />
                <Route exact path="/:planSponsor/" component={HomePage} />
                <Route exact path="/:planSponsor/404" component={NotFound} />
                <Route
                    exact
                    path="/:planSponsor/story/:title"
                    component={Story}
                />
                <Route
                    exact
                    path="/:planSponsor/quiz/:title"
                    component={Quiz}
                />
                <Route
                    exact
                    path="/:planSponsor/quiz/:title/summary"
                    component={QuizSummary}
                />
                <Route exact path="/:planSponsor/privacy" component={Privacy} />
                <Route
                    exact
                    path="/:planSponsor/privacy/form"
                    component={Privacy}
                />
                <Route exact path="/:planSponsor/sitemap" component={SiteMap} />
                <Route path={NOT_FOUND_PATH} component={NotFound} />
                <Route component={NotFound} />
            </Switch>
        </>
    );
};

export default PlanSponsorRoutes;
