import { FC } from 'react';
import { useHtmlParser } from '@capgroup-cxt/aem-react-utils/lib/hooks/htmlParser/useHtmlParser';
import useGetPath from '@capgroup-cxt/aem-react-utils/lib/hooks/useGetPath';

/* material */
import {
    makeStyles,
    useTheme,
    useMediaQuery,
    Typography,
} from '@material-ui/core';

/* renderer */
import { renderMap } from './renderMap';

/* types */
import getImagePath from 'utils/theme/getImagePath';

/* component */
import Header from 'components/Typography/Header';
import AspectRatio from 'components/AspectRatio';

const useStyles = makeStyles((theme) => ({
    wrapper: {
        display: 'flex',
        flexDirection: 'column',
        width: '100%',

        [theme.breakpoints.up('md')]: {
            flexDirection: 'row-reverse',
            flexGrow: 1,
            height: '475px',
        },
    },
    imgWrapper: {
        flexGrow: 1,
        height: '200px',

        [theme.breakpoints.up('sm')]: {
            height: '100%',
        },

        [theme.breakpoints.up('md')]: {
            flexBasis: '50%',
        },
    },
    backgroundImage: {
        backgroundPosition: 'center',
        backgroundRepeat: 'no-repeat',
        backgroundSize: 'cover',
        height: '200px',
        [theme.breakpoints.up('sm')]: {
            height: '100%',
        },
    },
    contentWrapper: {
        display: 'flex',
        flexGrow: 1,
        padding: '20px 24px 40px',

        [theme.breakpoints.up('md')]: {
            flexBasis: '50%',
            padding: '0 64px',
        },
    },
    content: {
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
    },
    title: {
        fontSize: '36px',
        lineHeight: '120%',
        [theme.breakpoints.up('sm')]: {
            fontSize: '48px',
        },
        [theme.breakpoints.up('lg')]: {
            fontSize: '56px',
        },
    },
}));

interface TwoColumnImageHeroProps {
    image: string;
    desc?: string;
    title: string;
    alt: string;
}

const TwoColumnImageHero: FC<TwoColumnImageHeroProps> = ({
    alt,
    desc,
    image,
    title,
}) => {
    const classes = useStyles();
    const theme = useTheme();
    const matches = useMediaQuery(theme.breakpoints.up('md'));
    const description = useHtmlParser(desc, renderMap);
    const imagePath = useGetPath(image);

    return (
        <div className={classes.wrapper}>
            <div className={classes.imgWrapper}>
                {matches ? (
                    <div
                        role="img"
                        aria-label={alt}
                        className={classes.backgroundImage}
                        style={{
                            backgroundImage: `url('${getImagePath(
                                imagePath,
                            )}')`,
                        }}
                        data-qa="content-card-image"
                    />
                ) : (
                    <AspectRatio ratio={0.65}>
                        <div
                            role="img"
                            aria-label={alt}
                            className={classes.backgroundImage}
                            style={{ backgroundImage: `url('${imagePath}')` }}
                            data-qa="content-card-image"
                        />
                    </AspectRatio>
                )}
            </div>
            <div className={classes.contentWrapper}>
                <div className={classes.content}>
                    <Header
                        title={title}
                        className={classes.title}
                        type="hero"
                    />
                    {desc && (
                        <Typography component="div" variant="subtitle2">
                            {description}
                        </Typography>
                    )}
                </div>
            </div>
        </div>
    );
};

export default TwoColumnImageHero;
