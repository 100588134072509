import { HtmlRenderDictionary } from '@capgroup-cxt/aem-react-utils/lib/hooks/htmlParser/interfaces';
import { RenderElement } from '@capgroup-cxt/aem-react-utils/lib/hooks/htmlParser/interfaces';
import { domToReact } from 'html-react-parser';
import { Link } from 'react-router-dom';
import { isUrlExternal } from 'utils/url';

const renderAnchor: RenderElement = (props) => {
    const {
        domNode: { children, attribs },
        options,
    } = props;
    const href = attribs['href'] || '';
    const isExternal = isUrlExternal(href);
    if (isExternal) {
        return <a href={href}>{domToReact(children, options)}</a>;
    }
    // TEMP, we should get the planSponsor slug and have the full url as '/slug/href,
    // however there is not current way to get this slug from this function, in PO we read it from redux session state, which haven't implement in LF
    // what this will cause is that we can't link to another level, it works for navigation from /privacy to /privacy/form  (href = "privacy/form" in aem)
    // but we should modify it for future uses or other urls
    return <Link to={`${href}`}>{domToReact(children, options)}</Link>;
};

const renderMap: HtmlRenderDictionary = {
    a: renderAnchor,
};

export default renderMap;
