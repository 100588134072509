import { FC } from 'react';
import { useHtmlParser } from '@capgroup-cxt/aem-react-utils/lib/hooks/htmlParser/useHtmlParser';

/* render map */
import { renderWidgetDisclosureMap } from './renderMap';

import { makeStyles } from '@material-ui/core';

const useStyles = makeStyles((theme) => ({
    source: {
        margin: '32px 0 0',

        '& > p': {
            fontSize: theme.footer.fontSize,
            margin: '0 0 16px',
        },

        [theme.breakpoints.up('sm')]: {
            margin: '0',
        },
    },
}));

interface WidgetDisclosureProps {
    disclaimerHtml: string;
}

const WidgetDisclosure: FC<WidgetDisclosureProps> = ({ disclaimerHtml }) => {
    const classes = useStyles();
    return (
        <div data-qa="widget-disclosure" className={classes.source}>
            {useHtmlParser(disclaimerHtml, renderWidgetDisclosureMap)}
        </div>
    );
};

export default WidgetDisclosure;
