import MuiCheckbox from '@material-ui/core/Checkbox';
import { withStyles } from '@material-ui/core/styles';

const Checkbox = withStyles((theme) => ({
    root: {
        color: theme.colors.navyTint1,
    },
    checked: {
        color: theme.colors.purpleDark,
    },
}))(MuiCheckbox);

export default Checkbox;
