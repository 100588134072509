import { FC } from 'react';

/* material */
import { makeStyles } from '@material-ui/core';

export const MENU_HEIGHT = 80;
export const MENU_HEIGHT_LARGE = 100;
export const MENU_HEIGHT_SMALL = 60;
export type Size = 'small' | 'regular' | 'large';

const useStyles = makeStyles(() => ({
    regular: {
        height: MENU_HEIGHT,
    },
    large: {
        height: MENU_HEIGHT_LARGE,
    },
    small: {
        height: MENU_HEIGHT_SMALL,
    },
}));

interface NavPlaceHolderProps {
    size: Size;
}

const NavPlaceHolder: FC<NavPlaceHolderProps> = ({ size }) => {
    const classes = useStyles();
    return <div data-qa="menu-placeholder" className={classes[size]}></div>;
};

export default NavPlaceHolder;
