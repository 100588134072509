import { FC } from 'react';
import { useHtmlParser } from '@capgroup-cxt/aem-react-utils/lib/hooks/htmlParser/useHtmlParser';
import renderMap from './renderMap';

interface ParseAEMContentProps {
    content: string;
}
const ParsedAEMContent: FC<ParseAEMContentProps> = ({ content }) => {
    const parsedContent = useHtmlParser(content, renderMap);
    return <>{parsedContent}</>;
};

export default ParsedAEMContent;
