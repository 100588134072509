interface CookieOptionsProp {
    days: number;
    domain: string;
    sameSite: string;
    secure: boolean;
    path: string;
}

export const createCookie = (
    name: string,
    value: string,
    options?: Partial<CookieOptionsProp>,
) => {
    let o = options || {};
    let e = '';
    if (o.days) {
        const date = new Date();
        date.setTime(date.getTime() + o.days * 24 * 60 * 60 * 1000);
        e = 'expires=' + date.toUTCString();
    }
    const d = o.domain ? `domain=${o.domain}` : '';
    const s = o.secure ? 'Secure' : '';
    const ss = `SameSite=${o.sameSite ? o.sameSite : 'Lax'}`;
    const p = `path=${o.path ? o.path : '/'}`;
    document.cookie = `${name}=${value};${[d, s, ss, e, p].join(';')}`;
};

export const readCookie = (name: string) => {
    var nameEQ = name + '=';
    var ca = document.cookie.split(';');
    for (var i = 0; i < ca.length; i++) {
        var c = ca[i];
        while (c.charAt(0) === ' ') c = c.substring(1, c.length);
        if (c.indexOf(nameEQ) === 0)
            return c.substring(nameEQ.length, c.length);
    }

    return null;
};

export const deleteCookie = (name: string) => {
    createCookie(name, '', { days: -1 });
};
