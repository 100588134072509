import { CreateWebsiteResponse } from 'store/services/advisorServices';
import * as actions from '../actionTypes/createWebsiteActionTypes';

export function setUrlSlug(customUrl: string): actions.SetUrlSlugAction {
    return {
        type: actions.SET_URL_SLUG,
        customUrl,
    };
}

export function resetCreateAnotherFlow(): actions.ResetCreateAnotherFlowAction {
    return {
        type: actions.RESET_CREATE_ANOTHER_FLOW,
    };
}

export function resetCreateWebsiteInfo(): actions.ResetCreateWebsiteInfoAction {
    return {
        type: actions.RESET_CREATE_WEBSITE_INFO,
    };
}

export function resetPlanSponsorInfo(): actions.ResetPlanSponsorInfoAction {
    return {
        type: actions.RESET_PLAN_SPONSOR_INFO,
    };
}

export function resetSelectedPlanSponsorInfo(): actions.ResetSelectedPlanSponsorInfoAction {
    return {
        type: actions.RESET_SELECTED_PLAN_SPONSOR_INFO,
    };
}

export function setActiveFlowComplete(): actions.SetActiveFlowCompleteAction {
    return {
        type: actions.SET_ACTIVE_FLOW_COMPLETE,
    };
}

export function setActiveFlowStart(): actions.SetActiveFlowStartAction {
    return {
        type: actions.SET_ACTIVE_FLOW_START,
    };
}

export function setActiveStep(step: number): actions.SetActiveStepAction {
    return {
        type: actions.SET_ACTIVE_STEP,
        step,
    };
}

export function setCreateAnotherFlow(): actions.SetCreateAnotherFlowAction {
    return {
        type: actions.SET_CREATE_ANOTHER_FLOW,
    };
}

export function setPlanSponsorInfo(
    customUrl: string,
    displayName: string,
    industry: string,
    industryNaicsCode2: string,
    relationshipToPlanSponsor: string,
): actions.SetPlanSponsorInfoAction {
    return {
        type: actions.SET_PLAN_SPONSOR_INFO,
        customUrl,
        displayName,
        industry,
        industryNaicsCode2,
        relationshipToPlanSponsor,
    };
}

export function setSelectedPlanSponsor(
    id: number,
    industry: string | undefined,
    name: string,
    officialName: string,
): actions.SetSelectedPlanSponsorAction {
    return {
        type: actions.SET_SELECTED_PLAN_SPONSOR,
        id,
        industry,
        name,
        officialName,
    };
}

export function setWebsiteCreationError(): actions.SetWebsiteCreationErrorAction {
    return {
        type: actions.SET_WEBSITE_CREATION_ERROR,
    };
}

export function setWebsiteCreationSuccess(
    createWebsiteResponse: CreateWebsiteResponse,
): actions.SetWebsiteCreationSuccessAction {
    return {
        type: actions.SET_WEBSITE_CREATION_SUCCESS,
        createWebsiteResponse,
    };
}
