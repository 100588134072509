import * as actions from '../actionTypes/industryActionTypes';
import {
    formatBenchmarkNumbers,
    IndustryBenchmark,
    Industry,
} from './planSponsorReducer';

export type IndustryState = {
    tempIndustry: Industry;
    industries: Industry[] | undefined;
};

const initialState: IndustryState = {
    tempIndustry: {} as Industry,
    industries: undefined,
};

export default function industryReducer(
    state: IndustryState = initialState,
    action: actions.IndustryAction,
): IndustryState {
    switch (action.type) {
        case actions.SET_TEMP_INDUSTRY:
            return {
                ...state,
                tempIndustry: {
                    ...action.tempIndustry,
                    benchmark: formatBenchmarkNumbers(
                        action.tempIndustry.benchmark,
                    ) as IndustryBenchmark,
                },
            };
        case actions.GET_INDUSTRIES_SUCCESS:
            return {
                ...state,
                industries: action.content,
            };
        default:
            return state;
    }
}
