export const CG_DEMO = 'CG_DEMO';
export const CG_INSTITUTIONAL = 'CG_INSTITUTIONAL';
export const GENERIC = 'generic';
export const PLAN_SPONSOR_COMPANY = 'plan-sponsor-company';
export const PUBLIC = 'PUBLIC';
export const SITE_BRAND_CG = 'CG';
export const SITE_BRAND_RPI = 'RPI';
export const SITE_FOOTER_CUSTOM = 'CUSTOM';
export const SITE_FOOTER_DETAILED = 'DETAILED';
export const SITE_FOOTER_DEFAULT = 'DEFAULT';
