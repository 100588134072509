import { HtmlRenderDictionary } from '@capgroup-cxt/aem-react-utils/lib/hooks/htmlParser/interfaces';
import { RenderElement } from '@capgroup-cxt/aem-react-utils/lib/hooks/htmlParser/interfaces';
import Typography from '@material-ui/core/Typography';
import { domToReact } from 'html-react-parser';
import renderToolTip from 'utils/AemRenderMap/renderToolTip';

const renderParagraph: RenderElement = (props) => {
    const {
        domNode: { children },
        options,
    } = props;
    return (
        <Typography component="div" variant="subtitle2">
            {domToReact(children, options)}
        </Typography>
    );
};

const renderMAp: HtmlRenderDictionary = {
    p: renderParagraph,
    tooltip: renderToolTip,
};

export default renderMAp;
