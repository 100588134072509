const dev = {
    envName: 'dev',
    issuer:
        'https://identity-advisor.dev.capitalgroup.com/oauth2/aus1chsl7bjVgqKT60h8',
    redirect_uri: '/advisor/implicit/callback',
    client_id: '0oa1cgp1g5a6o2nJI0h8',
    scopes: ['openid', 'profile', 'email'],
};

export default dev;
