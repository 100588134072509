import * as actions from '../actionTypes/planSponsorSlugActionTypes';

export interface PlanSponsorSlugState {
    id: string;
}

const initialState: PlanSponsorSlugState = {
    id: '',
};

export default function planSponsorSlugReducer(
    state: PlanSponsorSlugState = initialState,
    action: actions.PlanSponsorSlugAction,
): PlanSponsorSlugState {
    switch (action.type) {
        case actions.SET_PLAN_SPONSOR_SLUG:
            return {
                id: action.id,
            };
        default:
            return state;
    }
}
