import * as actions from '../actionTypes/planSponsorSlugActionTypes';

export function setPlanSponsorSlug(
    id: string,
): actions.SetPlanSponsorSlugAction {
    return {
        type: actions.SET_PLAN_SPONSOR_SLUG,
        id,
    };
}
