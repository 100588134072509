import { FC } from 'react';
import { makeStyles } from '@material-ui/core/styles';

interface ReadOnlyProps {
    copy?: string;
}

const useStyles = makeStyles(() => ({
    root: {
        clip: 'rect(1px, 1px, 1px, 1px)',
        height: 1,
        opacity: 0.1,
        overflow: 'hidden',
        position: 'absolute',
        width: 1,
    },
}));

const ReadOnly: FC<ReadOnlyProps> = ({ copy, children }) => {
    const classes = useStyles();
    return (
        <div className={classes.root} aria-hidden={false}>
            {copy ? copy : children}
        </div>
    );
};

export default ReadOnly;
