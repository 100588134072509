import { FC } from 'react';

/* material */
import { ButtonProps, Link, LinkProps, Theme } from '@material-ui/core';
import { withStyles, WithStyles } from '@material-ui/core/styles';

const styles = (theme: Theme) => ({
    root: {
        color: theme.linkButton.color,
        fontFamily: theme.linkButton.fontFamily,
        fontSize: 18,
        lineHeight: '27px',
        marginBottom: 8,
        textAlign: 'left' as 'left',

        [theme.breakpoints.up('sm')]: {
            fontSize: 22,
            lineHeight: '33px',
            marginBottom: 18,
        },
    },
});

type LinkButtonProps = ButtonProps<'button', { component: 'button' }> &
    LinkProps &
    WithStyles<typeof styles> & {
        dataLabelId?: string;
        dataEddlDisabled?: string;
        dataClassType?: string;
    };

const LinkButton: FC<LinkButtonProps> = (props: LinkButtonProps) => {
    const {
        classes,
        onClick = () => {},
        dataLabelId,
        dataEddlDisabled,
        dataClassType,
        children,
        ...others
    } = props;
    return (
        <Link
            {...others}
            data-qa="link-button"
            onClick={onClick}
            className={classes.root}
            data-label-id={dataLabelId ? dataLabelId : ''}
            data-class-type={dataClassType ? dataClassType : ''}
            data-eddl-disabled={dataEddlDisabled ? dataEddlDisabled : ''}
        >
            {children}
        </Link>
    );
};

export default withStyles(styles, { withTheme: true })(LinkButton);
