import { FC } from 'react';
import { domToReact } from 'html-react-parser';
import {
    RenderElementProps,
    RenderElement,
    HtmlRenderDictionary,
} from '@capgroup-cxt/aem-react-utils/lib/hooks/htmlParser/interfaces';

/* material */
import { makeStyles, Typography } from '@material-ui/core';

const RenderWidgetDisclosureParagraph: RenderElement = (
    props: RenderElementProps,
): JSX.Element | null => {
    const { domNode, options } = props;
    const { children } = domNode;

    return <p>{children && domToReact(children, options)}</p>;
};

export const renderWidgetDisclosureMap: HtmlRenderDictionary = {
    p: RenderWidgetDisclosureParagraph,
};

const useStyles = makeStyles(() => ({
    p: {
        margin: '0 0 16px',

        '& > b': {
            fontWeight: 700,
        },
    },
}));

interface HtmlRenderProps {
    children: JSX.Element;
}

const FooterDisclosureP: FC<HtmlRenderProps> = ({ children }) => {
    const classes = useStyles();
    return (
        <Typography className={classes.p} variant="caption" component="p">
            {children}
        </Typography>
    );
};

const renderFooterDisclosureP: RenderElement = (props) => {
    const {
        domNode: { children },
        options,
    } = props;

    return (
        <FooterDisclosureP>
            {domToReact(children, options) as JSX.Element}
        </FooterDisclosureP>
    );
};

export const renderFooterDisclosureMap: HtmlRenderDictionary = {
    p: renderFooterDisclosureP,
};
