import React, { FC, useRef, useEffect } from 'react';
import clsx from 'clsx';

/* material */
import { makeStyles, Typography } from '@material-ui/core';

interface StyleProps {
    type: 'hero' | 'card' | 'section';
}
const useStyles = makeStyles((theme) => ({
    header: (props: StyleProps) => {
        const { type } = props;
        const isHero = type === 'hero';
        const isCard = type === 'card';
        const isSection = type === 'section';
        return {
            fontFeatureSettings: `'pnum' on, 'lnum' on`,
            marginBottom: isHero
                ? theme.spacing(1.5)
                : isCard
                ? theme.spacing(1)
                : theme.spacing(3.75),
            outline: 'none',
            textAlign: isSection ? 'center' : 'left',
            [theme.breakpoints.up('sm')]: {
                marginBottom: isHero
                    ? theme.spacing(3)
                    : isCard
                    ? theme.spacing(1.5)
                    : theme.spacing(3.125),
            },
        };
    },
}));

interface HeaderProps {
    title?: string;
    type: 'hero' | 'card' | 'section';
    className?: string;
    style?: React.CSSProperties;
}

const renderHeader = (
    type: string,
    classes: string,
    children: React.ReactNode,
    ref: React.RefObject<HTMLHeadingElement>,
    style?: React.CSSProperties,
    title?: string,
) => {
    switch (type) {
        case 'card':
            return (
                <Typography
                    style={style}
                    component="h2"
                    variant="h2"
                    className={classes}
                >
                    {title || children}
                </Typography>
            );
        case 'section':
            return (
                <Typography
                    style={style}
                    className={classes}
                    variant="h3"
                    component="h4"
                >
                    {title || children}
                </Typography>
            );
        default:
            return (
                <Typography
                    style={style}
                    component="h1"
                    variant="h1"
                    tabIndex={-1}
                    className={classes}
                    ref={ref}
                >
                    {title || children}
                </Typography>
            );
    }
};

const Header: FC<HeaderProps> = ({
    title = '',
    type,
    className,
    children,
    style = {},
}) => {
    const classes = useStyles({ type });
    const headerRef = useRef<HTMLHeadingElement>(null);

    useEffect(() => {
        if (type === 'hero') {
            headerRef.current && headerRef.current.focus();
        }
    }, [type, title]);

    return renderHeader(
        type,
        clsx(classes.header, className),
        children,
        headerRef,
        style,
        title,
    );
};

export default Header;
