import { all } from 'redux-saga/effects';
import watchGetLoadSponsorData from './planSponsorSaga';
import watchGetQuizData from './quizSaga';
import watchGetIndustriesData from './industrySaga';

export default function* rootSaga() {
    yield all([
        watchGetQuizData(),
        watchGetLoadSponsorData(),
        watchGetIndustriesData(),
    ]);
}
