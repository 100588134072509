import { CreateWebsiteResponse } from 'store/services/advisorServices';

export const RESET_CREATE_WEBSITE_INFO =
    'createWebsiteActionTypes/RESET_CREATE_WEBSITE_INFO';
export interface ResetCreateWebsiteInfoAction {
    type: typeof RESET_CREATE_WEBSITE_INFO;
}

export const RESET_PLAN_SPONSOR_INFO =
    'createWebsiteActionTypes/RESET_PLAN_SPONSOR_INFO';
export interface ResetPlanSponsorInfoAction {
    type: typeof RESET_PLAN_SPONSOR_INFO;
}

export const RESET_SELECTED_PLAN_SPONSOR_INFO =
    'createWebsiteActionTypes/RESET_SELECTED_PLAN_SPONSOR_INFO';
export interface ResetSelectedPlanSponsorInfoAction {
    type: typeof RESET_SELECTED_PLAN_SPONSOR_INFO;
}

export const SET_ACTIVE_FLOW_COMPLETE =
    'createWebsiteActionTypes/SET_ACTIVE_FLOW_COMPLETE';
export interface SetActiveFlowCompleteAction {
    type: typeof SET_ACTIVE_FLOW_COMPLETE;
}

export const SET_ACTIVE_FLOW_START =
    'createWebsiteActionTypes/SET_ACTIVE_FLOW_START';
export interface SetActiveFlowStartAction {
    type: typeof SET_ACTIVE_FLOW_START;
}

export const SET_ACTIVE_STEP = 'createWebsiteActionTypes/SET_ACTIVE_STEP';
export interface SetActiveStepAction {
    type: typeof SET_ACTIVE_STEP;
    step: number;
}

export const SET_CREATE_ANOTHER_FLOW =
    'createWebsiteActionTypes/SET_CREATE_ANOTHER_FLOW';
export interface SetCreateAnotherFlowAction {
    type: typeof SET_CREATE_ANOTHER_FLOW;
}

export const RESET_CREATE_ANOTHER_FLOW =
    'createWebsiteActionTypes/RESET_CREATE_ANOTHER_FLOW';
export interface ResetCreateAnotherFlowAction {
    type: typeof RESET_CREATE_ANOTHER_FLOW;
}

export const SET_PLAN_SPONSOR_INFO =
    'createWebsiteActionTypes/SET_PLAN_SPONSOR_INFO';
export interface SetPlanSponsorInfoAction {
    type: typeof SET_PLAN_SPONSOR_INFO;
    displayName: string;
    customUrl: string;
    industry: string;
    industryNaicsCode2: string;
    relationshipToPlanSponsor: string;
}

export const SET_SELECTED_PLAN_SPONSOR =
    'createWebsiteActionTypes/SET_SELECTED_PLAN_SPONSOR';
export interface SetSelectedPlanSponsorAction {
    type: typeof SET_SELECTED_PLAN_SPONSOR;
    id: number;
    industry: string | undefined;
    name: string;
    officialName: string;
}

export const SET_URL_SLUG = 'createWebsiteActionTypes/SET_URL_SLUG';
export interface SetUrlSlugAction {
    type: typeof SET_URL_SLUG;
    customUrl: string;
}

export const SET_WEBSITE_CREATION_ERROR =
    'createWebsiteActionTypes/SET_WEBSITE_CREATION_ERROR';
export interface SetWebsiteCreationErrorAction {
    type: typeof SET_WEBSITE_CREATION_ERROR;
}

export const SET_WEBSITE_CREATION_SUCCESS =
    'createWebsiteActionTypes/SET_WEBSITE_CREATION_SUCCESS';
export interface SetWebsiteCreationSuccessAction {
    type: typeof SET_WEBSITE_CREATION_SUCCESS;
    createWebsiteResponse: CreateWebsiteResponse;
}

export type CreateWebsiteAction =
    | ResetCreateWebsiteInfoAction
    | ResetCreateAnotherFlowAction
    | ResetPlanSponsorInfoAction
    | ResetSelectedPlanSponsorInfoAction
    | SetActiveFlowCompleteAction
    | SetActiveFlowStartAction
    | SetActiveStepAction
    | SetCreateAnotherFlowAction
    | SetPlanSponsorInfoAction
    | SetSelectedPlanSponsorAction
    | SetUrlSlugAction
    | SetWebsiteCreationErrorAction
    | SetWebsiteCreationSuccessAction;
