import { Story } from 'interfaces/story';

export const GET_STORIES_SUCCESS = 'storiesActionTypes/STORIES_SUCCESS';
export interface GetStoriesSuccessAction {
    type: typeof GET_STORIES_SUCCESS;
    stories: Story[];
}

export const GET_STORIES_DATA = 'storiesActionTypes/GET_STORIES_DATA';
export type GetStoriesDataAction = {
    type: typeof GET_STORIES_DATA;
};

export type StoriesAction = GetStoriesSuccessAction | GetStoriesDataAction;
