import axios from 'axios';
import { Story } from 'interfaces/story';
import {
    contentFragmentUrl,
    TAG_NAMESPACES,
    aemDomain,
    AEMMODELS,
} from 'constants/aem';
import { getAemQueryTags } from '@capgroup-cxt/aem-react-utils';

interface StoriesResponse {
    articles: Story[];
}

export async function fetchStories(industry: string) {
    const tags = getAemQueryTags(aemDomain, {
        [TAG_NAMESPACES.industries]: [industry],
    });
    return await axios.get<StoriesResponse>(
        `${contentFragmentUrl}?type=${AEMMODELS.story}&${tags}`,
    );
}
