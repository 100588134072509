import { FC, useEffect } from 'react';

/* material */
// import {} from '@material-ui/core/Slider';
import { makeStyles, SliderProps as MuiSliderProps } from '@material-ui/core';

import MuiSlider from '@material-ui/core/Slider';

/* utils */
import { pxToRem } from 'theme/utils';

interface styleProps {
    solidTrackColor: boolean | undefined;
}

const useStyles = makeStyles((theme) => ({
    root: {
        height: 32,
        padding: '45px 0 0',

        [theme.breakpoints.up('lg')]: {
            height: 40,
            padding: '64px 0 0',
        },

        '&.MuiSlider-marked': {
            margin: 0,
        },

        '& .MuiSlider-rail, & .MuiSlider-track ': {
            background: (props: styleProps) => {
                return props.solidTrackColor
                    ? theme.tenureCalculator.slider.rail.backgroundColorSolid
                    : theme.tenureCalculator.slider.rail.backgroundColor;
            },
            borderRadius: 24,
            height: 32,
            left: -12,
            padding: '0 12px',
            position: 'absolute',

            [theme.breakpoints.up('lg')]: {
                borderRadius: 38,
                height: 40,
                left: -19,
                padding: '0 19px',
            },
        },

        '& .MuiSlider-rail': {
            opacity: theme.tenureCalculator.slider.rail.opacity,
        },

        '& .MuiSlider-track': {
            background: 'none',
            left: '-12px !important',
            overflow: 'hidden',
            padding: '0 12px',

            [theme.breakpoints.up('lg')]: {
                left: '-19px !important',
                padding: '0 19px',
            },
        },

        '& .MuiSlider-mark': {
            display: 'none',
        },

        '& .MuiSlider-markLabel': {
            color: theme.tenureCalculator.markerLabel.color,
            fontSize: pxToRem(14),
            fontWeight: 600,
            top: 18,

            [theme.breakpoints.up('sm')]: {
                top: 17,
            },
        },

        '& .MuiSlider-thumb': {
            backgroundColor: theme.tenureCalculator.thumb.backgroundColor,
            boxSizing: 'content-box',
            border: `solid 10px ${theme.colors.white}`,
            height: 12,
            marginLeft: -17,
            marginTop: 0,
            width: 12,
            zIndex: 2,

            '&:focus': {
                boxShadow: 'none',
            },

            '&:hover, &:active': {
                boxShadow: theme.tenureCalculator.thumb.boxShadow,
            },

            '& .MuiSlider-valueLabel': {
                transform: 'scale(1) translateY(-16px)',

                [theme.breakpoints.up('lg')]: {
                    transform: 'scale(1) translateY(-41px)',
                },
            },

            [theme.breakpoints.up('lg')]: {
                borderWidth: 12.5,
                height: 15,
                marginLeft: -20,
                width: 15,
            },
        },

        '& .MuiSlider-valueLabel': {
            fontFamily: '"Inter", sans-serif',
            fontSize: pxToRem(22),
            left: 'calc(-50% - 2px)',

            [theme.breakpoints.up('lg')]: {
                left: 'calc(-50% - 8px)',
            },

            '& > span': {
                backgroundColor: theme.colors.white,
                borderRadius: 8,
                height: 35,
                transform: 'none',
                width: 28,

                [theme.breakpoints.up('lg')]: {
                    height: 50,
                    transform: 'none',
                    width: 47,
                },

                '& span': {
                    color: theme.tenureCalculator.valueLabel.color,
                    fontWeight: 600,
                    transform: 'none',
                },
            },
            '&::after': {
                borderTop: `4px solid ${theme.colors.white}`,
                content: '""',
                display: 'block',
                position: 'absolute',
                borderLeft: '4px solid transparent',
                borderRight: '4px solid transparent',
                height: 0,
                left: 'calc(50% - 4px)',
                top: 34,
                width: 0,

                [theme.breakpoints.up('lg')]: {
                    borderTop: `6px solid ${theme.colors.white}`,
                    borderLeft: '6px solid transparent',
                    borderRight: '6px solid transparent',
                    left: 'calc(50% - 6px)',
                    top: 50,
                },
            },
        },
    },
    innerTrack: {
        background: (props: styleProps) => {
            return props.solidTrackColor
                ? theme.tenureCalculator.slider.innerTrack.backgroundColorSolid
                : theme.tenureCalculator.slider.innerTrack.backgroundColor;
        },
        borderRadius: 24,
        height: 32,
        left: 0,
        padding: '0',
        position: 'absolute',

        [theme.breakpoints.up('lg')]: {
            borderRadius: 38,
            height: 40,
        },
    },
}));

export interface SliderProps extends Omit<MuiSliderProps, 'onChange'> {
    onChange: (event: React.ChangeEvent<{}>, value: number | number[]) => void;
    solidTrackColor?: boolean;
}

const Slider: FC<SliderProps> = ({ solidTrackColor, onChange, ...props }) => {
    const classes = useStyles({ solidTrackColor });

    useEffect(() => {
        const rail = document.getElementsByClassName('MuiSlider-rail')[0];
        if (!rail) return;

        let ticking = false;

        const renderBackgroundRail = () => {
            const track = document.getElementsByClassName('MuiSlider-track')[0];
            const innerTrackElem = document.getElementById('innerTrack');
            const { width } = rail.getBoundingClientRect();

            if (innerTrackElem) {
                innerTrackElem.style.width = width + 'px';
            } else {
                const innerTrack = document.createElement('div');
                innerTrack.className = classes.innerTrack;
                innerTrack.setAttribute('id', 'innerTrack');
                innerTrack.style.width = width + 'px';
                track.appendChild(innerTrack);
            }
            ticking = false;
        };

        const onResize = () => {
            if (!ticking) requestAnimationFrame(renderBackgroundRail);
            ticking = true;
        };

        window.addEventListener('resize', onResize);

        renderBackgroundRail();

        return () => {
            window.removeEventListener('resize', onResize);
        };
    }, [classes]);

    // if (!props.marks) return null;

    return (
        <MuiSlider {...props} className={classes.root} onChange={onChange} />
    );
};

export default Slider;
