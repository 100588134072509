import { FC, useState } from 'react';
import { useQuery } from 'react-query';

/* components */
import { Select } from 'components';

/* types */
import { Industry } from 'store/reducers/planSponsorReducer';

/* services */
import { getIndustries } from 'store/services/industryServices';

type IndustryResponse = {
    content: Industry[];
};

type Option = {
    value: number;
    text: string;
};

export const INDUSTRY = 'industry';

interface IndustrySelectProps {
    error?: boolean;
    helperText?: string;
    onChange?: (industry: Industry) => void;
}

const IndustrySelect: FC<IndustrySelectProps> = ({ onChange, ...other }) => {
    const [selectedIndustry, setSelectedIndustry] = useState<number>(0);

    const { isLoading, error, data } = useQuery<IndustryResponse>(
        `advisor-get-industries`,
        async () => {
            try {
                const res = await getIndustries(true);
                if (!res.ok) throw Error(`Unable to fetch industries`);
                return res.json();
            } catch (e) {
                console.error(e);
            }
        },
    );

    if (error) {
        console.log(error);
        return null;
    }

    if (isLoading || !data) return null;

    const getOptions = () => {
        let options = [{ value: 0, text: 'Select an industry' }] as Option[];
        data.content.forEach((industry: Industry) => {
            if (industry.id && industry.name) {
                options.push({
                    value: industry.id,
                    text: industry.name,
                });
            }
        });
        return options;
    };

    const getIndustry = (industryId: number) => {
        const foundIndustry = data.content.filter((obj) => {
            return obj.id === industryId;
        });

        return foundIndustry[0] || ({} as Industry);
    };

    const handleChange = (
        event: React.ChangeEvent<{
            name?: string | undefined;
            value: unknown;
        }>,
    ) => {
        const value = event.target.value as number;
        setSelectedIndustry(value);
        onChange && onChange(getIndustry(value));
    };

    return (
        <Select
            {...other}
            data-qa="industry-select"
            label="Industry"
            name={INDUSTRY}
            id={INDUSTRY}
            items={getOptions()}
            value={selectedIndustry}
            onChange={handleChange}
        />
    );
};

export default IndustrySelect;
