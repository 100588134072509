import { Story } from 'interfaces/story';
import * as actions from '../actionTypes/storiesActionTypes';

export function getStoriesSuccess(
    stories: Story[],
): actions.GetStoriesSuccessAction {
    return {
        type: actions.GET_STORIES_SUCCESS,
        stories,
    };
}

export const getStoriesData = (): actions.GetStoriesDataAction => {
    return {
        type: actions.GET_STORIES_DATA,
    };
};
