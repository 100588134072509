import MuiAutoComplete from '@material-ui/lab/Autocomplete';
import { withStyles } from '@material-ui/core/styles';

const AutoComplete = withStyles(() => ({
    inputRoot: {
        '&.MuiAutocomplete-inputRoot .MuiAutocomplete-input': {
            padding: '14px 16px',
        },
    },
    option: {
        padding: '0px 16px',
    },
    paper: {
        boxShadow: '0px 0px 8px 4px rgba(143, 121, 255, 0.4)',
    },
}))(MuiAutoComplete);

export default AutoComplete;
