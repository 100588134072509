import { domToReact } from 'html-react-parser';

import { RenderElement } from '@capgroup-cxt/aem-react-utils/lib/hooks/htmlParser/interfaces';
import Typography from '@material-ui/core/Typography';

export const renderParagraph: RenderElement = (props) => {
    const {
        domNode: { children },
        options,
    } = props;
    return (
        <Typography component="div" variant="body2">
            {domToReact(children, options)}
        </Typography>
    );
};

export const renderBody1Paragraph: RenderElement = (props) => {
    const {
        domNode: { children },
        options,
    } = props;
    return (
        <Typography component="div" variant="body1">
            {domToReact(children, options)}
        </Typography>
    );
};

export const renderH2Paragraph: RenderElement = (props) => {
    const {
        domNode: { children },
        options,
    } = props;
    return (
        <Typography component="div" variant="subtitle2">
            {domToReact(children, options)}
        </Typography>
    );
};

export const renderHeroParagraph: RenderElement = (props) => {
    const {
        domNode: { children },
        options,
    } = props;
    return (
        <Typography component="div" variant="subtitle1">
            {domToReact(children, options)}
        </Typography>
    );
};
