import React, { FC } from 'react';
import { FormattedMessage } from 'react-intl';
import { NavLink } from 'react-router-dom';

/* material */
import { ListItem, ListItemText, makeStyles } from '@material-ui/core';

/* constants */
import { ADVISOR_CREATE_ADVISOR_INFO_PATH } from 'routes/AdvisorCreateRoutes';
import { ADVISOR_LOGIN_PATH, ADVISOR_MANAGE_PATH } from 'routes/AdvisorRoutes';

const useStyles = makeStyles((theme) => ({
    text: {
        '& > span': {
            ...theme.typography.h3,
            color: theme.topMenu.color,
            padding: '18px 40px 18px 16px',
        },
    },
}));

export type Link = {
    path: string;
    id?: string;
    title?: string;
};

interface MenuLinkProps {
    activeClassName?: string;
    activePath?: string;
    analyticId?: string;
    analyticLabel?: string;
    analyticPlacement?: string;
    className?: string;
    handleClick?: (link: Link) => void;
    link: Link;
}

const MenuLink: FC<MenuLinkProps> = ({
    activeClassName,
    analyticId,
    analyticLabel,
    analyticPlacement,
    className,
    handleClick,
    link,
    activePath,
}) => {
    const { path, id, title } = link;
    const classes = useStyles();
    const CustomNavLink = React.useMemo(
        () =>
            React.forwardRef<HTMLAnchorElement>((linkProps, ref) => (
                <NavLink
                    ref={ref}
                    to={path}
                    {...linkProps}
                    role="link"
                    onClick={() => {
                        handleClick && handleClick(link);
                    }}
                    isActive={(match, location) => {
                        if (match?.isExact) {
                            return true;
                        }
                        if (
                            location.pathname === ADVISOR_LOGIN_PATH &&
                            (activePath === ADVISOR_CREATE_ADVISOR_INFO_PATH ||
                                activePath === ADVISOR_MANAGE_PATH)
                        ) {
                            return path === activePath;
                        }
                        return false;
                    }}
                    data-analytics-label={analyticLabel}
                    data-analytics-id={analyticId}
                    data-analytics-placement={analyticPlacement}
                    className={className}
                    activeClassName={activeClassName}
                />
            )),
        [
            path,
            className,
            activeClassName,
            activePath,
            analyticId,
            analyticLabel,
            analyticPlacement,
            handleClick,
            link,
        ],
    );

    return (
        <ListItem
            data-qa="drawer-menu-item"
            disableGutters
            button
            component={CustomNavLink}
        >
            <ListItemText
                className={classes.text}
                primary={id ? <FormattedMessage id={id} /> : title}
            />
        </ListItem>
    );
};

export default MenuLink;
