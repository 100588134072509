import { createStore, applyMiddleware } from 'redux';
import createSagaMiddleware from 'redux-saga';
import { compose } from 'redux';
//import rootReducer from '../reducers/rootReducer';
import { routerMiddleware } from 'connected-react-router';
import rootSaga from '../sagas/rootSaga';
import logger from 'redux-logger';
import { createBrowserHistory } from 'history';
/* reducers */
import createRootReducer from '../reducers/rootReducer';
/* middleware */
// import trackPageView from 'middleware/trackPageView';
import createWebsite from 'middleware/createWebsite';
/* dev tools */
export const history = createBrowserHistory();
const rootReducer = createRootReducer(history);

const composeEnhancer: typeof compose =
    window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;

export type AppState = ReturnType<typeof rootReducer>;

const configureStore = () => {
    const sagaMiddleware = createSagaMiddleware();
    const store = createStore(
        rootReducer,
        composeEnhancer(
            applyMiddleware(
                routerMiddleware(history),
                sagaMiddleware,
                logger,
                // trackPageView,
                createWebsite,
            ),
        ),
    );
    sagaMiddleware.run(rootSaga);
    return store;
};

export default configureStore;
