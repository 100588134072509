import { FC } from 'react';
import { useLocation } from 'react-router-dom';

/* components */
import {
    AdvisorInfo,
    CustomPlanSponsorContactInfo,
    Footer,
    InstitutionalAdvisorInfo,
    WidgetDisclosure,
} from '.';

/* types */
import { PlanSponsorConfig, Site } from 'store/reducers/planSponsorReducer';
import { Story } from 'interfaces/story';

/* constants */
import { CG_INSTITUTIONAL, SITE_FOOTER_CUSTOM } from 'constants/plansponsors';

/* utils */
import { getTitleFromPath } from 'utils/url';

const getStoryId = (path: string) => {
    return path.split('/')[3];
};

const getWidgetDisclaimer = (id: string, stories: Story[]) => {
    const story = stories.find((story) => getTitleFromPath(story.path) === id);
    return story?.properties.widgetDisclaimer;
};

interface RPIFooterProps {
    planSponsorConfig?: PlanSponsorConfig;
    stories: Story[];
    slug: String;
}

const RPIFooter: FC<RPIFooterProps> = ({
    planSponsorConfig,
    slug,
    stories,
}) => {
    const location = useLocation();

    const id = getStoryId(location.pathname);
    const disclaimerHtml = getWidgetDisclaimer(id, stories);

    const getContactInfo = () => {
        if (!planSponsorConfig) return <div />;

        const {
            businessDevelopmentManagers,
            financialAdvisors,
            sites,
            type: planSponsorType,
        } = planSponsorConfig;
        const {
            customFooter,
            displaySiteOwnerFirmNameInPreparedForByStatement,
            displaySiteOwnerNameInPreparedForByStatement,
            footerType,
        } = sites[0] as Site;

        if (footerType === SITE_FOOTER_CUSTOM) {
            return <CustomPlanSponsorContactInfo footer={customFooter} />;
        }

        return planSponsorType === CG_INSTITUTIONAL ? (
            <InstitutionalAdvisorInfo
                businessDevelopmentManagers={businessDevelopmentManagers}
            />
        ) : (
            <AdvisorInfo
                financialAdvisors={financialAdvisors}
                footerType={footerType}
                displaySiteOwnerNameInPreparedForByStatement={
                    displaySiteOwnerNameInPreparedForByStatement
                }
                displaySiteOwnerFirmInPreparedForByStatement={
                    displaySiteOwnerFirmNameInPreparedForByStatement
                }
            />
        );
    };

    const getStoryDisclaimer = () => {
        return disclaimerHtml ? (
            <WidgetDisclosure disclaimerHtml={disclaimerHtml} />
        ) : null;
    };

    return (
        <Footer
            slug={slug}
            contactInfoElement={getContactInfo()}
            storyDisclaimerElement={getStoryDisclaimer()}
            planSponsorCompany={planSponsorConfig?.name}
        />
    );
};

export default RPIFooter;
