import { Quiz } from 'interfaces/quiz';
import { aemDomain, TAG_NAMESPACES } from 'constants/aem';

export const isBenchmarkQuizExcluded = (quizzes: Quiz[], slug: string) => {
    return quizzes.find((quiz: Quiz) => {
        const excludedPlanSponsors = quiz.properties?.excludedPlanSponsors;
        return (
            excludedPlanSponsors?.length &&
            excludedPlanSponsors.indexOf(
                `${aemDomain}:${TAG_NAMESPACES.planSponsors}/${slug}`,
            ) > -1
        );
    });
};
