import { HtmlRenderDictionary } from '@capgroup-cxt/aem-react-utils/lib/hooks/htmlParser/interfaces';
import { renderParagraph } from './renderParagraph';
import renderToolTip from './renderToolTip';
import renderBold from './renderBold';

const renderMAp: HtmlRenderDictionary = {
    p: renderParagraph,
    tooltip: renderToolTip,
    bold: renderBold,
};

export default renderMAp;
