import MuiAlert from '@material-ui/lab/Alert';
import { withStyles } from '@material-ui/core/styles';

const Alert = withStyles((theme) => ({
    root: {
        borderRadius: 8,
        color: theme.colors.navy,
        lineHeight: '120%',
        padding: 20,
        wordBreak: 'break-word',
    },
    outlinedInfo: {
        backgroundColor: 'rgba(23,108,217,0.03)',
        border: `solid 1px ${theme.colors.blue}`,
    },
    outlinedError: {
        backgroundColor: 'rgba(214,60,60,0.03)',
        border: `solid 1px ${theme.colors.red}`,
    },
    outlinedSuccess: {
        backgroundColor: 'rgba(24,140,12,0.03)',
        border: `solid 1px ${theme.colors.green}`,
    },
    message: {
        margin: 0,
        padding: 0,
        wordBreak: 'break-word',
    },
    icon: {
        padding: 0,

        '& > svg': {
            fontSize: 30,
        },
    },
}))(MuiAlert);

export default Alert;
