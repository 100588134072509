import useMediaQuery from '@material-ui/core/useMediaQuery';
import { Route, Switch } from 'react-router-dom';

/* material */
import { useTheme } from '@material-ui/core/styles';

/* utils */
import { asyncLoadComponent } from 'utils/asyncLoad';

/* constants */
import { LEARN_PATH } from './AdvisorRoutes';

const DesktopMenu = asyncLoadComponent('Menus/AdvisorMenu/DesktopMenu');
const MobileMenu = asyncLoadComponent('Menus/AdvisorMenu/MobileMenu');
const ResourceMenu = asyncLoadComponent('Menus/AdvisorMenu/ResourceMenu');

const AdvisorMenuRoutes = () => {
    const theme = useTheme();
    const matches = useMediaQuery(theme.breakpoints.up('sm'));
    const Menu = matches ? DesktopMenu : MobileMenu;
    return (
        <Switch>
            <Route exact path={LEARN_PATH} component={ResourceMenu} />
            <Route component={Menu} />
        </Switch>
    );
};

export default AdvisorMenuRoutes;
