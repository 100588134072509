export const formAbandonmentEvent = () => {
    window.appEventData = window.appEventData || [];
    const analyticsId = localStorage.getItem('analyticsId');
    const placement = localStorage.getItem('placement');
    const fieldInputs = analyticsId?.split(':') || [];
    const userInputFields = fieldInputs[0];
    const lastTouchField = fieldInputs[1];
    if (analyticsId != null && placement != null) {
        var processLabel = placement.split('-')[0].trim();
        var processValue = placement.split('-')[1].trim();
        window.appEventData.push({
            abandonment: {
                lasttouchfield: lastTouchField,
                userinputfields: userInputFields,
            },
            event: 'Abandonment',
            process: {
                processStepLabel: processLabel,
                processStepValue: processValue,
            },
        });
        localStorage.removeItem('analyticsId');
    }
};

const formIdList: string[] = [];
let userInputFields: string[] = [];
let lastTouchField: string = '';
let formName: string = '';
const formId: string = '';

export const formOnFocus = (event: FocusEvent) => {
    const elementName = event.currentTarget as HTMLInputElement;
    const fieldName = elementName.name;

    if (fieldName && !userInputFields.includes(fieldName)) {
        userInputFields.push(fieldName);
    }
    lastTouchField = fieldName || '';
    localStorage.setItem('placement', `${formName}`);
    const formId = (event.target as HTMLElement).closest('form')?.id;
    if (formId && !formIdList.includes(formId)) {
        formIdList.push(formId);
    }
};

export const onUnload = () => {
    const joinedUserFields = userInputFields.join('|');
    console.log(joinedUserFields);
    if (joinedUserFields) {
        localStorage.setItem(
            'analyticsId',
            joinedUserFields + ' : ' + lastTouchField,
        );
        formAbandonmentEvent();
        userInputFields = [];
        setTimeout(() => {
            const formIdIndex = formIdList.indexOf(formId);
            if (formIdIndex > -1) {
                formIdList.splice(formIdIndex, 1);
            }
        }, 500);
    }
};

export const formAnalytics = (elementList: HTMLElement[], name: string) => {
    localStorage.setItem('placement', name);
    formName = name;

    elementList.forEach((element) => {
        element.addEventListener('focus', formOnFocus);
    });
    window.addEventListener('beforeunload', onUnload);
};
export const formOnSubmit = () => {
    window.removeEventListener('beforeunload', onUnload);
    localStorage.removeItem('analyticsId');
    localStorage.removeItem('placement');
    userInputFields = [];
    if (formIdList.indexOf(formId) > -1)
        formIdList.splice(formIdList.indexOf(formId), 1);
};
export const formOnAbandon = () => {
    onUnload();
};
