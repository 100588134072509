import 'react-app-polyfill/ie11';
import 'react-app-polyfill/stable';
import 'theme/extendTheme';
import ReactDOM from 'react-dom';
import { Provider } from 'react-redux';
import flat from 'flat';
import { IntlProvider } from 'react-intl';
import reportWebVitals from './reportWebVitals';
import { v4 as uuidv4 } from 'uuid';
import { asyncWithLDProvider } from 'launchdarkly-react-client-sdk';
import queryString from 'query-string';

/* components */
import App from './containers/App/App';

/* utils */
import { getSlugUrl } from './utils/url';
import { getNavigatorLocale } from 'utils/locales';
import configureStore, { history } from './store/store/configureStore';
import { getGlobalConfig } from 'utils/config';

/* translations */
import localesEn from './locales/en.json';

const qs = queryString.parse(window.location.search);
const store = configureStore();
const locale = getNavigatorLocale();
const messages: Record<string, string> = flat(localesEn);
const clientSideID = getGlobalConfig().LAUNCH_DARKLY_CLIENT_SIDE_ID;
const launchDarklyUsers = ['qa'];
const uid = qs.uid || '';

const planSponsorSlug: string = getSlugUrl();

(async () => {
    let uuid: string | null = localStorage.getItem('LDUUID');
    if (!uuid) {
        const created: string = uuidv4();
        localStorage.setItem('LDUUID', created);
        uuid = created;
    }

    const shouldTrackUser = launchDarklyUsers.includes(uid as string);
    const LDProvider = await asyncWithLDProvider({
        clientSideID,
        user: {
            key: uuid,
            custom: {
                planSponsorSlug,
            },
            anonymous: !shouldTrackUser,
        },
    });

    ReactDOM.render(
        <LDProvider>
            <Provider store={store}>
                <IntlProvider
                    locale={locale}
                    defaultLocale="en"
                    messages={messages}
                >
                    <App history={history} />
                </IntlProvider>
            </Provider>
        </LDProvider>,
        document.getElementById('root'),
    );
})();
reportWebVitals();
