import { put, call, takeLatest, SagaReturnType } from 'redux-saga/effects';

/* services */
import { fetchPlanSponsorBySlug } from '../services/planSponsorServices';
import { fetchStories } from '../services/storiesServices';

/* actions */
import {
    getPlanSponsorSuccess,
    getPlanSponsorFailure,
} from '../actionCreators/planSponsorActionCreator';
import { getStoriesSuccess } from '../actionCreators/storiesActionCreator';
import { setPlanSponsorSlug } from '../actionCreators/planSponsorSlugActionCreator';

/* types */
import * as actionTypes from '../actionTypes/planSponsorActionTypes';

/* constants */
import { TAG_PLANSPONSOR_ALL } from 'constants/aem';

function* getPlanSponsor({ slug }: actionTypes.GetPlanSponsorAction) {
    try {
        if (!slug) throw new Error('invalid slug');
        yield put(setPlanSponsorSlug(slug));

        const {
            data: planSponsorData,
        }: SagaReturnType<typeof fetchPlanSponsorBySlug> = yield call(
            fetchPlanSponsorBySlug,
            slug,
        );
        const planSponsor = planSponsorData.planSponsors[0];

        if (!planSponsor) throw new Error('Plan sponsor not found');

        yield put(getPlanSponsorSuccess(planSponsor));

        const industry = planSponsor.industries?.[0];
        const {
            data: storiesData,
        }: SagaReturnType<typeof fetchStories> = yield call(
            fetchStories,
            industry?.name || TAG_PLANSPONSOR_ALL,
        );

        const getOrderedStories = () => {
            storiesData.articles.forEach((article) => {
                const orderOverride = article.properties.orderOverride;
                if (orderOverride) {
                    const planSponsorTag = orderOverride?.find(
                        (tag) => tag.indexOf(slug) > -1,
                    );
                    if (planSponsorTag) {
                        const [, , orderNumber] = planSponsorTag.split('/');
                        article.properties.order = parseInt(orderNumber);
                    }
                }
            });
            return [...storiesData.articles].sort(
                (a, b) => a.properties.order - b.properties.order,
            );
        };

        const stories = getOrderedStories();
        yield put(getStoriesSuccess(stories));
    } catch (error) {
        yield put(getPlanSponsorFailure(error as Error));
    }
}

export default function* watchGetLoadSponsorData() {
    yield takeLatest(actionTypes.GET_PLAN_SPONSOR, getPlanSponsor);
}
