import { FC, useState } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';

/* material */
import { makeStyles, Button, Grid } from '@material-ui/core';
import AddIcon from '@material-ui/icons/Add';

/* components */

import { Header } from 'components/Typography';
import MakeADifferenceElement from './MakeADifferenceElement';
import PageWrapper from 'components/PageWrapper';

/* interface */
import { Story } from 'interfaces/story';

const useStyles = makeStyles((theme) => ({
    makeAnImpact: {
        backgroundColor: theme.palette.primary.main,
        paddingTop: theme.spacing(3),
        [theme.breakpoints.up('sm')]: {
            paddingTop: theme.spacing(3.6),
        },
    },
    title: {
        color: theme.makeAnImpact.color,
        marginBottom: 0,
        textAlign: 'center',
    },
    nav: {
        listStyleType: 'none',
        margin: 0,
        paddingLeft: 0,
        '& li': {
            borderBottom: `1px solid ${theme.makeAnImpact.borderColor}`,
            paddingBottom: theme.spacing(5),
            paddingTop: theme.spacing(5),
        },
        '& li:last-child': {
            border: 'none',
        },
    },
    buttonContainer: {
        paddingBottom: theme.spacing(4),
        paddingTop: theme.spacing(4),
        textAlign: 'center',
    },
    button: {
        backgroundColor: 'transparent',
        border: `2px solid ${theme.makeAnImpact.loadMoreButton.color}`,
        color: theme.makeAnImpact.loadMoreButton.color,
        display: 'flex',
        alignItems: 'center',
        marginLeft: 'auto',
        marginRight: 'auto',

        '&:hover': {
            borderColor: theme.makeAnImpact.loadMoreButton.hoverColor,
            color: theme.makeAnImpact.loadMoreButton.hoverColor,
        },
        '&:active': {
            borderColor: theme.makeAnImpact.loadMoreButton.hoverColor,
            color: theme.makeAnImpact.loadMoreButton.hoverColor,
        },
        '@media (hover: none)': {
            '&.MuiButton-outlined:active': {
                color: theme.makeAnImpact.loadMoreButton.hoverColor,
                borderColor: theme.makeAnImpact.loadMoreButton.hoverColor,
            },
        },
    },
    icon: {
        marginLeft: 8,
    },
}));

interface MakeAnImpactProps {
    stories: Story[];
    slug: string;
}

export const STORY_COUNT_INCREMENT = 3;

const MakeAnImpact: FC<MakeAnImpactProps> = ({ stories, slug }) => {
    const intl = useIntl();
    const classes = useStyles();
    const [storyCount, setStoryCount] = useState(3);
    const displayMoreCta = storyCount < stories.length;
    const visibleStories = stories.slice(0, storyCount);

    const handleDisplayMoreClick = () => {
        setStoryCount(storyCount + STORY_COUNT_INCREMENT);
    };

    return (
        <div className={classes.makeAnImpact}>
            <PageWrapper>
                <Grid container spacing={1} justifyContent="center">
                    <Grid item xs={12} md={10}>
                        <Header className={classes.title} type="section">
                            <FormattedMessage id="homePage.makeADifference.title" />
                        </Header>
                        <nav>
                            <ul className={classes.nav}>
                                {visibleStories.map((story, index) => {
                                    //double check, backend sends [{}] instead of [] when no article is returned
                                    if (!story.id) return null;
                                    return (
                                        <li key={index}>
                                            <MakeADifferenceElement
                                                story={story}
                                                slug={slug}
                                            />
                                        </li>
                                    );
                                })}
                                {displayMoreCta && (
                                    <div className={classes.buttonContainer}>
                                        <Button
                                            data-analytics-label="trackLink:buttonClick"
                                            data-analytics-id={intl.formatMessage(
                                                {
                                                    id: 'homePage.loadMore',
                                                },
                                            )}
                                            data-analytics-placement="homepage:bottom-center"
                                            className={classes.button}
                                            variant="outlined"
                                            onClick={handleDisplayMoreClick}
                                        >
                                            <FormattedMessage id="homePage.loadMore" />
                                            <AddIcon className={classes.icon} />
                                        </Button>
                                    </div>
                                )}
                            </ul>
                        </nav>
                    </Grid>
                </Grid>
            </PageWrapper>
        </div>
    );
};

export default MakeAnImpact;
