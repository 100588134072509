import { FC } from 'react';
import { useQueryTags, useGetPath } from '@capgroup-cxt/aem-react-utils';
import { useQuery } from 'react-query';
import parse from 'html-react-parser';

/* material */
import {
    makeStyles,
    Typography,
    useTheme,
    useMediaQuery,
} from '@material-ui/core';

/* components */
import { PageWrapper } from 'components';

/* types */
import { Hero } from 'interfaces/hero';

/* constants */
import { AEMMODELS, contentFragmentUrl, TAG_NAMESPACES } from 'constants/aem';

const useStyles = makeStyles((theme) => ({
    hero: {
        width: '100%',

        [theme.breakpoints.up('sm')]: {
            backgroundPositionX: '80%',
            backgroundPositionY: 'center',
            backgroundRepeat: 'no-repeat',
            backgroundSize: 'cover',
            display: 'flex',
            minHeight: 600,
        },
    },
    copyWrapper: {
        padding: '30px 0 40px',

        [theme.breakpoints.up('sm')]: {
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'center',
            height: '100%',
            padding: '30px 0',
            width: '50%',
        },

        '& > h1': {
            color: theme.colors.black,
            lineHeight: '126%',

            [theme.breakpoints.up('sm')]: {
                color: theme.colors.white,
                fontSize: 35,
            },

            [theme.breakpoints.up('md')]: {
                fontSize: 50,
                marginBottom: 8,
            },
        },
        '& > div': {
            color: theme.colors.black,

            [theme.breakpoints.up('sm')]: {
                color: theme.colors.white,
                fontSize: 20,
            },

            [theme.breakpoints.up('md')]: {
                fontSize: 30,
            },
        },
    },
    img: {
        width: '100%',
    },
}));

interface TraditionalInstitutionalHeroProps {
    slug: string;
}

interface HeroResponse {
    articles: Hero[];
}

const TraditionalInstitutionalHero: FC<TraditionalInstitutionalHeroProps> = ({
    slug,
}) => {
    const classes = useStyles();
    const tags = useQueryTags({
        [TAG_NAMESPACES.planSponsors]: [slug],
    });
    const theme = useTheme();
    const matches = useMediaQuery(theme.breakpoints.up('sm'));
    const url = `${contentFragmentUrl}?type=${AEMMODELS.hero}&${tags}`;
    const { isLoading, error, data } = useQuery<HeroResponse>(
        `ti-${tags}`,
        async () => {
            try {
                const res = await fetch(url);
                if (!res.ok) throw Error(`Unable to fetch following ${url}`);
                return res.json();
            } catch (e) {
                console.error(e);
            }
        },
    );
    const desktopImagePath = useGetPath(
        data?.articles[0] ? data?.articles[0].properties?.desktopImage : '',
    );
    const mobileImagePath = useGetPath(
        data?.articles[0] ? data?.articles[0].properties?.mobileImage : '',
    );

    if (isLoading) return <div>Loading...</div>;

    if (!data || !data.articles[0].properties || error) {
        console.log({ error, data });
        return null;
    }

    const { title, desc, alt } = data.articles[0].properties;
    const desktopImage = matches ? `url('${desktopImagePath}')` : '';
    const mobileImage = matches ? null : (
        <img className={classes.img} src={mobileImagePath} alt={alt} />
    );

    return (
        <>
            {mobileImage}
            <div
                role="img"
                aria-label={alt}
                className={classes.hero}
                style={{
                    backgroundImage: desktopImage,
                }}
            >
                <PageWrapper px={{ xs: 1.5, md: 4 }}>
                    <div className={classes.copyWrapper}>
                        <Typography variant="h1">{title}</Typography>
                        <Typography variant="subtitle1" component="div">
                            {parse(desc)}
                        </Typography>
                    </div>
                </PageWrapper>
            </div>
        </>
    );
};

export default TraditionalInstitutionalHero;
