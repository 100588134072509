import { FC } from 'react';
import { compose } from 'redux';
import { ConnectedRouter } from 'connected-react-router';
import { datadogLogs } from '@datadog/browser-logs';
import { datadogRum } from '@datadog/browser-rum';
import { History } from 'history';
import { QueryClient, QueryClientProvider } from 'react-query';
import { ReactUtilsProvider } from '@capgroup-cxt/aem-react-utils';

/* components */
import { Loader, ScrollToTop } from 'components';

/* utils */
import { getGlobalConfig } from 'utils/config';
import { getSlugUrl } from 'utils/url';
import { asyncLoadContainer } from 'utils/asyncLoad';

/* constants */
import { ADVISOR, LEARN } from 'constants/site';
import { aemBaseURL, aemDomain } from 'constants/aem';

const queryClient = new QueryClient();
declare global {
    // TODO if possible change any for valid types/interfaces for analytics global variables
    interface Window {
        Capgroup: any;
        Analytics: {
            trackScene7: any;
            singlePageApp: any;
        };
        s: any;
        ContextHub: any;
        useDummyData?: boolean;
        appEventData: any[];
        formEngagement: any;
        _v?: string;

        envConfig?: () => {
            AEM_BASE_URL: string;
            LAUNCH_DARKLY_CLIENT_SIDE_ID: string;
            GOOGLE_RECAPTCHA_V2_KEY_SITE: string;
            DATADOG_CLIENT_TOKEN: string;
            SPRING_PROFILES_ACTIVE: string;
            APPIAN_API_KEY: string;
            APPIAN_DPRM_URL: string;
        };
        __REDUX_DEVTOOLS_EXTENSION_COMPOSE__?: typeof compose;
    }
}

const AdvisorSite = asyncLoadContainer('App/AdvisorSite');
const RpiSite = asyncLoadContainer('App/RpiSite');

type AppProps = {
    history: History;
};

//global variable to know the current version of the app (easier to detect cache related issues)
window._v = process.env.REACT_APP_VERSION;

// we use npm run build for both, dev and production, so in both scenarios node_env process will be production
// but we do not need to track on local (development) or when running unit testing (test)
if (process.env.NODE_ENV === 'production') {
    datadogLogs.init({
        applicationId: '8373aec4-df0a-41e8-beb2-ba4153605f45',
        clientToken: getGlobalConfig().DATADOG_CLIENT_TOKEN,
        site: 'datadoghq.com',
        sampleRate: 100,
        service: 'leapfrog-web',
        version: process.env.REACT_APP_VERSION,
        env: getGlobalConfig().SPRING_PROFILES_ACTIVE,
    });
    datadogRum.init({
        applicationId: '8373aec4-df0a-41e8-beb2-ba4153605f45',
        clientToken: getGlobalConfig().DATADOG_CLIENT_TOKEN,
        site: 'datadoghq.com',
        service: 'leapfrog-web',
        version: process.env.REACT_APP_VERSION,
        sampleRate: 100,
        trackInteractions: true,
        env: getGlobalConfig().SPRING_PROFILES_ACTIVE,
    });
}

const App: FC<AppProps> = ({ history }) => {
    const planSponsorSlug = getSlugUrl();
    const isAdvisorSite =
        planSponsorSlug === ADVISOR || planSponsorSlug === LEARN;

    return (
        <>
            <ReactUtilsProvider domain={aemDomain} AEMBaseURL={aemBaseURL}>
                <QueryClientProvider client={queryClient}>
                    {planSponsorSlug ? (
                        <ConnectedRouter history={history}>
                            <ScrollToTop />
                            {isAdvisorSite ? (
                                <AdvisorSite />
                            ) : (
                                <RpiSite planSponsorSlug={planSponsorSlug} />
                            )}
                        </ConnectedRouter>
                    ) : (
                        <Loader />
                    )}
                </QueryClientProvider>
            </ReactUtilsProvider>
        </>
    );
};

export default App;
