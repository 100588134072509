import React, { FC } from 'react';

import Box from '@material-ui/core/Box';

type AspectRatioProps = {
    children: React.ReactNode;
    ratio?: number;
};

const AspectRatio: FC<AspectRatioProps> = ({ children, ratio = 9 / 16 }) => {
    return (
        <Box height={0} position="relative" pt={`${ratio * 100}%`}>
            <Box
                position="absolute"
                top={0}
                left={0}
                height="100%"
                width="100%"
            >
                {children}
            </Box>
        </Box>
    );
};

export default AspectRatio;
