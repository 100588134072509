import { FC } from 'react';

/* types */
import { FinancialAdvisor } from 'store/reducers/planSponsorReducer';

/* material */
import { makeStyles } from '@material-ui/core';
import {
    SITE_FOOTER_CUSTOM,
    SITE_FOOTER_DEFAULT,
    SITE_FOOTER_DETAILED,
} from 'constants/plansponsors';

/* utils */
import { phoneFormatRegex } from 'utils/regex/phoneNumbers';

const useStyles = makeStyles((theme) => ({
    root: {
        fontSize: 14,
        lineHeight: '150%',
        [theme.breakpoints.up('sm')]: {
            fontSize: 22,
        },
    },
    advisorInfoBold: {
        fontWeight: 600,
        margin: 0,
    },
    advisorInfoLink: {
        color: theme.footer.color,
        display: 'block',
        textDecoration: 'underline',
    },
}));

interface AdvisorInfoProps {
    financialAdvisors: FinancialAdvisor[] | undefined;
    displaySiteOwnerNameInPreparedForByStatement: boolean;
    displaySiteOwnerFirmInPreparedForByStatement: boolean;
    footerType:
        | typeof SITE_FOOTER_CUSTOM
        | typeof SITE_FOOTER_DEFAULT
        | typeof SITE_FOOTER_DETAILED;
}

const AdvisorInfo: FC<AdvisorInfoProps> = ({
    financialAdvisors,
    displaySiteOwnerFirmInPreparedForByStatement = false,
    displaySiteOwnerNameInPreparedForByStatement = false,
    footerType,
}) => {
    const classes = useStyles();
    const isDetailedFooterType = footerType === SITE_FOOTER_DETAILED;
    if (!financialAdvisors || !financialAdvisors.length) return null;

    const {
        firmName: financialAdvisorFirm,
        firmWebUrl: financialAdvisorWebsiteTitle,
        fullNameWithDesignation,
        title: financialAdvisorTitle,
        phoneNumber: financialAdvisorPhone,
        other: financialAdvisorOther,
    } = financialAdvisors[0];

    let advisorInfo = displaySiteOwnerNameInPreparedForByStatement && (
        <div className={classes.advisorInfoBold}>{fullNameWithDesignation}</div>
    );

    if (isDetailedFooterType && displaySiteOwnerNameInPreparedForByStatement) {
        advisorInfo = (
            <>
                {fullNameWithDesignation && (
                    <div className={classes.advisorInfoBold}>
                        {fullNameWithDesignation}
                    </div>
                )}
                {financialAdvisorTitle && <div>{financialAdvisorTitle}</div>}
                {financialAdvisorPhone && (
                    <div>{phoneFormatRegex(financialAdvisorPhone)}</div>
                )}
            </>
        );
    }

    return (
        <div data-qa="advisor-info" className={classes.root}>
            {advisorInfo && (
                <>
                    {advisorInfo}
                    <br />
                </>
            )}
            {displaySiteOwnerFirmInPreparedForByStatement && (
                <div className={classes.advisorInfoBold}>
                    {financialAdvisorFirm}
                </div>
            )}
            {financialAdvisorOther && <div>{financialAdvisorOther}</div>}
            {financialAdvisorWebsiteTitle && (
                <a
                    href={financialAdvisorWebsiteTitle}
                    className={classes.advisorInfoLink}
                    target="_blank"
                    rel="noreferrer"
                >
                    {financialAdvisorWebsiteTitle}
                </a>
            )}
        </div>
    );
};

export default AdvisorInfo;
