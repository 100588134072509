import { put, call, SagaReturnType, takeLatest } from 'redux-saga/effects';

/* services */
import { fetchQuiz } from 'store/services/quizServices';

/* types */
import * as actionTypes from '../actionTypes/quizActionTypes';

import {
    getQuizFailure,
    getQuizSuccess,
} from 'store/actionCreators/quizActionCreator';

function* getQuiz({ industry, planSponsor }: actionTypes.GetQuizRequestAction) {
    try {
        const { data: quizData }: SagaReturnType<typeof fetchQuiz> = yield call(
            fetchQuiz,
            industry,
            planSponsor,
        );

        yield put(getQuizSuccess(quizData.articles));
    } catch (error) {
        console.error(error);
        yield put(getQuizFailure(error as Error));
    }
}

export default function* watchGetQuizData() {
    yield takeLatest(actionTypes.GET_QUIZ_REQUEST, getQuiz);
}
