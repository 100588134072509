import { FC, useEffect, useRef } from 'react';
import { VideoViewerParams } from '@capgroup-cxt/aem-react-utils/lib/s7-viewer/interface';

/* utils */
import { useImportScript } from 'hooks/useImportScript';
import { Typography } from '@material-ui/core';

const VIDEO_VIEWER_RESOURCE_URL =
    'https://thecapitalgroup.scene7.com/s7viewers/html5/js/VideoViewer.js';

type DynamicMediaPlayerProps = {
    params: VideoViewerParams | undefined;
    title?: string;
    alt?: string;
};

const setAriaAttribute = (videoContainerId: string, videoInfoId: string) => {
    document
        .querySelector(`#${videoContainerId}_videoPlayer > video`)
        ?.setAttribute('aria-describedby', videoInfoId);
};

const DynamicMediaPlayer: FC<DynamicMediaPlayerProps> = ({
    title,
    alt,
    params = {} as VideoViewerParams,
}) => {
    const randNum = new Date().getTime();
    const videoContainerId = useRef(`video-container-${randNum}`);
    const videoInfoId = useRef(`video-info-${randNum}`);
    const s7videoviewer = useRef<any>(null);
    const loaded = useImportScript(VIDEO_VIEWER_RESOURCE_URL);
    const { asset, posterimage, serverurl, videoserverurl } = params;

    useEffect(() => {
        if (loaded) {
            s7videoviewer.current = new window.s7viewers.VideoViewer({
                containerId: videoContainerId.current,
                params: {
                    asset,
                    posterimage,
                    serverurl,
                    videoserverurl,
                },
                handlers: {
                    initComplete: () => {
                        setAriaAttribute(
                            videoContainerId.current,
                            videoInfoId.current,
                        );
                    },
                    trackEvent: function (
                        _objID: string,
                        _compClass: string,
                        _instName: string,
                        _timeStamp: number,
                        eventInfo: string,
                    ) {
                        if (
                            window.Analytics &&
                            typeof window.Analytics.trackScene7 === 'function'
                        ) {
                            window.Analytics.trackScene7(
                                eventInfo,
                                false,
                                asset,
                                title,
                            );
                        }
                    },
                },
            });
            s7videoviewer.current.init();
        }
    }, [loaded, serverurl, posterimage, videoserverurl, asset, title]);

    const menuSelector = document.querySelectorAll(
        '[data-qa=drawer-menu-item]',
    );
    menuSelector.forEach(function (element: Element) {
        element.addEventListener('click', function () {
            if (!window.Analytics) return;
            const scene7 = window.Analytics.trackScene7;
            scene7.videoAbandoned && scene7.videoAbandoned();
            s7videoviewer.current.dispose();
        });
    });

    return (
        <div data-qa="dynamic-media-player">
            <div id={videoContainerId.current} />
            <Typography id={videoInfoId.current} variant="srOnly">
                {alt}
            </Typography>
        </div>
    );
};
export default DynamicMediaPlayer;
