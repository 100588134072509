import { FC } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { GENERIC } from 'constants/plansponsors';

/* components */
import {
    InstitutionalAdvisorInfo,
    CustomPlanSponsorContactInfo,
} from 'components';

/* interfaces */
import { PlanSponsorConfig, Site } from 'store/reducers/planSponsorReducer';
import { SITE_FOOTER_CUSTOM } from 'constants/plansponsors';

const useStyles = makeStyles((theme) => ({
    contact: {
        color: theme.topMenu.color,
        '& > div': {
            fontWeight: 600,
        },
        '& > a': {
            color: theme.topMenu.color,
            textDecoration: 'none',
        },
    },
    institutionalAdvisorWrapper: {
        fontSize: 18,
        '& h4': {
            fontSize: 18,
        },
    },
}));

interface AdvisorInfoProps {
    planSponsorConfig: PlanSponsorConfig;
}

const AdvisorInfo: FC<AdvisorInfoProps> = ({ planSponsorConfig }) => {
    const classes = useStyles();
    const {
        financialAdvisors,
        businessDevelopmentManagers,
        isCGInstitutional,
        sites,
    } = planSponsorConfig;
    const {
        firmName: financialAdvisorFirm,
        firmWebUrl: financialAdvisorWebsiteTitle,
    } = financialAdvisors?.[0] || {};
    const { customFooter, footerType } = sites[0] as Site;
    const firmName =
        financialAdvisorFirm?.toLowerCase() !== GENERIC && financialAdvisorFirm;

    if (footerType === SITE_FOOTER_CUSTOM) {
        return <CustomPlanSponsorContactInfo footer={customFooter} />;
    }

    if (isCGInstitutional) {
        return (
            <div className={classes.institutionalAdvisorWrapper}>
                <InstitutionalAdvisorInfo
                    businessDevelopmentManagers={businessDevelopmentManagers}
                />
            </div>
        );
    }

    return (
        <div className={classes.contact}>
            {firmName && <div>{firmName}</div>}
            {financialAdvisorWebsiteTitle && (
                <a
                    target="_blank"
                    rel="noreferrer"
                    href={financialAdvisorWebsiteTitle}
                    title={financialAdvisorFirm}
                >
                    {financialAdvisorWebsiteTitle}
                </a>
            )}
        </div>
    );
};

export default AdvisorInfo;
