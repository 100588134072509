import { getGlobalConfig } from 'utils/config';
export const aemBaseURL = getGlobalConfig().AEM_BASE_URL;
export const aemNodeExtension = '.analytics-json.js';
export const aemDomain = 'leapfrog';
export const baseAemAPI = `${aemBaseURL}/wcm/api/cloud/lf/`;
export const contentFragmentUrl = `${baseAemAPI}cfs`;
export const aemRPIAnalyticsPath =
    '/content/capital-group/us/en/retirement-plan-insights/';

export const TAG_PLANSPONSOR_ALL = 'all';

export const TAG_NAMESPACES = {
    actions: 'actions',
    contentZone: 'contentzone',
    industries: 'industries',
    industry: 'industry',
    planSponsor: 'plan_sponsor',
    planSponsors: 'plansponsors',
};

export const AEMMODELS = {
    action: 'lf-action',
    accumulation: 'lf-accumulation-widget',
    advisorStory: 'lf-advisor-story',
    advisorFaq: 'lf-advisor-faq',
    hero: 'lf-hero',
    planSponsor: 'lf-plan-sponsor',
    spotlight: 'lf-spotlight',
    story: 'lf-story',
    quiz: 'lf-quiz',
};
