import * as actions from '../actionTypes/quizActionTypes';
import { Quiz } from 'interfaces/quiz';
import { Quiz as QuizConfig } from 'containers/Quiz/interfaces';

export interface QuizzesState {
    quizzes: Quiz[];
    currentQuiz?: QuizConfig;
    quizToShow?: QuizConfig;
    answers?: string[];
    currentStep: number;
}

const initialState: QuizzesState = {
    quizzes: [],
    currentStep: 0,
};

export default function QuizzesReducer(
    state: QuizzesState = initialState,
    action: actions.QuizAction,
): QuizzesState {
    switch (action.type) {
        case actions.GET_QUIZ_SUCCESS:
            return {
                ...state,
                currentStep: 0,
                quizzes: action.quizzes,
            };
        case actions.START_QUIZ:
            return {
                ...state,
                currentQuiz: action.quiz,
                quizToShow: {
                    questions: [action.quiz.questions[0]],
                },
                answers: [],
            };
        case actions.QUESTION_ANSWERED:
            if (!state.currentQuiz || !state.quizToShow || !state.answers)
                return state;
            const answeredLastVisibleQuestion =
                action.questionNumber === state.quizToShow.questions.length - 1;
            const isLastQuestion =
                action.questionNumber ===
                state.currentQuiz.questions.length - 1;
            const nextStep =
                answeredLastVisibleQuestion && !isLastQuestion
                    ? state.currentStep + 1
                    : state.currentStep;
            const quizToShow =
                answeredLastVisibleQuestion && !isLastQuestion
                    ? {
                          questions: state.currentQuiz.questions.slice(
                              0,
                              nextStep + 1,
                          ),
                      }
                    : state.quizToShow;
            const answerExist = state.answers.length > action.questionNumber;

            const newAnswers = !answerExist
                ? [...state.answers, action.answer]
                : state.answers.map((ans, idx) => {
                      return action.questionNumber === idx
                          ? action.answer
                          : ans;
                  });

            return {
                ...state,
                quizToShow,
                answers: newAnswers,
                currentStep: nextStep,
            };
        default:
            return state;
    }
}
