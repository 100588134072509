export const newsletterSignUpEvent = (
    data = {
        linkName: '',
        eventName: '',
    },
) => {
    window.appEventData = window.appEventData || [];
    window.appEventData.push({
        event: data.eventName,
        linkInfo: {
            customLinkName: data.linkName,
        },
    });
};
